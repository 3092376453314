export const STANDARD_FILES = [
  {
    nameKo: '거버넌스 구조 및 운영 회칙',
    nameEn: 'Governance Operating Rules',
    descriptionKo:
      '팝플의 거버넌스는 구성원들의 활동 기준, 구성원의 임명, 의사결정 방식, 기타 운영을 위한 규칙을 제시하고 있습니다.',
    descriptionEn:
      "POPLE's governance outlines the criteria for member activities, appointment of members, decision-making processes, and other operational regulations.",
    fileKo:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/ko/policy/[POPLE] POPLE 거버넌스 구조 및 운영회칙.docx',
    fileEn: 'https://d1sjg004kl9yle.cloudfront.net/public/program/en/POPLE_Governance_Operating_Rules_v1.pdf',
    downloadLabelKo: '가이드라인',
    downloadLabelEn: 'Download',
    subjectKo: '모든 이용자',
    subjectEn: 'All',
  },
  {
    nameKo: '방법론 심의 패널 및 운영위원 신청서',
    nameEn: 'Panels and Members Application Form',
    descriptionKo:
      '신청을 희망할 경우 신청서 양식 작성 후 사무국에 제출해야합니다. 사무국은 신청자의 전문성을 검토 후 패널의 자격을 부여하고 등록합니다. 신청자가 필요한 역량을 갖추지 못했다고 판단하는 경우에 신청을 거부할 수 있습니다.',
    descriptionEn:
      "Should you wish to apply, you must submit the application to the secretariat. The secretariat will review the applicant's expertise, grant panel qualifications,\nand proceed with registration. Applicants may submit additional documentations or may be denied.",
    fileKo:
      'https://d1sjg004kl9yle.cloudfront.net/public/program/ko/POPLE_%E1%84%91%E1%85%A2%E1%84%82%E1%85%A5%E1%86%AF_%E1%84%86%E1%85%B5%E1%86%BE_%E1%84%8B%E1%85%B1%E1%84%8B%E1%85%AF%E1%86%AB_%E1%84%89%E1%85%B5%E1%86%AB%E1%84%8E%E1%85%A5%E1%86%BC%E1%84%89%E1%85%A5_v1.docx',
    fileEn:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/[POPLE] POPLE Governance and Operational Giudelines.pdf',
    downloadLabelKo: '신청서',
    downloadLabelEn: 'Download',
  },
  {
    nameKo: '방법론 제안서',
    nameEn: 'Proposal',
    descriptionKo:
      '신규 방법론 또는 타 스탠다드(레지스트리, 제도 포함)에 의해 승인된 방법론의 적격성 평가를 받기 위해 제출하는 제안서입니다.',
    descriptionEn:
      'This is a proposal in order to receive an eligibility assessment for a new methodology or a methodology that has been approved by another standards and registries.',
    fileKo:
      'https://d1sjg004kl9yle.cloudfront.net/public/program/ko/POPLE_%E1%84%87%E1%85%A1%E1%86%BC%E1%84%87%E1%85%A5%E1%86%B8%E1%84%85%E1%85%A9%E1%86%AB_%E1%84%8C%E1%85%A6%E1%84%8B%E1%85%A1%E1%86%AB%E1%84%89%E1%85%A5_v1.docx',
    fileEn: 'https://d1sjg004kl9yle.cloudfront.net/public/program/en/POPLE_Methodology_Proposal_v1.docx',
    downloadLabelKo: '제안서',
    downloadLabelEn: 'Download',
  },
];

export const APPLICANTS = {
  fileKo:
    'https://d1sjg004kl9yle.cloudfront.net/public/3rd-party-verification/ko/POPLE_%E1%84%80%E1%85%A5%E1%86%B7%E1%84%8C%E1%85%B3%E1%86%BC%E1%84%80%E1%85%B5%E1%84%80%E1%85%AA%E1%86%AB_%E1%84%83%E1%85%B3%E1%86%BC%E1%84%85%E1%85%A9%E1%86%A8_%E1%84%89%E1%85%B5%E1%86%AB%E1%84%8E%E1%85%A5%E1%86%BC%E1%84%89%E1%85%A5_v1.docx',
  fileEn: 'https://d1sjg004kl9yle.cloudfront.net/public/3rd-party-verification/en/POPLE_VVB_registration_form_v1.docx',
};

export const MANUAL_FILE_LIST = [];

export const RULE_FILE_LIST = [
  {
    nameKo: '사업계획서',
    nameEn: 'Project Design Document',
    fileKo:
      'https://d1sjg004kl9yle.cloudfront.net/public/program/ko/POPLE_%E1%84%89%E1%85%A1%E1%84%8B%E1%85%A5%E1%86%B8%E1%84%80%E1%85%A8%E1%84%92%E1%85%AC%E1%86%A8%E1%84%89%E1%85%A5_v1.docx',
    fileEn: 'https://d1sjg004kl9yle.cloudfront.net/public/program/en/POPLE_Project_Design_Document_v1.docx',
    downloadLabelKo: '사업계획서',
    downloadLabelEn: 'Download',
  },
  {
    nameKo: '타당성 평가 보고서',
    nameEn: 'Feasibility Assessment Form',
    fileKo:
      'https://d1sjg004kl9yle.cloudfront.net/public/program/ko/POPLE_%E1%84%90%E1%85%A1%E1%84%83%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A5%E1%86%BC_%E1%84%91%E1%85%A7%E1%86%BC%E1%84%80%E1%85%A1_%E1%84%8B%E1%85%A3%E1%86%BC%E1%84%89%E1%85%B5%E1%86%A8_v1.docx',
    fileEn: 'https://d1sjg004kl9yle.cloudfront.net/public/program/en/POPLE_Feasibility_Assessment_Form_v1.docx',
    downloadLabelKo: '가이드라인',
    downloadLabelEn: 'Download',
  },
  {
    nameKo: '세이프가드 원칙 및 가이드라인',
    nameEn: 'POPLE Safeguarding Principles and Guidelines',
    fileKo:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/ko/requirment/[POPLE] POPLE 세이프가드 원칙 및 가이드라인.docx',
    fileEn:
      'https://d1sjg004kl9yle.cloudfront.net/public/program/en/POPLE_Safeguarding+Principles+and+Guidelines_v1.0.pdf',
    downloadLabelKo: '가이드라인',
    downloadLabelEn: 'Download',
    additionalFileKo:
      'https://d1sjg004kl9yle.cloudfront.net/public/program/ko/POPLE_%E1%84%89%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%91%E1%85%B3%E1%84%80%E1%85%A1%E1%84%83%E1%85%B3+%E1%84%8B%E1%85%AF%E1%86%AB%E1%84%8E%E1%85%B5%E1%86%A8+%E1%84%91%E1%85%A7%E1%86%BC%E1%84%80%E1%85%A1%E1%84%89%E1%85%A5.docx',
    additionalFileEn:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/[POPLE] POPLE Safeguarding Principles and Guidelines.docx',
    additionalDownloadLabelKo: '세이프가드 원칙 평가서',
    additionalDownloadLabelEn: 'Report Download',
    subjectKo: '프로젝트 제안자',
    subjectEn: 'Project Developer',
  },
  {
    nameKo: '비영속성 및 리스크 관리 원칙 및 가이드라인',
    nameEn: 'POPLE Non-Permanence Management Principles and Guidelines',
    fileKo:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/ko/requirment/[POPLE] POPLE 비영속성 관리 원칙 및 가이드라인.docx',
    fileEn:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/[POPLE] POPLE NON-Permanence Management Principles and Guidelines.docx',
    downloadLabelKo: '가이드라인',
    downloadLabelEn: 'Download',
    additionalFileKo:
      'https://d1sjg004kl9yle.cloudfront.net/public/program/ko/POPLE_%E1%84%8B%E1%85%B1%E1%84%92%E1%85%A5%E1%86%B7+%E1%84%80%E1%85%AA%E1%86%AB%E1%84%85%E1%85%B5+%E1%84%91%E1%85%A7%E1%86%BC%E1%84%80%E1%85%A1+%E1%84%87%E1%85%A9%E1%84%80%E1%85%A9%E1%84%89%E1%85%A5_v1.docx',
    additionalFileEn:
      'https://d1sjg004kl9yle.cloudfront.net/public/program/en/POPLE_Assessment+Report+of+Non-Permanence+and+Risk+Management_v1.docx',
    additionalDownloadLabelKo: '위험도 관리 평가 보고서',
    additionalDownloadLabelEn: 'Report Download',
    subjectKo: '프로젝트 제안자',
    subjectEn: 'Project Developer',
  },
  {
    nameKo: '모니터링 보고서 양식',
    nameEn: 'POPLE Monitoring Report Template',
    fileKo:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/ko/template/[POPLE] 모니터링 보고서 양식.docx',
    fileEn: 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/[POPLE] Monitoring Report.docx',
    downloadLabelKo: '보고서',
    downloadLabelEn: 'Download',
    subjectKo: '프로젝트 제안자',
    subjectEn: 'Project Developer',
  },
  {
    nameKo: '이해관계자충돌 평가 보고서',
    nameEn: 'Conflicts of Interest Assessment Principles and Guidelines',
    fileKo:
      'https://d1sjg004kl9yle.cloudfront.net/public/program/ko/POPLE_%E1%84%8B%E1%85%B5%E1%84%92%E1%85%A2%E1%84%80%E1%85%AA%E1%86%AB%E1%84%80%E1%85%A8%E1%84%8E%E1%85%AE%E1%86%BC%E1%84%83%E1%85%A9%E1%86%AF+%E1%84%91%E1%85%A7%E1%86%BC%E1%84%80%E1%85%A1+%E1%84%8B%E1%85%AF%E1%86%AB%E1%84%8E%E1%85%B5%E1%86%A8+%E1%84%86%E1%85%B5%E1%86%BE+%E1%84%80%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%83%E1%85%B3%E1%84%85%E1%85%A1%E1%84%8B%E1%85%B5%E1%86%AB_v1.pdf',
    fileEn:
      'https://d1sjg004kl9yle.cloudfront.net/public/program/en/POPLE_Conflicts+of+Interest+Assessment+Principles+and+Guidelines+_v1.pdf',
    downloadLabelKo: '가이드라인',
    downloadLabelEn: 'Download',
    additionalFileKo:
      'https://d1sjg004kl9yle.cloudfront.net/public/program/ko/POPLE_%E1%84%8B%E1%85%B5%E1%84%92%E1%85%A2%E1%84%80%E1%85%AA%E1%86%AB%E1%84%80%E1%85%A8%E1%84%8E%E1%85%AE%E1%86%BC%E1%84%83%E1%85%A9%E1%86%AF+%E1%84%91%E1%85%A7%E1%86%BC%E1%84%80%E1%85%A1%E1%84%89%E1%85%A5.docx',
    additionalFileEn:
      'https://d1sjg004kl9yle.cloudfront.net/public/program/en/Assessment+of+Confilcts+of+Interest+Form_v1.docx',
    additionalDownloadLabelKo: '이해관계자 충돌 평가서',
    additionalDownloadLabelEn: 'Report Download',
  },
  {
    nameKo: '팝플 마켓플레이스 등록 양식',
    nameEn: 'POPLE Marketplace Registration Form',
    fileKo:
      'https://d1sjg004kl9yle.cloudfront.net/public/program/ko/POPLE_%E1%84%86%E1%85%A1%E1%84%8F%E1%85%A6%E1%86%BA%E1%84%91%E1%85%B3%E1%86%AF%E1%84%85%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%89%E1%85%B3_%E1%84%83%E1%85%B3%E1%86%BC%E1%84%85%E1%85%A9%E1%86%A8_%E1%84%8B%E1%85%A3%E1%86%BC%E1%84%89%E1%85%B5%E1%86%A8_v1.docx',
    fileEn: 'https://d1sjg004kl9yle.cloudfront.net/public/program/en/POPLE_Marketplace_Registration_Form_v1.docx',
    downloadLabelKo: '등록 양식',
    downloadLabelEn: 'Download',
  },
  {
    nameKo: 'POPLE Standard v1.4',
    nameEn: 'POPLE Standard v1.4',
    fileKo: 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/standard/ko/[POPLE] POPLE 스탠다드  v1.4.pdf',
    fileEn: 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/standard/[POPLE] POPLE Standard v1.4.pdf',
    subjectKo: '모든 이용자',
    subjectEn: 'All',
    versionKo: '이전 버전 보기',
    versionEn: 'REVISION HISTORY',
    downloadLabelKo: '등록 양식',
    downloadLabelEn: 'Download',
  },
  {
    nameKo: '방법론 개발 및 등록 매뉴얼',
    nameEn: 'POPLE Methodology Development and Registration Manual',
    fileKo:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/ko/manual/[POPLE] POPLE 방법론 개발 및 등록 매뉴얼.docx',
    fileEn:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/[POPLE] POPLE Methodology Development and Registration manual.docx',
    subjectKo: '방법론 개발자',
    subjectEn: 'Methodology Developer',
  },
  {
    nameKo: '프로젝트 등록 및 발행 매뉴얼',
    nameEn: 'POPLE Project Registration and Issuance Manual',
    fileKo:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/ko/manual/[POPLE] POPLE 프로젝트 등록 및 발행 매뉴얼.docx',
    fileEn:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/[POPLE] POPLE Project Registration and issuance manual.docx',
    subjectKo: '프로젝트 제안자',
    subjectEn: 'Project Developer',
  },
  {
    nameKo: '이해상충 관리 정책',
    nameEn: 'POPLE Conflict of Interest Management Policy',
    fileKo:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/ko/policy/[POPLE] POPLE 이해상충 정책.docx',
    fileEn:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/[POPLE] POPLE Conflict of Interest Policy.docx',
    subjectKo: '모든 이용자',
    subjectEn: 'All',
  },
  {
    nameKo: '프로그램 수수료 체계',
    nameEn: 'POPLE Fee Schedule',
    fileKo:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/ko/policy/[POPLE] POPLE 프로그램 수수료 체계.docx',
    fileEn: 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/[POPLE] POPLE Fee Schedule.docx',
    subjectKo: '프로젝트 제안자',
    subjectEn: 'Project Developer',
  },
  {
    nameKo: '청구 정책',
    nameEn: 'POPLE Claims Policy',
    fileKo:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/ko/policy/[POPLE] POPLE 청구 정책.docx',
    fileEn: 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/[POPLE] POPLE Claims Policy.docx',
    subjectKo: '프로젝트 제안자',
    subjectEn: 'Project Developer',
  },
  {
    nameKo: '불만 처리 정책',
    nameEn: 'POPLE Grievance Resolution Policy',
    fileKo:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/ko/policy/[POPLE] POPLE 불만 처리 정책.docx',
    fileEn:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/[POPLE] POPLE Grievance Resolution Policy.docx',
    subjectKo: '프로젝트 제안자',
    subjectEn: 'Project Developer',
  },
  {
    nameKo: '프로젝트 계획서(PDD) 양식',
    nameEn: 'POPLE Project Design Document Template',
    fileKo:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/ko/template/[POPLE] 프로젝트 계획서 양식.docx',
    fileEn: 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/[POPLE] Project Design Document.docx',
    subjectKo: '프로젝트 제안자',
    subjectEn: 'Project Developer',
  },
  {
    nameKo: '방법론 패널 평가 보고서 양식',
    nameEn: 'Methodology Assessment Report',
    fileKo:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/ko/template/[POPLE] 방법론 패널 평가 보고서 양식.docx',
    fileEn:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/[POPLE] Methodology Assessment Report.docx',
    subjectKo: '방법론 개발자',
    subjectEn: 'Methodology Developer',
  },
  {
    nameKo: '방법론 시정조치 결과서 양식',
    nameEn: 'Methodology Correction Results Report',
    fileKo:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/ko/template/[POPLE] 방법론 시정조치 결과서 양식.docx',
    fileEn:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/[POPLE] Methodology Correction Results Report.docx',
    subjectKo: '방법론 개발자',
    subjectEn: 'Methodology Developer',
  },

  {
    nameKo: '방법론 제안서 양식',
    nameEn: 'Methodology Proposal Template',
    fileKo:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/ko/template/[POPLE] POPLE_방법론 제안서 양식.docx',
    fileEn:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/[POPLE] POPLE_Methodology Proposal.docx',
    subjectKo: '방법론 개발자',
    subjectEn: 'Methodology Developer',
  },
  {
    nameKo: '방법론 아이디어 노트 양식',
    nameEn: 'Methodology Idea Note Template',
    fileKo:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/ko/template/[POPLE] 방법론 아이디어 노트 양식.docx',
    fileEn: 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/[POPLE] Methodology Idea Note.docx',
    subjectKo: '방법론 개발자',
    subjectEn: 'Methodology Developer',
  },
  {
    nameKo: '방법론 컨셉 노트 양식',
    nameEn: 'Methodology Concept Note Template',
    fileKo:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/ko/template/[POPLE] 방법론 컨셉 노트 양식.docx',
    fileEn: 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/[POPLE] Methodology Concept Note.docx',
    subjectKo: '방법론 개발자',
    subjectEn: 'Methodology Developer',
  },
  {
    nameKo: '사전 검토 보고서 양식',
    nameEn: 'Preliminary Review Report',
    fileKo:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/ko/template/[POPLE] 사전검토보고서 양식.docx',
    fileEn:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_detail/[POPLE] Preliminary Review Form.docx',
    subjectKo: '방법론 개발자',
    subjectEn: 'Methodology Developer',
  },
  {
    nameKo: '타당성 평가 보고서 양식',
    nameEn: 'Validation Report Template',
    fileKo:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/ko/template/[POPLE] 타당성 평가 보고서 양식.docx',
    fileEn: 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/[POPLE] Validation Report Form.docx',
    subjectKo: '검진기관',
    subjectEn: 'Methodology Developer',
  },
  {
    nameKo: '이해상충 선언서',
    nameEn: 'Declaration of Conflict of Interest',
    fileKo:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/ko/template/[POPLE] 이해상충 선언서 양식.docx',
    fileEn:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/[POPLE] Declaration of Conflict of Interest form.docx',
    subjectKo: '모든 이용자',
    subjectEn: 'All',
  },
  {
    nameKo: '검증기관 등록 신청서',
    nameEn: 'POPLE_VVB Registration Form',
    fileKo:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/ko/template/[POPLE] POPLE_검증기관 등록 신청서 양식.docx',
    fileEn:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/[POPLE] POPLE VVB registration form.docx',
    subjectKo: '검진기관',
    subjectEn: 'VVB',
  },
  {
    nameKo: '패널 및 위원 신청서',
    nameEn: 'POPLE_Panels and Members Application Form',
    fileKo:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/ko/template/[POPLE] POPLE_패널 및 위원 신청서.docx',
    fileEn:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/[POPLE] POPLE Panels and Members Application Form.docx',
    subjectKo: '워킹패널, 인증심사위원',
    subjectEn: 'Working Panel',
  },
  {
    nameKo: '마켓플레이스 등록 양식',
    nameEn: 'Marketplace Registration Form',
    fileKo:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/ko/template/[POPLE] POPLE 마켓플레이스 등록 양식.docx',
    fileEn:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/[POPLE] POPLE_Marketplace Registration Form.docx',
    subjectKo: '프로젝트 제안자',
    subjectEn: 'Project Developer',
  },
  {
    nameKo: '레지스트리 이용약관 v.1.0',
    nameEn: 'POPLE Registry Terms of Use Form, v1.0',
    fileKo:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/ko/etc/[POPLE] POPLE_레지스트리 이용약관.docx',
    fileEn:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/[POPLE] POPLE_Registry Terms of Use.docx',
    subjectKo: '모든 이용자',
    subjectEn: 'All',
  },
  {
    nameKo: '고객확인 정책',
    nameEn: 'KYC Policy',
    fileKo:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/ko/policy/[POPLE] POPLE 고객확인 정책.docx',
    fileEn: 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/[POPLE] POPLE KYC Policy.docx',
    subjectKo: '프로젝트 제안자',
    subjectEn: 'Project Developer',
  },
  {
    nameKo: '프로젝트 공개논평 결과서',
    nameEn: 'Public Comments and Responses',
    fileKo:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/ko/template/[POPLE] 프로젝트 공개논평 결과서.docx',
    fileEn:
      'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/[POPLE] Public Comments and Responses.docx',
    subjectKo: '모든 이용자',
    subjectEn: 'All',
  },
];

export const AGENCY_LIST = [
  {
    nameKo: '(재)한국품질재단',
    nameEn: 'Korea\nFoundation\nfor Quality\n(KFQ)',
    certNumKo: '제2022-7호\n(2022.09.27)',
    certNumEn: 'Issue No. 2022-7\n(2022.09.27)',
    periodKo: '2023.09.16 ~ 2026.09.15',
    periodEn: 'Sep.16.2023 ~ Sep.15.2026',
    areaKo: 'ISO 14065:2013\n(온실가스 검증기관)',
    areaEn: 'ISO 14065:2013\n(GHG Verification Body)',
    addressKo: '서울 금천구 가산디지털 1로 168\n(우림라이온스밸리 B동 13층)\n(Tel: +82-2-2025-9074)',
    addressEn:
      '13th Floor, Building B, 168, Gasan digital 1-ro, Geumcheon-gu, Seoul, Republic of Korea\n(Tel: +82  2-2025-9074)',
    url: 'https://kfq.or.kr',
  },
  {
    nameKo: '(재)한국화학융합\n시험연구원',
    nameEn: 'Korea Testing & Research Institute\n(KTR)',
    certNumKo: '제2022-4호\n(2022.08.08)',
    certNumEn: 'Issue No. 2022-4\n(2022.08.08)',
    periodKo: '2023.08.11 ~ 2026.08.10',
    periodEn: 'Aug.11.2023 ~ Aug.10.2026',
    areaKo: 'ISO 14065:2013\n(온실가스 검증기관)\nGHG Verifier',
    areaEn: 'ISO 14065:2013\n(GHG Verification Body)GHG Verifier',
    addressKo: '경기도 과천시 교육원로98(중앙동)\n(Tel: +82-2-2164-0011)',
    addressEn: '98, Gyoyugwon-ro, Gwacheon-si, Gyeonggi-do, Republic of Korea\n(Tel: +82-2-2164-0011)',
    url: 'https://ktr.or.kr',
  },
  {
    nameKo: '로이드인증원㈜',
    nameEn: "Lloyd's Register Quality Assurance\n(LRQA)",
    certNumKo: '제2022-10호\n(2022.10.24)',
    certNumEn: 'Issue No. 2022-10\n(2022.10.24)',
    periodKo: '2023.09.12 ∼ 2025.10.23',
    periodEn: 'Sep.12.2023 ~ Oct.23.2026',
    areaKo: 'ISO 14065:2013\n(온실가스 검증기관)',
    areaEn: 'ISO 14065:2013\n(GHG Verification Body)',
    addressKo: '서울시 중구 소월로 2길 30, T타워 2층\n(Tel: +82-2-736-6231)',
    addressEn: '2nd Floor, 30, Sowol-ro 2-gil, Jung-gu, Seoul, Republic of Korea\n(Tel: +82  2-736-6231)',
    url: 'https://www.lrqa.com/ko-kr',
  },
];
