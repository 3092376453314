import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';
import API from '../../api';
import { Download, PageHeader } from '../../components';
import { DetailDocumentOld } from '../../components/table/DetailDocumentOld';
import { LangContext, SnackbarContext } from '../../contexts';
import { useLabel } from '../../hooks';

export const TermsPage = (props) => {
  const { t } = useTranslation();
  const [list, setList] = useState(null);
  const [latest, setLatest] = useState(null);
  const { isKorean } = useContext(LangContext);
  const { updateSnackbar } = useContext(SnackbarContext);
  const { REGISTRY_TERM_PAGE } = useLabel();

  const requestNewList = (init_page) => {
    API.Documents.GetUsageTerm()
      .then((res) => {
        const result = [...res.data];
        const latest = result.filter((e) => e.type === 'HISTORY').sort((a, b) => b.createdAt - a.createdAt)[0];
        const latestIdx = result.indexOf(latest);
        setLatest(result[latestIdx]);

        result.splice(latestIdx, 1);
        result.sort((a, b) => b.createdAt - a.createdAt);
        setList(result);
      })
      .catch((err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err?.data?.message,
        });
      });
  };

  const termsList = useMemo(() => {
    if (list?.length > 0) return Handler.genDocumentGroup(list);
    return [];
  }, [list]);

  useEffect(() => {
    requestNewList();
  }, []);

  return (
    <article className='basic-page'>
      <PageHeader type='document' title={REGISTRY_TERM_PAGE['TITLE']} description={REGISTRY_TERM_PAGE['DESCRIPTION']}>
        <ul className='mt-4'>
          {REGISTRY_TERM_PAGE['LIST'].map((el, idx) => (
            <li key={`registry-term-list-${idx}`} className='whitespace-pre-wrap text-gray-700 leading-6'>
              {el}
            </li>
          ))}
        </ul>
      </PageHeader>

      <article className='content__wrapper'>
        {latest && <Download {...latest} isKorean={isKorean} />}
        <section className='mt-12 w-full'>
          <h2 className='mb-4'>{REGISTRY_TERM_PAGE['DOC_TITLE']}</h2>
          <DetailDocumentOld docData={termsList} type={''} />
        </section>
      </article>
    </article>
  );
};
