import AddRoundedIcon from '@mui/icons-material/AddRounded';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Handler from '../../Handler';
import API from '../../api';
import { AddressLocation, Button, FileUploader, RadioBox, Stepper, TextInput } from '../../components';
import { LangContext, SnackbarContext } from '../../contexts';
import { useForm, useLabel } from '../../hooks';
import { SignUpTerms } from './SignUpTerms';

export const UserSignUpPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [step, setStep] = useState(0);
  const [termsChecks, setTermsChecks] = useState([false, false, false, false]);
  const [termsAllCheck, setTermsAllCheck] = useState(false);
  const { MSG, ERROR } = useLabel();
  const { isKorean } = useContext(LangContext);

  const kycRadioStyle = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    justifyContent: 'flex-start',
    gap: 0,
  };
  const [formData, formMeta, formChange, setForm] = useForm(
    {
      email: '',
      password: '',
      name: '',
      phone: '',
      companyName: '',
      ceoName: '',
      profileImage: '',
      businessNumber: '',
      businessFile: '',
      address: {
        addr1: '',
        addr2: '',
      },
      site: '',
      fax: '',
      kyc: {
        position: '',
        role: '',
        isRepOwns25: null,
        isRepOnly25: null,
        isOtherOwns25: null,
        ownerName1: '',
        ownerPos1: '',
        ownerName2: '',
        ownerPos2: '',
        ownerName3: '',
        ownerPos3: '',
        ownerName4: '',
        ownerPos4: '',
      },
    },
    {
      email: {
        key: 'email',
        label: t('EMAIL'),
        style: {},
        className: 'point',
        required: true,
        helperText: t('ENTER_YOUR_EMAIL_MSG'),
      },
      password: {
        key: 'password',
        label: t('PASSWORD'),
        type: 'password',
        style: {},
        className: 'point',
        required: true,
        placeholder: t('PASSWORD_PLACEHOLDER'),
        regexp: Handler.REGEXP.PASSWORD,
      },
      name: {
        key: 'name',
        label: t('RESPONSIBILITY'),
        style: {},
        className: 'point',
        required: true,
      },
      phone: {
        key: 'phone',
        label: t('MOBILE'),
        style: {},
        className: 'point',
        required: true,
      },
      companyName: {
        key: 'companyName',
        label: t('COMPANY_NAME'),
        style: {},
        className: 'point',
        required: true,
      },
      ceoName: {
        key: 'ceoName',
        label: t('CEO_NAME'),
        style: {},
        className: 'point',
        required: true,
      },
      businessNumber: {
        key: 'businessNumber',
        label: t('BIZ_NUMBER'),
        style: {},
        className: 'point',
        required: true,
      },
      profileImage: {
        type: 'image',
        key: 'profileImage',
        label: t('REPRESENTATIVE_IMAGE'),
        path: 'signup/profile_image',
        className: 'point',
        helperText: t('PROFILE_IMAGE_GUIDE'),
      },
      businessFile: {
        type: 'file',
        key: 'businessFile',
        label: t('BIZ_CERT'),
        path: 'signup/business_file',
        required: true,
        className: 'point',
      },
      address: {
        required: true,
        className: 'point',
        key: 'address',
        label: t('HEADQ_ADDRESS'),
        type: 'mypage',
      },
      site: {
        key: 'site',
        label: t('HOMEPAGE'),
        style: {},
        className: 'point',
        required: false,
      },
      fax: {
        key: 'fax',
        label: t('FAX'),
        style: {},
        className: 'point',
        required: false,
      },
      kyc: {
        position: {
          key: 'kyc.position',
          label: t('KYC-POSITION'),
          style: {},
          className: 'point',
          required: false,
        },
        role: {
          key: 'kyc.role',
          label: t('KYC-ROLE'),
          style: {},
          className: 'point',
          required: false,
        },
        kyc: {
          isRepOwns25: {
            key: 'kyc.isRepOwns25',
            label: t('KYC-ISREPOWNS25'),
            type: 'radio',
            menus: [
              { value: true, label: t('YES-REP-OWNS') },
              { value: false, label: t('NO-REP-OWNS') },
            ],
            helperText: t('ENTER_YOUR_EMAIL_MSG'),
            className: 'point',
          },
          isRepOnly25: {
            key: 'kyc.isRepOnly25',
            label: t('KYC-ISREPONlYS25'),
            type: 'radio',
            menus: [
              { value: true, label: t('YES-REP-ONLY') },
              { value: false, label: t('NO-REP-ONLY') },
            ],
            className: 'point',
          },
          isOtherOwns25: {
            key: 'kyc.isOtherOwns25',
            label: t('KYC-ISOTHEROWNS25'),
            type: 'radio',
            menus: [
              { value: true, label: t('YES-OTHER-OWNS') },
              { value: false, label: t('NO-OTHER-OWNS') },
            ],
            className: 'point',
          },
        },
        ownerName1: {
          key: 'kyc.ownerName1',
          label: t('KYC-OWNERNAME1'),
          className: 'point',
          style: {},
          required: true,
        },
        ownerPos1: {
          key: 'kyc.ownerPos1',
          label: t('KYC-OWNERPOS1'),
          className: 'point',
          style: {},
          required: true,
        },
        ownerName2: {
          key: 'kyc.ownerName2',
          label: t('KYC-OWNERNAME2'),
          className: 'point',
          style: {},
        },
        ownerPos2: {
          key: 'kyc.ownerPos2',
          label: t('KYC-OWNERPOS2'),
          className: 'point',
          style: {},
        },
        ownerName3: {
          key: 'kyc.ownerName3',
          label: t('KYC-OWNERNAME3'),
          className: 'point',
          style: {},
        },
        ownerPos3: {
          key: 'kyc.ownerPos3',
          label: t('KYC-OWNERPOS3'),
          className: 'point',
          style: {},
        },
        ownerName4: {
          key: 'kyc.ownerName4',
          label: t('KYC-OWNERNAME4'),
          className: 'point',
          style: {},
        },
        ownerPos4: {
          key: 'kyc.ownerPos4',
          label: t('KYC-OWNERPOS4'),
          className: 'point',
          style: {},
        },
      },
    },
  );

  console.log('formData-------->', formData);

  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [signUpResult, setSignUpResult] = useState(null);
  useEffect(() => {
    if (termsChecks) {
      let is_all_true = termsChecks.every((e) => e);
      setTermsAllCheck(is_all_true);
    }
  }, [termsChecks]);

  useEffect(() => {
    document.body.scrollTop = 0;
  }, [step]);

  const onChangeStep = (new_value) => {
    setStep(new_value);
  };

  const requestSignup = () => {
    // 1. 비밃번호 확인
    if (formData.password !== passwordConfirm) {
      updateSnackbar({
        type: 'error',
        open: true,
        message: ERROR['PASSWORD'],
      });
      return;
    }

    // 2. 필수값 검증
    const missingKeys = formToBodyKey(formData, formMeta);
    if (missingKeys.length > 0) {
      formValidations(missingKeys[0]); // 필드 강조 또는 에러 메세지 출력
      return;
    }

    // 4. KYC 정보 입력 확인
    if (showOwnerRows && ownerList?.length > 0) {
      const hasEmptyValue = ownerList.some((owner) => owner.name.trim() === '' || owner.position.trim() === '');

      if (hasEmptyValue) {
        updateSnackbar({
          type: 'error',
          open: true,
          message: ERROR['FINISH_KYC_PROCESS'],
        });
        return;
      } else {
        ownerList.forEach((el, idx) => {
          formData.kyc[`ownerName${idx + 1}`] = el.name;
          formData.kyc[`ownerPos${idx + 1}`] = el.position;
        });
      }
    }

    // 3. 데이터 준비
    const body = Handler.formToBody(formData, formMeta);
    const db_body = {
      ...body,
      isMarketingAgree: termsChecks[3],
      profileImage: body.profileImage || Handler.GET_DEFAULT_IMAGE('company'),
      address: JSON.stringify(body.address),
    };

    // 4. API 호출
    API.User.Signup(db_body)
      .then((res) => {
        let result = res.data;
        setStep(2);
        setSignUpResult(result);
      })
      .catch((err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.fieldErrors ? err.data.fieldErrors[0].reason : err.data.message,
        });
      });
  };

  // 필수 값 검증 개선
  const formValidations = (key) => {
    let message = '';
    if (['email', 'password', 'name', 'phone', 'address'].includes(key)) {
      message = ERROR[key.toUpperCase()];
    } else if (key === 'companyName') {
      message = ERROR['COMPANY_NAME'];
    } else if (key === 'ceoName') {
      message = ERROR['CEO_NAME'];
    } else if (key === 'businessNumber') {
      message = ERROR['BIZ_NUMBER'];
    } else if (key === 'businessFile') {
      message = ERROR['BIZ_CERT'];
    }
    updateSnackbar({
      type: 'error',
      open: true,
      message: message,
    });
  };

  // 필수 키를 반환하는 함수 개선
  const formToBodyKey = (formData, formMeta) => {
    return Object.keys(formMeta).filter((key) => formMeta[key].required && !isValidValue(formData[key]));
  };

  // 값이 유효한지 확인하는 헬퍼 함수
  const isValidValue = (value) => {
    // null, undefined 체크
    if (value === null || value === undefined) return false;

    // 문자열 체크
    if (typeof value === 'string') {
      // address가 빈 문자열인 경우도 유효하지 않음으로 처리
      return value.trim() !== '';
    }

    // 객체 체크
    if (typeof value === 'object') {
      // 빈 객체 체크
      if (Object.keys(value).length === 0) return false;

      // address 객체가 유효한 형태인 경우만 addr1 체크
      if (value.addr1 !== undefined) {
        return typeof value.addr1 && Object.keys(value.addr1).length > 0;
      }
    }

    return true;
  };

  const handleNestedValueChange = (value, name) => {
    console.log('name ------->', value, name);
    setForm({
      ...formData,
      kyc: {
        ...formData.kyc,
        [name]: value,
      },
    });
  };
  const [ownerCount, setOwnerCount] = useState(1);
  const [ownerList, setOwnerList] = useState([{ name: '', position: '' }]);

  const handleOwnerList = (value, name, idx) => {
    setOwnerList((prev) => {
      return [...prev].map((el, index) => {
        return idx === index
          ? {
              ...el,
              [name]: value,
            }
          : el;
      });
    });
  };

  const addOwner = () => {
    if (ownerList?.length < 4) {
      setOwnerList((prev) => {
        return [
          ...prev,
          {
            name: '',
            position: '',
          },
        ];
      });
    }
  };
  const deleteOwner = (index) => {
    if (ownerList?.length > 1) {
      setOwnerList((prev) => {
        const _ = prev;
        _.splice(index, 1);
        return [..._];
      });
    }
  };

  const showOwnerRows = useMemo(() => {
    return formData.kyc.isOtherOwns25 === true;
  }, [formData.kyc.isOtherOwns25]);

  useEffect(() => {
    console.log('formData-------->', formData);
  }, [formData]);

  useEffect(() => {
    if (termsChecks) {
      let is_all_true = termsChecks.every((e) => e);
      setTermsAllCheck(is_all_true);
    }
  }, [termsChecks]);

  return (
    <article className='basic-page'>
      <article className='content__wrapper'>
        <section className='heading-text__container'>
          <header>
            <h1>{t('SIGN_UP')}</h1>
          </header>
        </section>
        <section className='user-stepper__container'>
          <Stepper
            meta={{}}
            value={step}
            label={[t('AGREE_TO_TERM'), t('ENTER_INFO'), t('EMAIL_VERIFICATION')]}
            onChange={onChangeStep}
          ></Stepper>
        </section>
        {step === 0 && (
          <>
            <section className='user-form__container'>
              <SignUpTerms onChange={(terms) => setTermsChecks(terms)} />
              <section className='button__container'>
                <Button
                  style={{ width: '180px', marginRight: 24 }}
                  onClick={() => {
                    history.push('/user/signup_guide');
                  }}
                  label={t('PREV')}
                  type='secondary'
                ></Button>
                <Button
                  style={{ width: '180px' }}
                  onClick={() => {
                    if (termsChecks[0] && termsChecks[1] && termsChecks[2]) {
                      setStep(1);
                    } else {
                      updateSnackbar({
                        type: 'error',
                        open: true,
                        message: ERROR['REQUIRED_TERM'],
                      });
                    }
                  }}
                  label={t('NEXT')}
                ></Button>
              </section>
            </section>
          </>
        )}
        {step === 1 && (
          <>
            <section className='user-form__container'>
              <header>
                <h2>{t('LOGIN_INFO')}</h2>
                <em>
                  <div className='ico required'></div> {t('REQUIRED')}
                </em>
              </header>
              <ul className='form__list'>
                <TextInput value={formData.email} onChange={formChange} meta={formMeta.email}></TextInput>
                <TextInput value={formData.password} onChange={formChange} meta={formMeta.password}></TextInput>
                <TextInput
                  value={passwordConfirm}
                  onChange={(new_value) => {
                    setPasswordConfirm(new_value);
                  }}
                  meta={{
                    key: 'passwordConfirm',
                    type: 'password',
                    style: { marginTop: 12 },
                    className: 'point',
                    required: true,
                    placeholder: 'PASSWORD_CONFIRM',
                    equal: formData.password,
                  }}
                ></TextInput>
                <TextInput value={formData.name} onChange={formChange} meta={formMeta.name}></TextInput>
                <TextInput
                  value={formData.phone}
                  onChange={(value, key) => {
                    const reg = new RegExp('^[0-9]{0,11}$');
                    const isValidation = reg.test(value);
                    if (isValidation) {
                      formChange(value, key);
                    }
                  }}
                  meta={formMeta.phone}
                ></TextInput>
              </ul>
            </section>
            <section className='user-form__container'>
              <header>
                <h2>{t('COMPANY_PROFILE')}</h2>
                <em>
                  <div className='ico required'></div> {t('REQUIRED')}
                </em>
              </header>
              <ul className='form__list'>
                <TextInput value={formData.companyName} onChange={formChange} meta={formMeta.companyName}></TextInput>
                <TextInput value={formData.ceoName} onChange={formChange} meta={formMeta.ceoName}></TextInput>
                <TextInput
                  value={formData.businessNumber}
                  onChange={(value, key) => {
                    const reg = new RegExp('^[0-9]{0,10}$');
                    const isValidation = reg.test(value);
                    if (isValidation) {
                      formChange(value, key);
                    }
                  }}
                  meta={formMeta.businessNumber}
                ></TextInput>
                <FileUploader
                  value={formData.businessFile}
                  onChange={formChange}
                  meta={formMeta.businessFile}
                ></FileUploader>
                <FileUploader
                  value={formData.profileImage}
                  onChange={formChange}
                  meta={formMeta.profileImage}
                ></FileUploader>
                <AddressLocation
                  value={formData.address}
                  onChange={formChange}
                  meta={formMeta.address}
                ></AddressLocation>
                <TextInput value={formData.site} onChange={formChange} meta={formMeta.site}></TextInput>
                <TextInput
                  value={formData.fax}
                  onChange={(value, key) => {
                    const reg = new RegExp('^[0-9]{0,11}$');
                    const isValidation = reg.test(value);
                    if (isValidation) {
                      formChange(value, key);
                    }
                  }}
                  meta={formMeta.fax}
                ></TextInput>
              </ul>
            </section>
            <section className='user-form__container mb-[]'>
              <header>
                <h2>{t('KYC-CHECK')}</h2>
                <em>
                  <div className='ico required'></div> {t('REQUIRED')}
                </em>
              </header>
              <div className='mt-3 '>
                <p className='text-gray-500' style={{ fontSize: '14px', lineHeight: '18px' }}>
                  {t('KYC-DESCRIPTION')}
                </p>
              </div>
              <ul className='form__list'>
                <p className='mt-8 mb-[-30px]' style={{ fontSize: '14px', lineHeight: '24px', fontWeight: 500 }}>
                  {t('KYC-POSITION-Q')}
                </p>
                <TextInput
                  value={formData.kyc.position}
                  onChange={(newValue) => formChange(newValue, 'kyc.position')}
                  meta={{
                    ...formMeta.kyc.position,
                    placeholder: isKorean ? '직위 입력' : 'Enter Position',
                  }}
                ></TextInput>
                <TextInput
                  value={formData.kyc.role}
                  onChange={(newValue) => formChange(newValue, 'kyc.role')}
                  meta={{
                    ...formMeta.kyc.role,
                    placeholder: isKorean ? '맡고 있는 구체적인 업무나 역할을 입력' : 'Enter Role',
                  }}
                ></TextInput>
                <div className='mt-10'>
                  <RadioBox
                    value={formData.kyc.isRepOwns25}
                    onChange={(new_value) => handleNestedValueChange(new_value, 'isRepOwns25')}
                    meta={{
                      key: 'isRepOwns25',
                      menus: [
                        { value: true, label: t('YES-REP-OWNS') },
                        { value: false, label: t('NO-REP-OWNS') },
                      ],
                      required: true,
                      className: 'point',
                      label: t('KYC-ISREPOWNS25'),
                    }}
                    menuStyle={kycRadioStyle}
                    checked={formData.kyc.isRepOwns25}
                  />
                </div>
                <div className='mt-10'>
                  <RadioBox
                    value={formData.kyc.isRepOnly25}
                    onChange={(new_value) => handleNestedValueChange(new_value, 'isRepOnly25')}
                    meta={{
                      label: t('KYC-ISREPONlY25'),
                      labelClassName: 'radio-header',
                      key: 'isRepOnly25',
                      required: true,
                      menus: [
                        { value: true, label: t('YES-REP-ONLY') },
                        { value: false, label: t('NO-REP-ONLY') },
                      ],
                      className: 'point',
                    }}
                    menuStyle={kycRadioStyle}
                    checked={formData.kyc.isRepOnly25}
                  />
                </div>
                <div className='mt-10'>
                  <RadioBox
                    value={formData.kyc.isOtherOwns25}
                    onChange={(new_value) => handleNestedValueChange(new_value, 'isOtherOwns25')}
                    meta={{
                      label: t('KYC-ISOTHEROWNS25'),
                      labelClassName: 'radio-header',
                      key: 'isOtherOwns25',
                      required: true,
                      className: 'point',
                      menus: [
                        { value: true, label: t('YES-OTHER-OWNS') },
                        { value: false, label: t('NO-OTHER-OWNS') },
                      ],
                    }}
                    menuStyle={kycRadioStyle}
                    checked={formData.kyc.isOtherOwns25}
                  />
                </div>
              </ul>
            </section>
            <section className='mt-[40px]'>
              {showOwnerRows && (
                <>
                  <section className='user-form__container !mt-0'>
                    {/* <header></header> */}
                    <div className='w-full h-[1px] bg-[rgba(0,_0,_0,_0.2)]'></div>
                    <ul className='form__container'>
                      <p className='mt-[40px] mb-5' style={{ fontSize: '14px', lineHeight: '24px', fontWeight: 500 }}>
                        {t('KYC-POSITION-R')}
                      </p>
                    </ul>
                    <div className='w-full'>
                      {ownerList.map((owner, index) => {
                        return (
                          <div
                            className='flex row justify-stretch align-super place-items-end gap-3'
                            key={index}
                            style={{ marginBottom: 14, width: '560px' }}
                          >
                            <TextInput
                              value={owner.name}
                              onChange={(new_value) => handleOwnerList(new_value, 'name', index)}
                              meta={{
                                label: index === 0 && t('KYC-OWNERNAME1'),
                                className: 'point',
                                required: true,
                              }}
                            />
                            <TextInput
                              value={owner.position}
                              onChange={(new_value) => handleOwnerList(new_value, 'position', index)}
                              meta={{
                                label: index === 0 && t('KYC-OWNERPOS1'),
                                className: 'point',
                                required: true,
                              }}
                              required={true}
                            />
                            <div>
                              <Button
                                type={'delete-button'}
                                onClick={() => deleteOwner(index)}
                                label={isKorean ? '삭제' : 'Delete'}
                                style={{
                                  width: '60px',
                                  height: '50px',
                                  color: '#BDBDBD',
                                }}
                              ></Button>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    <div className='flex items-center justify-center mt-5 mb-8' style={{ marginRight: 10 }}>
                      <AddRoundedIcon style={{ cursor: 'pointer', color: '#5D38E5' }} />
                      <Button
                        type='no-outline'
                        onClick={() => addOwner()}
                        label={isKorean ? '추가하기' : 'Add'}
                        style={{
                          width: '60px',
                          fontWeight: 500,
                          color: '#5D38E5',
                          fontSize: '16px',
                          lineHeight: '20px',
                        }}
                      />
                    </div>
                  </section>
                </>
              )}
            </section>
            <section className='button__container '>
              <Button
                style={{ width: '180px', marginRight: 10 }}
                onClick={() => {
                  setStep(0);
                }}
                label={t('PREV')}
                type='secondary'
              ></Button>
              <Button
                style={{ width: '180px' }}
                onClick={() => {
                  requestSignup();
                }}
                label={t('NEXT')}
              ></Button>
            </section>
          </>
        )}
        {step === 2 && (
          <>
            <section className='user-eamil__container'>
              <section className='mail__box'>
                <div className='image'></div>
                <h1>{MSG['EMAIL_SEND']}</h1>
              </section>
              <section className='value__box'>
                <h2>{formData.email}</h2>
              </section>
              <section className='guide__box'>
                <span>{MSG['EMAIL_SEND_SIGN_UP']}</span>
              </section>
              <section className='info__box'>
                <ul>
                  <li>* {MSG['CHECK_SPAM_MAIL']}</li>
                  <li>
                    *{' '}
                    <em
                      onClick={() => {
                        API.User.PutSignupEmailResend({ key: signUpResult.key }).then(
                          (res) => {
                            updateSnackbar({
                              type: 'success',
                              open: true,
                              message: MSG['MAIL_RESEND_SUCCESS'],
                            });
                          },
                          (err) => {
                            updateSnackbar({
                              type: 'error',
                              open: true,
                              message: err.data.message,
                            });
                          },
                        );
                      }}
                    >
                      {t('IF_YOU_NEED_RESEND')}
                    </em>
                  </li>
                </ul>
              </section>
            </section>
          </>
        )}
      </article>
    </article>
  );
};
