// import { useState } from 'react';

// export const useForm = (value, meta) => {
//   const [form, setForm] = useState(value);
//   const [formMeta, setFormMeta] = useState(meta);

//   const onChange = (newValue, key) => {
//     setForm({ ...form, [key]: newValue });
//   };

//   const reset = () => {
//     setForm(value);
//   };

//   return [form, formMeta, onChange, setForm, setFormMeta, reset];
// };
import { useState } from 'react';

export const useForm = (value, meta) => {
  const [form, setForm] = useState(value);
  const [formMeta, setFormMeta] = useState(meta);

  const onChange = (newValue, key) => {
    setForm((prevForm) => {
      if (key.includes('.')) {
        const keys = key.split('.');
        const updatedForm = { ...prevForm };
        let nested = updatedForm;

        for (let i = 0; i < keys.length - 1; i++) {
          nested[keys[i]] = { ...nested[keys[i]] };
          nested = nested[keys[i]];
        }

        nested[keys[keys.length - 1]] = newValue;
        return updatedForm;
      } else {
        return { ...prevForm, [key]: newValue };
      }
    });
  };

  const reset = () => {
    setForm(value);
  };

  return [form, formMeta, onChange, setForm, setFormMeta, reset];
};
