// import CircularProgress from '@mui/material/CircularProgress';
// import React from 'react';
// import { useTranslation } from 'react-i18next';
// import Arrow from '../../styles/icon/arrow.svg';

// export const Button = (props) => {
//   const { label, onClick, style, type, loading, disabled, ico } = props;
//   const { t } = useTranslation();

//   const renderClassname = () => {
//     if (type) {
//       return type + '-button';
//     } else {
//       return 'basic-button';
//     }
//   };

//   return (
//     <article className={renderClassname()} style={{ ...style }}>
//       <button disabled={loading || disabled} onClick={onClick} style={{ fontFamily: "'Pretendard', sans-serif" }}>
//         {!loading && ico && <div className={'ico ' + ico} style={{ marginRight: 8 }}></div>}
//         {loading ? <CircularProgress size={14}></CircularProgress> : t(label)}
//       </button>
//       <img src={Arrow} width={'20px'} alt='' />
//     </article>
//   );
// };

import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Arrow from '../../styles/icon/arrow.svg';

export const Button = (props) => {
  const { label, onClick, style, type, loading, disabled, ico } = props;
  const { t } = useTranslation();

  const renderClassname = () => {
    if (type) {
      return type + '-button';
    } else {
      return 'basic-button';
    }
  };

  const customStyles = {
    ...(type === 'no-outline' && {
      background: 'none',
      border: 'none',
      outline: 'none',
      padding: 0,
      color: 'inherit',
      cursor: 'pointer',
    }),
    ...(type === 'delete-button' && {
      background: 'none',
      border: '1px solid #D7DAE2',
      outline: 'none',
      padding: 0,
      borderRadius: '10px',
      color: 'black',
      cursor: 'pointer',
    }),
    fontFamily: "'Pretendard', sans-serif",
    ...style,
  };

  return (
    <article className={renderClassname()} style={{ ...style }}>
      <button disabled={loading || disabled} onClick={onClick} style={customStyles}>
        {!loading && ico && <div className={'ico ' + ico} style={{ marginRight: 8 }}></div>}
        {loading ? <CircularProgress size={14}></CircularProgress> : t(label)}
      </button>

      {type !== 'no-outline' && type !== 'delete-button' && <img src={Arrow} width={'20px'} alt='' />}
    </article>
  );
};
