import Button from '@mui/material/Button';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Handler from '../../Handler';
import API from '../../api';
import { ContentRegistry, PageHeader } from '../../components';
import { DetailDocumentOld } from '../../components/table/DetailDocumentOld';
import { LangContext, SnackbarContext } from '../../contexts';
import { useLabel } from '../../hooks';

export const MethodologyRegistryDetailPage = () => {
  const history = useHistory();
  const category = 'methodology';
  const { t } = useTranslation();
  const { LABEL, TABLE, ROUTE_PATH } = useLabel();
  const { slug } = useParams();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { isKorean } = useContext(LangContext);
  const [initData, setInitData] = useState(null);

  const getDetailData = useCallback(
    (slug) => {
      API.Registry.GetRegistryDetail(category, slug)
        .then((res) => {
          let result = res.data;
          setInitData(result);
        })
        .catch((err) => {
          history.go(-1);
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
          return;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateSnackbar],
  );

  const methodologyDocData = useMemo(() => {
    if (initData?.documents?.length > 0) return Handler.genDocumentGroup(initData.documents);
    return [];
  }, [initData]);

  useEffect(() => {
    if (slug) {
      getDetailData(slug);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, getDetailData]);

  if (!initData) return <></>;

  return (
    <article className='basic-page'>
      <PageHeader
        type='methodology-registry'
        title={isKorean ? initData?.title : initData?.titleEn}
        parentPath='/registry/methodology/list'
        parentText={ROUTE_PATH['METHODOLOGY']}
        detail={LABEL['METHODOLOGY_DETAIL']}
      ></PageHeader>
      <section className='content__wrapper'>
        <article className='w-full'>
          <div>
            <p className='mb-2 text-gray-600'>{isKorean ? initData?.introOne : initData?.introOneEn}</p>
            <p className='text-gray-600'>{isKorean ? initData?.introTwo : initData?.introTwoEn}</p>
          </div>
        </article>
        <article className='py-16 w-full'>
          <section className='text-left'>
            <h2 className='mb-4'>{TABLE['METHODOLOGY_DETAIL_INFO']}</h2>
            <ContentRegistry category={category} data={initData} />
          </section>
          <section className='mt-16'>
            <h2 className='mb-4'>{t('DOCUMENTS')}</h2>
            <DetailDocumentOld docData={methodologyDocData} type='methodology' />
          </section>
        </article>
        <article>
          <Button color='primary' variant='outlined' onClick={() => history.push('/registry/methodology/list')}>
            {t('LIST')}
          </Button>
        </article>
      </section>
    </article>
  );
};
