import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-scroll';
import { LangContext } from '../../contexts';
import Calendar from '../../styles/icon/calendar-icon.svg';
import PersonIcon from '../../styles/icon/person-icon.svg';
export const StandardDownload = (props) => {
  const {
    title,
    description,
    file,
    downloadLabel,
    additionalFile,
    additionalDownloadLabel,
    subject,
    date,
    versionButtonStandard,
    versionButtonRegistry,
  } = props;
  const [previewed, setPreviewed] = useState(false);
  const { isKorean } = useContext(LangContext) || { isKorean: false };
  const history = useHistory();

  const handleDownload = async () => {
    const response = await fetch(file);
    if (!response.ok) {
      throw new Error('네트워크 응답이 좋지 않습니다.');
    }
    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = file.split('/').pop() || title || 'download';

    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(blobUrl);
  };

  const gotoRegistyPage = () => {
    history.push('/registry/terms');
  };
  const gotoStandardPage = () => {
    history.push('/program/standard');
  };
  return (
    <div className='flex border border-solid border-gray-300' style={{ height: '298px', width: '284px' }}>
      <section style={{ padding: '24px' }}>
        <div className='h-[90px]' style={{ width: '236px' }}>
          <p
            className='mr-1 flex'
            style={{
              fontSize: 20,
              lineHeight: '28px',
              fontWeight: 'semi-bold',
              overflowWrap: 'normal',
              wordBreak: 'keep-all',
            }}
          >
            {title}
          </p>
          <div className='flex mt-3'>
            {versionButtonStandard && (
              <>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    alignContent: 'center',
                    marginTop: 5,
                  }}
                >
                  <Button
                    variant='outlined'
                    className=''
                    onClick={gotoStandardPage}
                    style={{
                      fontWeight: 500,
                      color: '#5D38E5',
                      background: 'none',
                      lineHeight: '18px',
                      padding: 0,
                      fontSize: '14px',
                    }}
                  >
                    {versionButtonStandard}
                  </Button>
                  {<ArrowForwardIcon style={{ color: '#5D38E5', fontSize: '16px', margin: 1 }} />}
                </div>
              </>
            )}
            {versionButtonRegistry && (
              <>
                <div style={{ display: 'flex', alignItems: 'center', alignContent: 'center', marginTop: 5 }}>
                  <Button
                    variant='outlined'
                    className=''
                    onClick={gotoRegistyPage}
                    style={{
                      fontWeight: 500,
                      color: '#5D38E5',
                      background: 'none',
                      lineHeight: '18px',
                      padding: 0,
                      fontSize: '14px',
                    }}
                  >
                    {versionButtonRegistry}
                  </Button>
                  {<ArrowForwardIcon style={{ color: '#5D38E5', fontSize: '16px', margin: 1 }} />}
                </div>
              </>
            )}
          </div>
        </div>

        <div style={{ borderTop: '1px solid #D7DAE2', width: 236, marginTop: '32px' }}>
          <div className='pl-[2px]'>
            <div
              className='flex justify-start items-center gap-2 mt-3'
              style={{ display: 'flex', alignItems: 'center', alignContent: 'center' }}
            >
              <img src={PersonIcon} style={{ fontSize: '14px', marginTop: 1 }} alt='Person icon' />
              <span className='text-[#606369] mt-1 text-[13px]'>{subject || '모든 이용자'}</span>
            </div>

            <div
              className='flex justify-start items-center gap-2 mt-1'
              style={{ display: 'flex', alignItems: 'center', alignContent: 'center' }}
            >
              <img src={Calendar} style={{ fontSize: '18px', marginTop: 1 }} alt='Calendar icon' />
              <span className='text-[#606369] text-[13px] mt-1'>
                {date || isKorean ? '2024.11.14' : 'Nov 14, 2024'}{' '}
              </span>
            </div>
          </div>
          <div className='mt-7'>
            <Button
              variant='contained'
              className='flex '
              onClick={handleDownload}
              style={{
                height: 44,
                width: 236,
                borderRadius: 0,
                background: 'black',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '16px',
              }}
            >
              <span className='text-white' style={{ fontSize: '15px', fontWeight: 500 }}>
                {isKorean ? '다운로드' : 'Download'}
              </span>
            </Button>
          </div>
        </div>
      </section>
    </div>
  );
};
