import { Button, Tab, Tabs } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader, StandardDownload } from '../../components';
import { RULE_FILE_LIST, STANDARD_FILES } from '../../constants';
import { LangContext } from '../../contexts';
import { useLabel } from '../../hooks';

const styles = {
  paragraph: {
    fontSize: '16px',
    color: '#606369',
    fontWeight: 300,
  },
  sideBar: {
    position: 'sticky',
    display: 'block',
    top: '200px',
    margin: 20,
  },
  button: {
    borderRadius: '0',
    height: 44,
    width: 215,
    marginRight: '10px',
    fontSize: '15px',
    lineHeight: '20px',
    padding: '10px',
    paddingRight: 0,
  },
};

export const DocumentPage = (props) => {
  const { isKorean } = useContext(LangContext);
  const { STANDARD_DOCUMENT_PAGE } = useLabel();
  const { DOCUMENT_PAGE } = useLabel();
  const { t } = useTranslation();
  const [activeLink, setActiveLink] = useState('');
  const ruleList = useMemo(() => {
    return STANDARD_DOCUMENT_PAGE['RULES']['REQUIREMENTS'].map((el, idx) => {
      return {
        title: el['TITLE'],
        subject: el['SUBJECT'],
        versionButtonVersion: el['VERSION'],
        versionButtonRegistry: el['REVISION'],
        description: el['DESCRIPTION'],
        fileName: isKorean ? RULE_FILE_LIST[idx].fileKo : RULE_FILE_LIST[idx].fileEn,
        downloadLabel: isKorean ? RULE_FILE_LIST[idx].downloadLabelKo : RULE_FILE_LIST[idx].downloadLabelEn,
        additionalFile: isKorean ? RULE_FILE_LIST[idx].additionalFileKo : RULE_FILE_LIST[idx].additionalFileEn,
        additionalDownloadLabel: isKorean
          ? RULE_FILE_LIST[idx].additionalDownloadLabelKo
          : RULE_FILE_LIST[idx].additionalDownloadLabelEn,
      };
    });
  }, [STANDARD_DOCUMENT_PAGE, isKorean]);

  const handleNavClick = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const headerOffset = 200;
      const sectionPosition = section.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = sectionPosition - headerOffset;
      setActiveLink(sectionId);
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    setActiveLink('requirements');
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.target.id) {
            setActiveLink(entry.target.id);
          }
        });
      },
      { threshold: 0.7, rootMargin: '0px 0% -30% 0px' },
    );

    const sections = document.querySelectorAll('section');
    sections.forEach((section) => observer.observe(section));

    return () => observer.disconnect();
  }, []);

  const inactiveLinkStyle = {
    color: '#999EAD',
    fontWeight: 400,
  };
  const activeLinkStyle = {
    color: '#5D38E5',
    fontWeight: 600,
  };

  const [selectedTab, setSelectedTab] = useState('프로젝트');

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <article className='basic-page'>
      <article className='basic-page bg-[#FAF9FE] h-full pb-6'>
        <PageHeader className='flex w-full' type='document'>
          <div className='flex justify-between w-full'>
            <h1 style={{ fontWeight: 600 }}>{`${DOCUMENT_PAGE['TITLE']}`}</h1>
          </div>
        </PageHeader>
      </article>
      <div className='py-[80px] px-[1200px]' style={{ display: 'flex', top: '200px' }}>
        <div className='fixed-sidebar mr-[100px]'>
          <nav className='fixed-sidebar flex' style={styles.sideBar}>
            <div
              style={{
                borderRight: '1px solid #D7DAE2',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'right',
                justifyContent: 'flex-end',
                paddingRight: 24,
                width: 170,
                alignContent: 'flex-end',
              }}
            >
              <Button
                onClick={() => handleNavClick('requirements')}
                style={{
                  ...(activeLink === 'requirements' ? styles.activeLink : inactiveLinkStyle),
                  paddingRight: '0',
                  textAlign: 'right',
                  width: '100%',
                  justifyContent: 'flex-end',
                }}
              >
                {DOCUMENT_PAGE['REQUIREMENTS']}
              </Button>
              <Button
                onClick={() => handleNavClick('manual')}
                style={{
                  ...(activeLink === 'manual' ? styles.activeLink : inactiveLinkStyle),
                  paddingRight: '0',
                  textAlign: 'right',
                  width: '100%',
                  justifyContent: 'flex-end',
                }}
              >
                {DOCUMENT_PAGE['MANUAL']['TITLE']}
              </Button>
              <Button
                onClick={() => handleNavClick('policy')}
                style={{
                  ...(activeLink === 'policy' ? styles.activeLink : inactiveLinkStyle),
                  paddingRight: '0',
                  textAlign: 'right',
                  width: '100%',
                  justifyContent: 'flex-end',
                }}
              >
                {DOCUMENT_PAGE['GOVERNANCE']['TITLE']}
              </Button>
              <Button
                onClick={() => handleNavClick('template')}
                style={{
                  ...(activeLink === 'template' ? styles.activeLink : inactiveLinkStyle),
                  paddingRight: '0',
                  textAlign: 'right',
                  width: '100%',
                  justifyContent: 'flex-end',
                }}
              >
                {DOCUMENT_PAGE['TEMPLATE']}
              </Button>
              <Button
                onClick={() => handleNavClick('other')}
                style={{
                  ...(activeLink === 'other' ? styles.activeLink : inactiveLinkStyle),
                  paddingRight: '0',
                  textAlign: 'right',
                  width: '100%',
                  justifyContent: 'flex-end',
                }}
              >
                {DOCUMENT_PAGE['OTHER']['TITLE']}
              </Button>
            </div>
          </nav>
        </div>
        <div>
          <section className='w-full py-[20px]'>
            <section id='requirements' className='mb-[100px]'>
              <article className='flex '>
                <p className='text-[20px] mb-8'>{STANDARD_DOCUMENT_PAGE['RULES']['TITLE']}</p>
              </article>
              <div className='flex flex-wrap gap-4 w-[894px]'>
                <div>
                  <StandardDownload
                    title={isKorean ? RULE_FILE_LIST[7].nameKo : RULE_FILE_LIST[7].nameEn}
                    versionButtonStandard={isKorean ? '이전 버전 보기' : 'REVISION HISTORY'}
                    subject={isKorean ? RULE_FILE_LIST[7].subjectKo : RULE_FILE_LIST[7].subjectEn}
                    file={isKorean ? RULE_FILE_LIST[7].fileKo : RULE_FILE_LIST[7].fileEn}
                    downloadLabel={isKorean ? RULE_FILE_LIST[2].downloadLabelKo : RULE_FILE_LIST[2].downloadLabelEn}
                    additionalFile={isKorean ? STANDARD_FILES[2].additionalFileKo : STANDARD_FILES[2].additionalFileEn}
                    additionalDownloadLabel={
                      isKorean
                        ? STANDARD_FILES[2].additionalDownloadLabelKo
                        : STANDARD_FILES[2].additionalDownloadLabelEn
                    }
                  />
                </div>

                <StandardDownload
                  title={isKorean ? RULE_FILE_LIST[2].nameKo : RULE_FILE_LIST[2].nameEn}
                  subject={isKorean ? RULE_FILE_LIST[2].subjectKo : RULE_FILE_LIST[2].subjectEn}
                  description={isKorean ? RULE_FILE_LIST[2].descriptionKo : RULE_FILE_LIST[2].descriptionEn}
                  file={isKorean ? RULE_FILE_LIST[2].fileKo : RULE_FILE_LIST[2].fileEn}
                  downloadLabel={isKorean ? STANDARD_FILES[2].downloadLabelKo : STANDARD_FILES[2].downloadLabelEn}
                  additionalFile={isKorean ? STANDARD_FILES[2].additionalFileKo : STANDARD_FILES[2].additionalFileEn}
                  additionalDownloadLabel={
                    isKorean ? STANDARD_FILES[2].additionalDownloadLabelKo : STANDARD_FILES[2].additionalDownloadLabelEn
                  }
                />

                <StandardDownload
                  title={isKorean ? RULE_FILE_LIST[3].nameKo : RULE_FILE_LIST[3].nameEn}
                  subject={isKorean ? RULE_FILE_LIST[3].subjectKo : RULE_FILE_LIST[3].subjectEn}
                  file={isKorean ? RULE_FILE_LIST[3].fileKo : RULE_FILE_LIST[3].fileEn}
                  downloadLabel={isKorean ? STANDARD_FILES[2].downloadLabelKo : STANDARD_FILES[2].downloadLabelEn}
                  additionalFile={isKorean ? STANDARD_FILES[2].additionalFileKo : STANDARD_FILES[2].additionalFileEn}
                  additionalDownloadLabel={
                    isKorean ? STANDARD_FILES[2].additionalDownloadLabelKo : STANDARD_FILES[2].additionalDownloadLabelEn
                  }
                />
                {/* {ruleList.map((e, idx) => (
                  <StandardDownload
                    key={`rule-${idx}`}
                    title={e.title}
                    // description={e.description}
                    file={e.fileName}
                    // downloadLabel={e.downloadLabel}
                    // additionalFile={e.additionalFile}
                    // additionalDownloadLabel={e.additionalDownloadLabel}
                  />
                ))} */}
              </div>
            </section>
            <section className='pb-12 mt-5 gap-4' id='manual'>
              <p className='text-[20px] mb-8'>{DOCUMENT_PAGE['MANUAL']['TITLE']}</p>
              <div className='mb-6 flex flex-wrap gap-4 w-[894px]'>
                <StandardDownload
                  title={isKorean ? RULE_FILE_LIST[9].nameKo : RULE_FILE_LIST[9].nameEn}
                  subject={isKorean ? RULE_FILE_LIST[9].subjectKo : RULE_FILE_LIST[9].subjectEn}
                  file={isKorean ? RULE_FILE_LIST[9].fileKo : RULE_FILE_LIST[9].fileEn}
                />
                <StandardDownload
                  title={isKorean ? RULE_FILE_LIST[8].nameKo : RULE_FILE_LIST[8].nameEn}
                  subject={isKorean ? RULE_FILE_LIST[8].subjectKo : RULE_FILE_LIST[8].subjectEn}
                  version={isKorean ? RULE_FILE_LIST[8].versionKo : RULE_FILE_LIST[8].versionEn}
                  file={isKorean ? RULE_FILE_LIST[8].fileKo : RULE_FILE_LIST[8].fileEn}
                />
              </div>
            </section>
            <section className='mb-[100px] gap-5' id='policy'>
              <div className='mb-10 mt-10'>
                <p className='text-[20px] mb-8'>{DOCUMENT_PAGE['GOVERNANCE']['TITLE']}</p>
                <div className='flex flex-wrap space-between gap-4'>
                  <StandardDownload
                    title={isKorean ? STANDARD_FILES[0].nameKo : STANDARD_FILES[0].nameEn}
                    subject={isKorean ? STANDARD_FILES[0].subjectKo : STANDARD_FILES[0].subjectEn}
                    description={isKorean ? STANDARD_FILES[0].descriptionKo : STANDARD_FILES[0].descriptionEn}
                    file={isKorean ? STANDARD_FILES[0].fileKo : STANDARD_FILES[0].fileEn}
                    downloadLabel={isKorean ? STANDARD_FILES[0].downloadLabelKo : STANDARD_FILES[0].downloadLabelEn}
                    additionalFile={isKorean ? STANDARD_FILES[0].additionalFileKos : STANDARD_FILES[0].additionalFileEn}
                    additionalDownloadLabel={
                      isKorean
                        ? STANDARD_FILES[0].additionalDownloadLabelKo
                        : STANDARD_FILES[0].additionalDownloadLabelEn
                    }
                  />
                  <StandardDownload
                    title={isKorean ? RULE_FILE_LIST[10].nameKo : RULE_FILE_LIST[10].nameEn}
                    subject={isKorean ? RULE_FILE_LIST[10].subjectKo : RULE_FILE_LIST[10].subjectEn}
                    file={isKorean ? RULE_FILE_LIST[10].fileKo : RULE_FILE_LIST[10].fileEn}
                  />
                  <StandardDownload
                    title={isKorean ? RULE_FILE_LIST[11].nameKo : RULE_FILE_LIST[11].nameEn}
                    subject={isKorean ? RULE_FILE_LIST[11].subjectKo : RULE_FILE_LIST[11].subjectEn}
                    file={isKorean ? RULE_FILE_LIST[11].fileKo : RULE_FILE_LIST[11].fileEn}
                  />
                  <StandardDownload
                    title={isKorean ? RULE_FILE_LIST[12].nameKo : RULE_FILE_LIST[12].nameEn}
                    subject={isKorean ? RULE_FILE_LIST[12].subjectKo : RULE_FILE_LIST[12].subjectEn}
                    file={isKorean ? RULE_FILE_LIST[12].fileKo : RULE_FILE_LIST[12].fileEn}
                  />

                  <StandardDownload
                    title={isKorean ? RULE_FILE_LIST[13].nameKo : RULE_FILE_LIST[13].nameEn}
                    subject={isKorean ? RULE_FILE_LIST[13].subjectKo : RULE_FILE_LIST[13].subjectEn}
                    file={isKorean ? RULE_FILE_LIST[13].fileKo : RULE_FILE_LIST[13].fileEn}
                  />
                  <StandardDownload
                    title={isKorean ? RULE_FILE_LIST[27].nameKo : RULE_FILE_LIST[27].nameEn}
                    subject={isKorean ? RULE_FILE_LIST[27].subjectKo : RULE_FILE_LIST[27].subjectEn}
                    file={isKorean ? RULE_FILE_LIST[27].fileKo : RULE_FILE_LIST[27].fileEn}
                  />
                </div>
              </div>
            </section>
            <section className='mb-[100px] gap-5' id='template'>
              <p className='text-[20px]'>{DOCUMENT_PAGE['TEMPLATE']}</p>
              <div className='flex mt-9' style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                <Tabs
                  value={selectedTab}
                  onChange={handleTabClick}
                  aria-label='tabs example'
                  variant='fullWidth'
                  style={{
                    fontSize: '16px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    background: 'transparent',
                    boxShadow: 'none',
                  }}
                  sx={{
                    '&active': {
                      background: 'transparent',
                      boxShadow: 'none',
                    },
                  }}
                >
                  <div
                    className='flex'
                    style={{
                      borderRight: '1px solid #999EAD',
                      height: 18,
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Tab
                      label={isKorean ? '프로젝트' : 'Project'}
                      className={selectedTab === '프로젝트' ? 'active' : ''}
                      sx={{
                        color: selectedTab === '프로젝트' ? '#5D38E5' : '#999EAD',
                        fontWeight: selectedTab === '프로젝트' ? 500 : 300,
                        textDecoration: selectedTab === '프로젝트' ? 'underline' : 'none',
                        alignItems: 'flex-start',
                        padding: 0,
                        marginBottom: 0,
                        marginRight: '-15px',
                        fontSize: '16px',
                        backgroundColor: selectedTab === 'transparent',
                        background: selectedTab === '프로젝트' ? 'transparent' : 'transparent',
                        opacity: 1,

                        textTransform: 'none',
                      }}
                      onClick={() => handleTabClick('프로젝트')}
                    ></Tab>
                  </div>

                  <div
                    className='flex'
                    style={{
                      borderRight: '1px solid #999EAD',
                      height: 18,
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Tab
                      label={isKorean ? '방법론' : 'Methodology'}
                      className={selectedTab === '방법론' ? 'active' : ''}
                      onClick={() => handleTabClick('방법론')}
                      sx={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        boxShadow: 'none',
                        color: selectedTab === '방법론' ? '#5D38E5' : '#999EAD',
                        fontWeight: selectedTab === '방법론' ? 500 : 300,
                        textDecoration: selectedTab === '방법론' ? 'underline' : 'none',
                        alignItems: 'flex-start',
                        fontSize: '16px',
                        padding: 0,
                        paddingLeft: '15px',
                        marginRight: isKorean ? '-15px' : '15px',
                        opacity: 1,
                        textTransform: 'none',
                      }}
                    ></Tab>
                  </div>
                  <div className='flex mr-2' style={{ height: 18, alignItems: 'center' }}>
                    <Tab
                      label={isKorean ? '그 외' : 'Others'}
                      className={selectedTab === '그외' ? 'active' : ''}
                      onClick={() => handleTabClick('그외')}
                      sx={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: selectedTab === '그외' ? '#5D38E5' : '#999EAD',
                        fontWeight: selectedTab === '그외' ? 500 : 300,
                        textDecoration: selectedTab === '그외' ? 'underline' : 'none',
                        alignItems: 'flex-start',
                        fontSize: '16px',
                        padding: 0,
                        paddingLeft: '16px',
                        opacity: 1,
                        textTransform: 'none',
                      }}
                    ></Tab>
                  </div>
                </Tabs>
              </div>
              <div
                className='flex mt-[-10px]'
                role='tabpanel'
                hidden={selectedTab !== '#999EAD'}
                style={{ justifyContent: 'flex-start' }}
              >
                {selectedTab === '프로젝트' && (
                  <div className='flex gap-4'>
                    <StandardDownload
                      title={isKorean ? RULE_FILE_LIST[14].nameKo : RULE_FILE_LIST[14].nameEn}
                      subject={isKorean ? RULE_FILE_LIST[14].subjectKo : RULE_FILE_LIST[14].subjectEn}
                      file={isKorean ? RULE_FILE_LIST[14].fileKo : RULE_FILE_LIST[14].fileEn}
                    />
                    <StandardDownload
                      title={isKorean ? RULE_FILE_LIST[4].nameKo : RULE_FILE_LIST[4].nameEn}
                      subject={isKorean ? RULE_FILE_LIST[4].subjectKo : RULE_FILE_LIST[4].subjectEn}
                      file={isKorean ? RULE_FILE_LIST[4].fileKo : RULE_FILE_LIST[4].fileEn}
                    />
                  </div>
                )}
                <div role='tabpanel' hidden={selectedTab !== '방법론'}>
                  {selectedTab === '방법론' && (
                    <div className='flex flex-wrap space-between gap-4'>
                      <StandardDownload
                        title={isKorean ? RULE_FILE_LIST[15].nameKo : RULE_FILE_LIST[15].nameEn}
                        subject={isKorean ? RULE_FILE_LIST[15].subjectKo : RULE_FILE_LIST[15].subjectEn}
                        file={isKorean ? RULE_FILE_LIST[15].fileKo : RULE_FILE_LIST[15].fileEn}
                      />
                      <StandardDownload
                        title={isKorean ? RULE_FILE_LIST[16].nameKo : RULE_FILE_LIST[16].nameEn}
                        subject={isKorean ? RULE_FILE_LIST[16].subjectKo : RULE_FILE_LIST[16].subjectEn}
                        file={isKorean ? RULE_FILE_LIST[16].fileKo : RULE_FILE_LIST[16].fileEn}
                      />
                      <StandardDownload
                        title={isKorean ? RULE_FILE_LIST[17].nameKo : RULE_FILE_LIST[17].nameEn}
                        subject={isKorean ? RULE_FILE_LIST[17].subjectKo : RULE_FILE_LIST[17].subjectEn}
                        file={isKorean ? RULE_FILE_LIST[17].fileKo : RULE_FILE_LIST[17].fileEn}
                      />
                      <StandardDownload
                        title={isKorean ? RULE_FILE_LIST[18].nameKo : RULE_FILE_LIST[18].nameEn}
                        subject={isKorean ? RULE_FILE_LIST[18].subjectKo : RULE_FILE_LIST[18].subjectEn}
                        file={isKorean ? RULE_FILE_LIST[18].fileKo : RULE_FILE_LIST[18].fileEn}
                      />
                      <StandardDownload
                        title={isKorean ? RULE_FILE_LIST[19].nameKo : RULE_FILE_LIST[19].nameEn}
                        subject={isKorean ? RULE_FILE_LIST[19].subjectKo : RULE_FILE_LIST[19].subjectEn}
                        file={isKorean ? RULE_FILE_LIST[19].fileKo : RULE_FILE_LIST[19].fileEn}
                      />
                    </div>
                  )}
                </div>
                <div role='tabpanel' hidden={selectedTab !== '그외'}>
                  {selectedTab === '그외' && (
                    <div className='flex flex-wrap space-between gap-4'>
                      <StandardDownload
                        title={isKorean ? RULE_FILE_LIST[20].nameKo : RULE_FILE_LIST[20].nameEn}
                        subject={isKorean ? RULE_FILE_LIST[20].subjectKo : RULE_FILE_LIST[20].subjectEn}
                        file={isKorean ? RULE_FILE_LIST[20].fileKo : RULE_FILE_LIST[20].fileEn}
                      />
                      <StandardDownload
                        title={isKorean ? RULE_FILE_LIST[21].nameKo : RULE_FILE_LIST[21].nameEn}
                        subject={isKorean ? RULE_FILE_LIST[21].subjectKo : RULE_FILE_LIST[21].subjectEn}
                        file={isKorean ? RULE_FILE_LIST[21].fileKo : RULE_FILE_LIST[21].fileEn}
                      />
                      <StandardDownload
                        title={isKorean ? RULE_FILE_LIST[22].nameKo : RULE_FILE_LIST[22].nameEn}
                        subject={isKorean ? RULE_FILE_LIST[22].subjectKo : RULE_FILE_LIST[22].subjectEn}
                        file={isKorean ? RULE_FILE_LIST[22].fileKo : RULE_FILE_LIST[22].fileEn}
                      />
                      <StandardDownload
                        title={isKorean ? RULE_FILE_LIST[23].nameKo : RULE_FILE_LIST[23].nameEn}
                        subject={isKorean ? RULE_FILE_LIST[23].subjectKo : RULE_FILE_LIST[23].subjectEn}
                        file={isKorean ? RULE_FILE_LIST[23].fileKo : RULE_FILE_LIST[23].fileEn}
                      />
                      <StandardDownload
                        title={isKorean ? RULE_FILE_LIST[24].nameKo : RULE_FILE_LIST[24].nameEn}
                        subject={isKorean ? RULE_FILE_LIST[24].subjectKo : RULE_FILE_LIST[24].subjectEn}
                        file={isKorean ? RULE_FILE_LIST[24].fileKo : RULE_FILE_LIST[24].fileEn}
                      />
                      <StandardDownload
                        title={isKorean ? RULE_FILE_LIST[25].nameKo : RULE_FILE_LIST[25].nameEn}
                        subject={isKorean ? RULE_FILE_LIST[25].subjectKo : RULE_FILE_LIST[25].subjectEn}
                        file={isKorean ? RULE_FILE_LIST[25].fileKo : RULE_FILE_LIST[25].fileEn}
                      />
                      <StandardDownload
                        title={isKorean ? RULE_FILE_LIST[28].nameKo : RULE_FILE_LIST[28].nameEn}
                        subject={isKorean ? RULE_FILE_LIST[28].subjectKo : RULE_FILE_LIST[28].subjectEn}
                        file={isKorean ? RULE_FILE_LIST[28].fileKo : RULE_FILE_LIST[28].fileEn}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className='flex flex-wrap mb-6 gap-4 w-[894px] '></div>
            </section>
            <section className='pb-12 gap-5' id='other'>
              <p className='text-[20px] mb-8'>{DOCUMENT_PAGE['OTHER']['TITLE']}</p>
              <div className='mb-6'>
                <StandardDownload
                  title={isKorean ? RULE_FILE_LIST[26].nameKo : RULE_FILE_LIST[26].nameEn}
                  subject={isKorean ? RULE_FILE_LIST[26].subjectKo : RULE_FILE_LIST[26].subjectEn}
                  file={isKorean ? RULE_FILE_LIST[26].fileKo : RULE_FILE_LIST[26].fileEn}
                  versionButtonRegistry={isKorean ? '이전 버전 보기' : 'VERSION HISTORY'}
                />
              </div>
            </section>

            {/* <Divider /> */}
          </section>
        </div>
      </div>
    </article>
  );
};
