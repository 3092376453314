import { useTranslation } from 'react-i18next';
export const useLabel = () => {
  const { t } = useTranslation();
  const { t: TransPrivacy } = useTranslation('privacyTerm');
  const { t: TransUsage } = useTranslation('usageTerm');
  const { t: TransStandard } = useTranslation('standard');
  const { t: TransDocument } = useTranslation('standard');
  const { t: TransProgramDetails } = useTranslation('standard');
  const { t: TransBoard } = useTranslation('standard');
  const { t: TransForm } = useTranslation('form');
  const { t: TransMsg } = useTranslation('msg');
  const ROUTE_PATH = t('ROUTE_PATH', { returnObjects: true });
  const HOME_ICON = t('HOME_ICON_PROCESS', { returnObjects: true });
  const DOC_PROCESS = t('DOC_PROCESS', { returnObjects: true });
  const PROJECT_PROCESS = t('PROJECT_PROCESS', { returnObjects: true });
  const REDUCTION_PROCESS = t('REDUCTION_PROCESS', { returnObjects: true });
  const AREA_TYPE = t('AREA_TYPE', { returnObjects: true });
  const REGISTRY_DETAIL = t('REGISTRY_DETAIL', { returnObjects: true });
  const NAVIGATION = t('NAVIGATION', { returnObjects: true });
  const CONSULTATION_CATEGORY = t('CONSULTATION_CATEGORY', { returnObjects: true });
  const METHODOLOGY_STATUS = t('METHODOLOGY_STATUS', { returnObjects: true });
  const METHODOLOGY_CONSULTATION = t('METHODOLOGY_CONSULTATION', { returnObjects: true });
  const PROJECT_STATUS = t('PROJECT_STATUS', { returnObjects: true });
  const CREDIT_STATUS = t('CREDIT_STATUS', { returnObjects: true });
  const STANDARD_TYPE = t('METHODOLOGY_STANDARD_TYPE', { returnObjects: true });
  const LABEL = t('LABEL', { returnObjects: true });
  const REGISTRY_TERM_PAGE = t('REGISTRY_TERM_PAGE', { returnObjects: true });
  const FILTER = t('FILTER', { returnObjects: true });
  const PURCHASE_REASON_LIST = t('PURCHASE_REASON_LIST', { returnObjects: true });
  const TABLE_HEADER = t('TABLE_HEADER', { returnObjects: true });
  const DOCUMENT_METHODOLOGY = t('DOCUMENT_METHODOLOGY', { returnObjects: true });
  const DOCUMENT_PROJECT = t('DOCUMENT_PROJECT', { returnObjects: true });
  const DOCUMENT = t('DOCUMENT', { returnObjects: true });
  const DOCUMENT_STANDARD = t('DOCUMENT_STANDARD', { returnObjects: true });
  const PRIVACY_TERM = TransPrivacy('PRIVACY', { returnObjects: true });
  const USAGE_TERM = TransUsage('USAGE', { returnObjects: true });
  const COUNTRY = t('COUNTRY', { returnObjects: true });

  // Program Details Page
  const PROGRAM_DETAILS_PAGE = TransProgramDetails('PROGRAM_DETAILS_PAGE', { returnObjects: true });
  const BOARD_PAGE = TransBoard('BOARD_PAGE', { returnObjects: true });
  const BOARD_DETAILS_PAGE = TransBoard('BOARD_PAGE', { returnObjects: true });

  /* Standard Page */

  const STANDARD_PAGE = TransStandard('STANDARD_PAGE', { returnObjects: true });
  const STANDARD_THIRD_PARTY_PAGE = TransStandard('STANDARD_THIRD_PARTY_PAGE', { returnObjects: true });
  const STANDARD_DOCUMENT_PAGE = TransStandard('STANDARD_DOCUMENT_PAGE', { returnObjects: true });

  const DOCUMENT_PAGE = TransStandard('DOCUMENT_PAGE', { returnObjects: true });
  const CONSULTATION_PAGE = TransStandard('CONSULTATION', { returnObjects: true });
  const CONSULTATION_HISTORY_PAGE = TransStandard('CONSULTATION_HISTORY', { returnObjects: true });

  /* FORM */
  const BUTTON = TransForm('BUTTON', { returnObjects: true });
  const TABLE = TransForm('TABLE', { returnObjects: true });
  const CREDIT_STATUS_LABEL = TransForm('CREDIT_STATUS_LABEL', { returnObjects: true });
  const PLACEHOLDER = TransForm('PLACEHOLDER', { returnObjects: true });
  const CONSULTATION_FORM = TransForm('CONSULTATION', { returnObjects: true });

  /* MESSAGE */
  const ERROR = TransMsg('ERROR', { returnObjects: true });
  const TOOLTIPS = TransMsg('TOOLTIPS', { returnObjects: true });
  const MSG = TransMsg('MSG', { returnObjects: true });
  const SIGN_UP_TERM = t('SIGN_UP_TERM', { returnObjects: true });

  return {
    ROUTE_PATH,
    HOME_ICON,
    DOC_PROCESS,
    PROJECT_PROCESS,
    REDUCTION_PROCESS,
    AREA_TYPE,
    REGISTRY_DETAIL,
    CONSULTATION_CATEGORY,
    METHODOLOGY_STATUS,
    METHODOLOGY_CONSULTATION,
    PROJECT_STATUS,
    CREDIT_STATUS,
    STANDARD_TYPE,
    LABEL,
    PROGRAM_DETAILS_PAGE,
    ERROR,
    TOOLTIPS,
    MSG,
    SIGN_UP_TERM,
    STANDARD_PAGE,
    STANDARD_DOCUMENT_PAGE,
    BOARD_PAGE,
    BOARD_DETAILS_PAGE,
    DOCUMENT_PAGE,
    STANDARD_THIRD_PARTY_PAGE,
    CONSULTATION_PAGE,
    CONSULTATION_HISTORY_PAGE,
    REGISTRY_TERM_PAGE,
    FILTER,
    PURCHASE_REASON_LIST,
    TABLE_HEADER,
    DOCUMENT_METHODOLOGY,
    DOCUMENT_PROJECT,
    DOCUMENT_STANDARD,
    DOCUMENT,
    PRIVACY_TERM,
    USAGE_TERM,
    COUNTRY,
    TransPrivacy,
    TransUsage,
    TransForm,
    BUTTON,
    TABLE,
    CREDIT_STATUS_LABEL,
    PLACEHOLDER,
    CONSULTATION_FORM,
    NAVIGATION,
  };
};
