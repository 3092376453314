import styled from '@emotion/styled';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button } from '@mui/material';
import Modal from '@mui/material/Modal';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';
import { ModalPdfView } from '../../components';
import { LangContext } from '../../contexts';
import { useLabel } from '../../hooks';
import ExpandMore from '../../styles/icon/expand-icon.svg';

const TableRow = styled.div`
  display: grid;
  grid-template-columns: 13rem 1fr 120px 120px 120px;
  padding: 10px;
  border: solid 1px;
`;

const TableSubRow = styled.div`
  display: grid;
  grid-template-columns: 360px 120px 120px 120px;
`;

export const DetailDocument = (props) => {
  const { docData, type } = props;
  const ref = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const [document, setDocument] = useState(null);
  const { isKorean } = useContext(LangContext);
  const { TABLE_HEADER, DOCUMENT_METHODOLOGY, DOCUMENT_PROJECT, DOCUMENT_STANDARD, ERROR } = useLabel();
  const { STANDARD_PAGE } = useLabel();
  const handleDocumentModal = useCallback((doc) => {
    setDocument(doc);
    setShowModal(true);
  }, []);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleAccordionChange = () => {
    setIsExpanded(!isExpanded);
  };
  const documentType = useMemo(() => {
    if (type === 'methodology') return DOCUMENT_METHODOLOGY;
    if (type === 'project') return DOCUMENT_PROJECT;
    if (type === 'standard') return DOCUMENT_STANDARD;
    return DOCUMENT_STANDARD;
  }, [type, DOCUMENT_METHODOLOGY, DOCUMENT_PROJECT, DOCUMENT_STANDARD]);

  const docTypeKeys = useMemo(() => {
    if (type === 'methodology') return ['BASIC', 'RESULT', 'CONSULTATION'];
    if (type === 'project') return ['BASIC', 'MONITORING', 'VERIFY', 'RESULT', 'CONSULTATION'];
    if (type === 'standard') return ['HISTORY', 'CONSULTATION'];
    return ['HISTORY', 'CONSULTATION'];
  }, [type]);

  const noDataRow = (type, idx) => (
    <TableSubRow key={`doc-no-data-sub-row-${idx}`} className={`w-full items-center`}>
      {/* <div className='text-gray-400 leading-6 text-center'>{ERROR['NO_DOCUMENTS']}</div> */}
    </TableSubRow>
  );

  return (
    <Accordion
      expanded={isExpanded}
      onChange={handleAccordionChange}
      style={{
        'borderRadius': 0,
        'boxShadow': 'none',
        'border': '1px solid #D7DAE2',
        'borderBottom': '1px solid #D7DAE2',
        '&.Mui-expanded': {
          borderColor: '#333',
          border: '1px solid #D7DAE2',
          borderRadius: 0,
        },
      }}
    >
      <AccordionSummary className='flex bg-slate-100 border-1px-[#D7DAE2]' style={{ height: 84 }}>
        <div className='flex flex-col m-3'>
          <div className='flex justify-start'>
            <p className='text-color[#222222] mr-2'> {STANDARD_PAGE['POPLE_REVISION']} </p>
            <div className='text-color[#777777]'>|</div>
            <p className='ml-2 text-gray-400'> {STANDARD_PAGE['VERSION_HISTORY']} </p>
          </div>
        </div>
        <img
          src={ExpandMore}
          alt='Right Arrow Icon'
          style={{ display: 'flex', marginRight: '15px' }}
          className='ml-auto'
        />
      </AccordionSummary>{' '}
      {isExpanded && (
        <div
          className='mb-3 ml-7 w-[838px] h-px bg-gray-300 border border-[#D7DAE2]'
          style={{
            boxShadow: '0',
          }}
        ></div>
      )}
      <div>
        {docTypeKeys.map((el, idx) => {
          return (
            <AccordionDetails
              key={`doc-data-item-${idx}`}
              aria-controls={`panel${idx}-content`}
              className='w-full border-s black'
            >
              <div
                className='flex justify-center flex-col gap-3'
                style={{ justifyContent: 'center', alignContent: 'center', paddingRight: '5px' }}
              >
                {docData[el]?.length > 0
                  ? docData[el].map((doc, idx) => {
                      const file = isKorean ? doc.attachedFile : doc.attachedFileEn;
                      const title = isKorean ? doc.title : doc.titleEn;
                      const date = Handler.geni18nDate(isKorean, doc.createdAt * 1000);
                      return (
                        <TableSubRow key={`doc-data-sub-row-${idx}`} className='w-[830px] justify-between'>
                          <div className='flex text-center ml-3 text-black'>
                            {idx === 0 && <p className='text-[#5D38E5] font-medium mr-1'>New</p>}
                            {title}
                          </div>

                          <div className='flex text-center leading-6 text-gray-400'>{date}</div>
                          <div className='flex ml-12 '>
                            <div>
                              <Button
                                variant='outlined'
                                sx={{
                                  'height': 40,
                                  'width': 120,
                                  'borderRadius': 0,
                                  'color': 'black',
                                  'borderColor': 'black',
                                  'padding': 0,
                                  '&:hover': {
                                    background: '#fff',
                                    borderColor: 'rgba(153, 153, 153, 1)',
                                    color: 'rgba(153, 153, 153, 1)',
                                  },
                                }}
                                onClick={() => handleDocumentModal(file)}
                              >
                                {isKorean ? '미리보기' : 'Preview'}
                              </Button>
                            </div>
                            <div className='ml-2'>
                              <a href={file} download={file} target='_blank' rel='noreferrer'>
                                <Button
                                  variant='contained'
                                  disableElevation
                                  sx={{
                                    'height': 40,
                                    'width': 120,
                                    'borderRadius': 0,
                                    'padding': '10px 20px',
                                    'background': 'black',
                                    'color': '#FFFFFF',
                                    '&:hover': {
                                      backgroundColor: 'rgba(153, 153, 153, 1)',
                                      color: '#fff',
                                    },
                                  }}
                                >
                                  {isKorean ? '다운로드' : 'Download'}
                                </Button>
                              </a>
                            </div>
                          </div>
                        </TableSubRow>
                      );
                    })
                  : noDataRow(el, 0)}
              </div>
            </AccordionDetails>
          );
        })}
      </div>
      <Modal ref={ref} open={showModal} onClose={() => setShowModal(false)}>
        <Box>
          <ModalPdfView url={document} onClose={() => setShowModal(false)} />
        </Box>
      </Modal>
    </Accordion>
  );
};
