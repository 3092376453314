import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LangContext } from '../../contexts';
export const OldStandardDownload = (props) => {
  const {
    title,
    description,
    file,
    downloadLabel,
    additionalFile,
    additionalDownloadLabel,
    subject,
    date,
    versionButtonStandard,
    versionButtonRegistry,
  } = props;
  const [previewed, setPreviewed] = useState(false);
  const { isKorean } = useContext(LangContext) || { isKorean: false };
  const history = useHistory();
  const handleDownload = () => {
    window.open(file, '_blank');
    setPreviewed(true);
    const link = document.createElement('a');
    link.download = file.split('/');
    link.click();
  };

  const gotoRegistyPage = () => {
    history.push('/registry/terms');
  };
  const gotoStandardPage = () => {
    history.push('/program/standard');
  };
  return (
    <section className='p-6 my-4 border border-solid border-gray-300'>
      <article>
        <h3 className='text-base'>{title}</h3>
        <p className='mt-1 text-base text-gray-500 leading-6 break-keep'>{description}</p>
        <div className='flex gap-2 mt-6'>
          <a className='flex items-center' href={file} download={file} target='_blank' rel='noreferrer'>
            <span className='text-md text-gray-500 mr-1 font-bold'>{downloadLabel}</span>
            <FileDownloadOutlinedIcon htmlColor='#6B7280' />
          </a>
        </div>
        {additionalDownloadLabel && (
          <div className='flex gap-2 mt-3'>
            <a
              className='flex items-center'
              href={additionalFile}
              download={additionalFile}
              target='_blank'
              rel='noreferrer'
            >
              <span className='text-md text-gray-500 mr-1 font-bold'>{additionalDownloadLabel}</span>
              <FileDownloadOutlinedIcon htmlColor='#6B7280' />
            </a>
          </div>
        )}
      </article>
    </section>
  );
};
