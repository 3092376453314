import Button from '@mui/material/Button';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Handler from '../../Handler';
import API from '../../api';
import { ContentRegistry, PageHeader } from '../../components';
import { DetailDocumentOld } from '../../components/table/DetailDocumentOld';
import { LangContext, SnackbarContext } from '../../contexts';
import { useLabel } from '../../hooks';

export const ProjectRegistryDetailPage = (props) => {
  const category = 'project';
  const history = useHistory();
  const { t } = useTranslation();
  const { LABEL } = useLabel();
  const { slug } = useParams();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { isKorean } = useContext(LangContext);
  const [initData, setInitData] = useState(null);

  const getDetailData = useCallback(() => {
    API.Registry.GetRegistryDetail(category, slug)
      .then((res) => {
        setInitData(res.data);
      })
      .catch((err) => {
        history.go(-1);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      });
  }, [slug, history, updateSnackbar]);

  const projectDocData = useMemo(() => {
    if (initData?.documents?.length > 0) return Handler.genDocumentGroup(initData.documents);
    return [];
  }, [initData]);

  useEffect(() => {
    if (slug) {
      getDetailData();
    }
  }, [slug, getDetailData]);

  if (!initData) return <></>;

  return (
    <article className='basic-page'>
      <PageHeader
        title={isKorean ? initData?.title : initData?.titleEn}
        parentPath='/registry/project/list'
        parentText={t('PROJECT')}
        detail={LABEL['PROJECT_DETAIL']}
      />
      <section className='content__wrapper'>
        {/* 추후 반영 예정(24.02.27)
        <article className='w-full'>
          <div>
            <p className='mb-2 text-gray-600'>{isKorean ? initData?.introOne : initData?.introOneEn}</p>
            <p className='text-gray-600'>{isKorean ? initData?.introTwo : initData?.introTwoEn}</p>
          </div>
        </article> */}
        <article className='py-16 w-full'>
          <section className='text-left'>
            <h2 className='mb-4'>{LABEL['PROJECT_DETAIL']}</h2>
            <ContentRegistry category={category} data={initData} />
          </section>
          <section className='mt-20'>
            <h2 className='mb-4'>{t('DOCUMENTS')}</h2>
            {projectDocData && <DetailDocumentOld docData={projectDocData} type={category} />}
          </section>
        </article>
        <article>
          <Button color='primary' variant='outlined' onClick={() => history.push('/registry/project/list')}>
            {t('LIST')}
          </Button>
        </article>
      </section>
    </article>
  );
};
