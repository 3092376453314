import dayjs from 'dayjs';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Handler from '../../Handler';
import { LangContext } from '../../contexts';
import { useLabel } from '../../hooks';
import { GoogleMapWrapper } from '../basic';

export const ContentRegistry = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { isKorean } = useContext(LangContext);
  const { category, data } = props;
  const [registryDictArray, setRegistryDictArray] = useState([]);
  const { REGISTRY_DETAIL, TABLE, AREA_TYPE, STANDARD_TYPE, PROJECT_STATUS, COUNTRY } = useLabel();

  const genDictArray = useCallback(
    (category, data) => {
      let arr = [];
      if (category === 'methodology') {
        arr = [
          {
            label: REGISTRY_DETAIL['AREA'],
            value: `${AREA_TYPE[data?.area.toUpperCase()]} / ${AREA_TYPE[data?.subArea.toUpperCase()]}`,
          },
          {
            label: REGISTRY_DETAIL['STATUS_VERSION'],
            value: `${data?.versionNumber > 1 ? t('UPDATE') : t('NEW')} / ${data?.version}`,
          },
          {
            label: TABLE['START_END_DATE'],
            value: {
              startDate: data.startDate,
              expiredYear: data.expiredYear,
            },
          },
          {
            label: REGISTRY_DETAIL['ID'],
            value: data.id,
          },
          {
            label: REGISTRY_DETAIL['STANDARD'],
            value: STANDARD_TYPE[data?.standard],
          },
        ];
      } else if (category === 'project') {
        const location = Handler.jsonParser(data?.lastProjectDoc?.location);
        const country = Handler.findCountry(location?.addr1?.address_components);
        const countryAddress = location?.addr1?.formatted_address;
        const companyAddress = Handler.jsonParser(data?.user?.address)?.addr1?.formatted_address;
        arr = [
          {
            label: REGISTRY_DETAIL['AREA'],
            value: `${AREA_TYPE[data?.methodology?.area.toUpperCase()]} / ${
              AREA_TYPE[data?.methodology?.subArea.toUpperCase()]
            }`,
          },
          {
            label: TABLE['ESTIMATED_REDUCTION'],
            value: data.reductionExpect,
          },
          {
            label: TABLE['MONITORING_PHASE'],
            value: data.monitorDegree,
          },
          {
            label: TABLE['ELIGIBLE_PERIOD'],
            value: {
              startDate: data.startDate,
              endDate: data.endDate,
            },
          },
          {
            label: TABLE['REDUCTION_PERIOD'],
            value: {
              reductionStartDate: data.reductionStartDate,
              reductionEndDate: data.reductionEndDate,
            },
          },
          {
            label: TABLE['METHODOLOGY'],
            value: {
              methodology: data.methodology,
            },
          },
          {
            label: TABLE['PROJECT_STATUS'],
            value: PROJECT_STATUS[data.type],
          },
          {
            label: t('PROJECT_ID'),
            value: data.id,
          },
          {
            label: TABLE['PROJECT_COUNTRY'],
            value: {
              country: (COUNTRY[country.short_name] 
                ? `${COUNTRY[country.short_name]}(${country.short_name})`
                : `${country.long_name}(${country.short_name})`),
              countryAddress: countryAddress,
            },
          },
          {
            label: TABLE['PROJECT_COMPANY'],
            value: {
              companyName: data.user.companyName,
              companyNameEn: data.user.companyNameEn,
              companyAddress: companyAddress,
            },
          },
        ];
      }
      setRegistryDictArray(arr);
    },
    [t, TABLE, REGISTRY_DETAIL, AREA_TYPE, STANDARD_TYPE, PROJECT_STATUS, COUNTRY],
  );

  useEffect(() => {
    if (data) {
      genDictArray(category, data);
    }
  }, [category, data, genDictArray, isKorean]);

  const renderRow = () => {
    return registryDictArray.map((el, idx) => {
      const renderValueCell = (el) => {
        if (el.label === TABLE['ELIGIBLE_PERIOD'] || el.label === TABLE['START_END_DATE']) {
          const start = el?.value?.startDate;
          /* 방법론일 경우 종료일을 유효기간(년)으로 계산해서 도출 */
          const end =
            category === 'methodology'
              ? dayjs(el?.value?.startDate).add(el?.value?.expiredYear, 'y')
              : el?.value?.endDate;
          const period = Handler.geni18nDatePeriod(isKorean, start, end);

          /* 프로젝트일 경우 유효기간(년)을 종료일로 계산해서 도출 */
          const year =
            category === 'methodology'
              ? `${TABLE['ELIGIBLE_PERIOD']} (${el.value?.expiredYear}${t('YEAR')})`
              : `(${dayjs(el?.value?.endDate).diff(el?.value?.startDate, 'y') + 1}${t('YEAR')})`;
          return (
            <div className='flex'>
              {el?.value?.startDate && dayjs(el?.value?.startDate).isValid() && <span>{period}</span>}
              <span className='ml-3 text-sm text-gray-400'>{year}</span>
            </div>
          );
        }
        if (el.label === TABLE['REDUCTION_PERIOD']) {
          return (
            <div className='flex'>
              <span className='mr-1'>
                {Handler.geni18nDatePeriod(isKorean, el.value?.reductionStartDate, el.value?.reductionEndDate)}
              </span>
            </div>
          );
        }
        if (el.label === TABLE['PROJECT_COUNTRY']) {
          return (
            <>
              <p className='mb-2'>{el.value.country}</p>
              <GoogleMapWrapper
                address={el.value.countryAddress}
                meta={{
                  readOnly: true,
                }}
                onChange={() => null}
              ></GoogleMapWrapper>
            </>
          );
        }
        if (el.label === TABLE['PROJECT_COMPANY']) {
          return (
            <>
              <span className='font-bold mr-2'>{isKorean ? el.value?.companyName : el.value?.companyNameEn}</span>
            </>
          );
        }
        if (el.label === TABLE['ESTIMATED_REDUCTION']) {
          return (
            <div className='flex'>
              <span className='mr-1'>{Handler.numberWithCommas(el.value)}</span>
              <span className='text-sm text-gray-500'>tCO₂eq</span>
            </div>
          );
        }
        if (el.label === TABLE['METHODOLOGY']) {
          return (
            <>
              <div className='flex gap-2 items-center'>
                <div>
                  <p className='text-sm text-gray-500'>{el.value?.methodology?.id}</p>
                  <p>{isKorean ? el.value?.methodology?.title : el.value?.methodology?.titleEn}</p>
                </div>
                <div
                  className='cursor-pointer py-1 px-3 rounded-md bg-slate-50 text-sm font-bold text-gray-600'
                  onClick={() => history.push(`/registry/methodology/detail/${el.value?.methodology?.seq}`)}
                >
                  {t('DETAIL')}
                </div>
              </div>
            </>
          );
        }
        return (
          <>
            <div className='flex items-center'>
              <span>{`${el.value ? el.value : '-'}`}</span>
            </div>
          </>
        );
      };

      return (
        <section
          key={'registry-row' + idx}
          className={`flex border-t border-solid border-gray-200 ${
            idx === registryDictArray.length - 1 ? 'border-b' : ''
          }`}
        >
          <div className='bg-slate-50 p-3 w-52'>
            <span className='font-bold text-gray-500'>{el.label}</span>
          </div>
          <div className='p-3 w-3/4 flex flex-col justify-center'>{renderValueCell(el)}</div>
        </section>
      );
    });
  };

  return <article className='w-full'>{registryDictArray?.length > 0 && renderRow(registryDictArray)}</article>;
};
