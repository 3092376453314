import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Handler from '../../Handler';
import API from '../../api';
import { AddressLocation, Button, FileUploader, TextInput } from '../../components';
import { SnackbarContext, UserContext } from '../../contexts';
import { useForm } from '../../hooks';

export const MypageModifyPage = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { userInfo, refreshUserInfo } = useContext(UserContext);
  const [formData, formMeta, formChange, setFormData] = useForm(
    {
      email: '',
      name: '',
      phone: '',
      companyName: '',
      ceoName: '',
      profilePath: '',
      businessNumber: '',
      businessFile: '',
      address: {
        daum: null,
        detail: '',
      },
      site: '',
      fax: '',
    },
    {
      email: {
        key: 'email',
        label: t('EMAIL'),
        style: {},
        className: 'point',
        disabled: true,
      },
      name: {
        key: 'name',
        label: t('RESPONSIBILITY'),
        style: {},
        className: 'point',
        required: true,
      },
      phone: {
        key: 'phone',
        label: t('MOBILE'),
        style: {},
        className: 'point',
        required: true,
      },
      companyName: {
        key: 'companyName',
        label: t('COMPANY_NAME'),
        style: {},
        className: 'point',
        disabled: true,
      },
      ceoName: {
        key: 'ceoName',
        label: t('CEO_NAME'),
        style: {},
        className: 'point',
        disabled: true,
      },
      businessNumber: {
        key: 'businessNumber',
        label: t('BIZ_NUMBER'),
        style: {},
        className: 'point',
        disabled: true,
      },
      profilePath: {
        type: 'image',
        key: 'profilePath',
        label: t('REPRESENTATIVE_IMAGE'),
        path: 'singup/profile_image',
        className: 'point',
        helperText: t('PROFILE_IMAGE_GUIDE'),
        disabled: false,
      },
      businessFile: {
        type: 'file',
        key: 'businessFile',
        label: t('BIZ_CERT'),
        path: 'singup/business_file',
        className: 'point',
        disabled: true,
      },
      address: {
        required: true,
        className: 'point',
        key: 'address',
        label: t('HEADQ_ADDRESS'),
        type: 'mypage',
      },
      site: {
        key: 'site',
        label: t('HOMEPAGE'),
        style: {},
        className: 'point',
        required: false,
      },
      fax: {
        key: 'fax',
        label: t('FAX'),
        style: {},
        className: 'point',
        required: false,
      },
    },
  );

  useEffect(() => {
    if (userInfo) {
      console.log('userInfo', userInfo);
      setFormData({
        ...formData,
        email: userInfo.email,
        name: userInfo.name,
        phone: userInfo.phone,
        ceoName: userInfo.ceoName,
        companyName: userInfo.companyName,
        businessNumber: userInfo.businessNumber,
        profilePath: userInfo.profilePath,
        businessFile: userInfo.businessFile,
        address: userInfo.address,
        site: userInfo.site,
        fax: userInfo.fax,
      });
    }
  }, [userInfo]);

  console.log('formData', formData);

  const requsetModify = () => {
    let body = Handler.formToBody(formData, formMeta);
    console.log('requsetModify body', body);

    if (body) {
      // let db_address = Handler.addressToDbData(body.address);
      
      let db_body = {
        name: body.name,
        phone: body.phone,
        address: body.address,
        site: body.site,
        fax: body.fax,
        profilePath: body.profilePath,
      };

      let default_image = Handler.GET_DEFAULT_IMAGE('company');
      console.log('default_image', default_image);

      if (!db_body.profilePath) {
        db_body.profilePath = default_image;
      }
      console.log('db_body', db_body);

      API.User.Put(db_body).then(
        (res) => {
          let result = res.data;
          console.log('User Put result', result);
          refreshUserInfo();
          updateSnackbar({
            type: 'success',
            open: true,
            message: '회원정보 수정 성공',
          });
        },
        (err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '필수정보를 확인해주세요.',
      });
    }
  };

  return (
    <article className='basic-page'>
      <article className='content__wrapper'>
        <section className='headding-text__container'>
          <header>
            <h1>{t('MODIFY_MYINFO')}</h1>
          </header>
          <div className='right__box'>{/* <Breadcrumbs type={'small'}></Breadcrumbs> */}</div>
        </section>

        <section className='user-form__container'>
          <header>
            <h2>{t('LOGIN_INFO')}</h2>\
            <em>
              <div className='ico required'></div> {t('REQUIRED')}
            </em>
          </header>
          <ul className='form__list'>
            <TextInput value={formData.email} onChange={formChange} meta={formMeta.email}></TextInput>
            <article className='form-custom point'>
              <header>
                <span>{t('PASSWORD')}</span>
              </header>
              <div className='button__box'>
                <Button
                  style={{ width: '160px' }}
                  type='secondary-square'
                  onClick={() => {
                    history.push('/user/reset_pw');
                  }}
                  label={t('CHANGE_PASSWORD')}
                ></Button>
              </div>
            </article>

            <TextInput value={formData.name} onChange={formChange} meta={formMeta.name}></TextInput>
            <TextInput
              value={formData.phone}
              onChange={(value, key) => {
                const reg = new RegExp('^[0-9]{0,11}$');
                const isValidtion = reg.test(value);
                if (isValidtion) {
                  formChange(value, key);
                }
              }}
              meta={formMeta.phone}
            ></TextInput>
          </ul>
        </section>
        <section className='user-form__container'>
          <header>
            <h2>{t('COMPANY_PROFILE')}</h2>
            <em>
              <div className='ico required'></div> {t('REQUIRED')}
            </em>
          </header>
          <ul className='form__list'>
            <TextInput value={formData.companyName} onChange={formChange} meta={formMeta.companyName}></TextInput>
            <TextInput value={formData.ceoName} onChange={formChange} meta={formMeta.ceoName}></TextInput>
            <TextInput value={formData.businessNumber} onChange={formChange} meta={formMeta.businessNumber}></TextInput>
            <FileUploader value={formData.profilePath} onChange={formChange} meta={formMeta.profilePath}></FileUploader>
            <FileUploader
              value={formData.businessFile}
              onChange={formChange}
              meta={formMeta.businessFile}
            ></FileUploader>
            {/* <Address value={formData.address} onChange={formChange} meta={formMeta.address}></Address> */}
            <AddressLocation
              value={formData.address}
              hideMap={true}
              onChange={formChange}
              meta={formMeta.address}
            ></AddressLocation>
            <TextInput value={formData.site} onChange={formChange} meta={formMeta.site}></TextInput>
            <TextInput
              value={formData.fax}
              onChange={(value, key) => {
                const reg = new RegExp('^[0-9]{0,11}$');
                const isValidtion = reg.test(value);
                if (isValidtion) {
                  formChange(value, key);
                }
              }}
              meta={formMeta.fax}
            ></TextInput>
          </ul>
        </section>
        <section className='button__container' style={{ maxWidth: 560 }}>
          <Button
            style={{ width: '180px', marginRight: 24 }}
            onClick={() => {
              history.push('/mypage/signout');
            }}
            label={t('RESIGN_MEMBERSHIP')}
            type='info'
          ></Button>
          <Button
            style={{ width: '180px' }}
            onClick={() => {
              requsetModify();
            }}
            label={t('MODIFY')}
          ></Button>
        </section>
      </article>
    </article>
  );
};
