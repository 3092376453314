import React from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';

export const RadioBox = (props) => {
  const { value, onChange, meta, menuStyle } = props;
  const { t } = useTranslation();

  const renderRequired = () => {
    if (meta && meta.required) {
      if (meta.className === 'point') {
        return <div className='ico required'></div>;
      } else {
        return <span className='text-red-600'>*</span>;
      }
    }
  };

  return (
    <article
      className={Handler.BuildArticleClassname('form-radio-box', meta)}
      style={meta && meta.style ? { ...meta.style } : {}}
    >
      {meta.label && (
        <header>
          <span className='flex'>
            {t(meta.label)}
            {renderRequired()}
          </span>
          {meta.subLabel && <span className='sub-label'>{t(meta.subLabel)}</span>}
        </header>
      )}
      <ul className='radio-options' style={menuStyle}>
        {meta.menus &&
          meta.menus.map((item, index) => {
            return (
              <li
                key={`${item.text}${index}`}
                className='radio-option'
                onClick={() => {
                  if (!meta.disabled && !meta.readOnly) {
                    onChange(item.value, meta.key);
                  }
                }}
              >
                <div className='ico__box !mr-[8px]'>
                  <div
                    className={
                      value !== item.value
                        ? 'ico check'
                        : meta.disabled || meta.readOnly
                        ? 'ico check disabled'
                        : 'ico check on'
                    }
                  ></div>
                </div>
                <div className='text__box'>{t(item.label)}</div>
              </li>
            );
          })}
      </ul>
    </article>
  );
};
