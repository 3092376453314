import styled from '@emotion/styled';
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';
import Modal from '@mui/material/Modal';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalPdfView } from '..';
import { LangContext } from '../../contexts';
import Handler from '../../Handler';
import { useLabel } from '../../hooks';
import ExpandMore from '../../styles/icon/expand-icon.svg';

const TableRow = styled.div`
  display: grid;
  grid-template-columns: 13rem 1fr 120px 120px 120px;
  padding: 10px;
  border: solid 1px;
`;

const TableSubRow = styled.div`
  display: grid;
  grid-template-columns: 360px 120px 120px 120px;
`;

export const OtherDocument = (props) => {
  const { docData, type } = props;
  const ref = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [document, setDocument] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation();
  const { isKorean } = useContext(LangContext);
  const { TABLE_HEADER, DOCUMENT_METHODOLOGY, DOCUMENT_PROJECT, DOCUMENT_STANDARD, ERROR } = useLabel();
  const [showMore, setShowMore] = useState(false);
  const handleDocumentModal = useCallback((doc) => {
    setDocument(doc);
    setShowModal(true);
  }, []);

  const documentType = useMemo(() => {
    if (type === 'methodology') return DOCUMENT_METHODOLOGY;
    if (type === 'project') return DOCUMENT_PROJECT;
    if (type === 'standard') return DOCUMENT_STANDARD;
    return DOCUMENT_STANDARD;
  }, [type, DOCUMENT_METHODOLOGY, DOCUMENT_PROJECT, DOCUMENT_STANDARD]);

  const docTypeKeys = useMemo(() => {
    if (type === 'methodology') return ['BASIC', 'RESULT', 'CONSULTATION'];
    if (type === 'project') return ['BASIC', 'MONITORING', 'VERIFY', 'RESULT', 'CONSULTATION'];
    if (type === 'standard') return ['HISTORY', 'CONSULTATION'];
    return ['HISTORY', 'CONSULTATION'];
  }, [type]);

  const handleAccordionChange = () => {
    setIsExpanded(!isExpanded);
  };
  const noDataRow = (type, idx) => (
    <TableSubRow key={`doc-no-data-sub-row-${idx}`} className={`w-full items-center`}>
      <div className='text-gray-400 leading-6 text-center'>{ERROR['NO_DOCUMENTS']}</div>
    </TableSubRow>
  );

  return (
    <Accordion
      expanded={isExpanded}
      onChange={handleAccordionChange}
      style={{
        'borderRadius': 0,
        'boxShadow': 'none',
        'borderBottom': '1px solid #D7DAE2',
        'border': '1px solid #D7DAE2',
        '&.Mui-expanded': {
          borderColor: '#333',
          border: '1px solid #D7DAE2',
          borderRadius: 0,
        },
      }}
    >
      <AccordionSummary className='flex' style={{ height: 84 }}>
        <div className='flex flex-col m-3'>
          <div className='flex justify-start '>
            <p className='text-color[#222222] mr-2'> {isKorean ? '그 외 문서' : 'All Documents'}</p>
            <div className='text-color[#777777]'>|</div>
            <p className='ml-2 text-gray-400'> {isKorean ? '기타' : 'Others'} </p>
          </div>
        </div>

        <img
          src={ExpandMore}
          alt='Right Arrow Icon'
          style={{ display: 'flex', marginRight: '15px' }}
          className='ml-auto'
        />
      </AccordionSummary>
      {isExpanded && (
        <div
          className='mb-5 ml-7 w-[838px] h-px bg-gray-300 border border-[#D7DAE2]'
          style={{
            boxShadow: '0',
          }}
        ></div>
      )}
      <div>
        {docTypeKeys.map((el, idx) => {
          return (
            <AccordionDetails
              key={`doc-data-item-${idx}`}
              aria-controls={`panel${idx}-content`}
              className='w-full border-s black'
            >
              <div
                className='flex justify-between flex-col gap-2'
                style={{ justifyContent: 'center', alignContent: 'center', paddingRight: '5px' }}
              >
                {docData[el]?.length > 0 ? (
                  docData[el].map((doc, idx) => {
                    const file = isKorean ? doc.attachedFile : doc.attachedFileEn;
                    const title = isKorean ? doc.title : doc.titleEn;
                    const date = Handler.geni18nDate(isKorean, doc.createdAt * 1000);
                    return (
                      <TableSubRow key={`doc-data-sub-row-${idx}`} className='w-[830px] justify-between'>
                        {/* <div className='flex text-center ml-4 text-gray-500'>{title}</div>
                      <div className='flex text-center leading-6'>{date}</div>
                      <div className='flex ml-12'>
                        <div>
                          <Button
                            variant='outlined'
                            style={{
                              height: 40,
                              width: 120,
                              borderRadius: 0,
                              color: 'black',
                              borderColor: 'black',
                              padding: 0,
                            }}
                            className='mt-2'
                            onClick={() => handleDocumentModal(file)}
                          >
                            미리보기
                          </Button>
                        </div>
                        <div className='ml-2'>
                          <a href={file} download={file} target='_blank' rel='noreferrer'>
                            <Button
                              variant='contained'
                              style={{
                                height: 40,
                                width: 120,
                                borderRadius: 0,
                                background: 'black',
                                borderColor: 'black',
                              }}
                            >
                              다운로드
                            </Button>
                          </a>
                        </div>
                      </div> */}
                      </TableSubRow>
                    );
                  })
                ) : (
                  <div className='text-center py-0 mb-[80px] align-items text-gray-500'>
                    {' '}
                    {isKorean ? '등록된 문서가 없습니다.' : 'No documents'}
                  </div>
                )}
              </div>
            </AccordionDetails>
          );
        })}
      </div>
      <Modal ref={ref} open={showModal} onClose={() => setShowModal(false)}>
        <Box>
          <ModalPdfView url={document} onClose={() => setShowModal(false)} />
        </Box>
      </Modal>
    </Accordion>
  );
};
