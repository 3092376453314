import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import Utils from '../../api/Utils';
import { ModalReductionSelect } from '../../components';
import { ROUTE_PATH } from '../../constants/routes';
import { ContentContext, LangContext, SnackbarContext, UserContext } from '../../contexts';
import LogoPurple from '../../styles/icon/logo_pople.svg';
import LogoWhite from '../../styles/icon/logo_pople_white.svg';
import { MainHeader, PcNavigation, PcSubNavigation } from './HeaderNavStyle';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 660,
  bgcolor: 'background.paper',
  border: '1px solid #bcbcbc',
  p: 0,
  borderRadius: 2,
};

export const HeaderNav = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const NAVIGATION = t('ROUTE_PATH', { returnObjects: true });
  const { updateSnackbar } = useContext(SnackbarContext);
  const { isKorean } = useContext(LangContext);
  const { userInfo, clearToken } = useContext(UserContext);
  const { deviceWidth } = useContext(ContentContext);
  const [navPosition, setNavPosition] = useState(window.scrollY);
  const [hoverItem, setHoverItem] = useState(null);
  const [userMenu, setUserMenu] = useState(null);

  let CLEAR_FUNC = null;
  const LEFT_LIST = ROUTE_PATH;
  const [open, setOpen] = useState(false);

  const all_show_menus = ['REGISTRY', 'OPEN_COMMENT', 'PROGRAM_DETAILS'];

  useEffect(() => {
    if (userInfo) {
      setUserMenu([
        {
          type: 'mypage',
          text: userInfo.name + ' 님',
          activePath: 'mypage',
          child: [
            {
              text: 'LIST',
              path: '/mypage/credit_list',
            },
            {
              text: 'ONETOONE_HISTORY',
              path: '/mypage/inquiry_list',
            },
            {
              text: 'ACCOUNT_INFO',
              path: '/mypage/modify',
            },
            {
              type: 'action',
              text: 'LOGOUT',
              action: 'LOGOUT',
            },
          ],
        },
      ]);
    } else {
      setUserMenu([
        {
          type: 'sign',
          list: [
            { text: 'LOGIN', path: '/user/login' },
            { text: 'SIGN_UP', path: '/user/signup_guide' },
          ],
        },
      ]);
    }
  }, [userInfo]);

  useEffect(() => {
    window.addEventListener('scroll', updateNavPosition);
    return () => window.removeEventListener('scroll', updateNavPosition);
  }, []);

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    Utils.SetStorage('language', lang);
  };

  const updateNavPosition = () => {
    setNavPosition(window.scrollY);
  };

  const onMouseEnterHandler = (item) => {
    if (CLEAR_FUNC) {
      clearTimeout(CLEAR_FUNC);
    }
    setHoverItem(item);
  };

  const onMouseLeaveHandler = () => {
    CLEAR_FUNC = setTimeout(() => {
      setHoverItem(null);
    }, [500]);
  };

  const gotoPath = (path, type = null) => {
    if (type === 'outer') {
      window.open(path);
    } else {
      console.log('path', path);
      history.push(path);
    }
  };

  const listAction = (type) => {
    switch (type) {
      case 'NEW_PROJECT': {
        history.push('/project/writing/');
        break;
      }
      case 'REQUEST_REDUCTION': {
        if (userInfo) setOpen(true);
        else history.push('/user/login');
        break;
      }
      case 'LOGOUT': {
        console.log('LOGOUT');
        clearToken();
        break;
      }
      default: {
        console.log('action none');
      }
    }
  };

  const check_show_menu = (text) => {
    return (!userInfo && all_show_menus.includes(text)) || !!userInfo;
  };

  const renderSubMenu = (arr, menu_type) => {
    let subMenus = [];
    arr.forEach((list) => {
      if (menu_type === 'MAIN') {
        if (check_show_menu(list.text)) {
          if (list.child) subMenus.push(list.child);
        }
      } else {
        if (list.child) subMenus.push(list.child);
      }
    });

    const navList = [...subMenus].filter((li) => li.length > 0);

    return (
      <>
        {navList &&
          navList.map((e, index) => (
            <ul key={'navlist' + index}>
              {e.map((ls, idx) => (
                <li key={index + 'sub-navlist' + idx} onClick={() => gotoPath(ls.path, ls.type)}>
                  {(ls.type && ls.type === 'action' && (
                    <span onClick={() => listAction(ls.action)}>
                      {NAVIGATION[ls.text]}
                      {ls.action === 'LOGOUT' && <span className='ico logout'></span>}
                    </span>
                  )) || <span>{NAVIGATION[ls.text] || ls.text}</span>}
                </li>
              ))}
            </ul>
          ))}
      </>
    );
  };

  const renderNavClassname = (type, index) => {
    let return_classname = '';
    let pathArray = history.location.pathname.split('/');
    let parentPath = pathArray[1];

    if (hoverItem) {
      if (type === hoverItem.type && hoverItem.index === index) {
        return_classname += ' on';
      }
    }

    if (type === 'left') {
      if (parentPath === LEFT_LIST[index].activePath) {
        return_classname += ' active';
      }
    } else {
      if (parentPath === userMenu[index].activePath) {
        return_classname += ' active';
      }
    }
    return return_classname;
  };

  const isTransparent = () => {
    return navPosition === 0 && !hoverItem && location.pathname === '/';
  };

  return (
    <MainHeader className={isTransparent() ? 'is__transparent' : ''}>
      <article className='content__wrapper'>
        <PcNavigation login={userInfo}>
          <section className='flex w-full justify-between mb-6'>
            <section
              className='mr-20 cursor-pointer'
              onClick={() => {
                history.push('/');
              }}
            >
              <img src={isTransparent() ? LogoWhite : LogoPurple} width='64' alt='logo' />
            </section>
            <section className='flex items-center'>
              <div className={isTransparent() ? 'flex gap-3 text-white mr-4' : 'flex gap-3 mr-4'}>
                <div
                  className='cursor-pointer hover:underline'
                  onClick={() => gotoPath('https://www.pople.kr/', 'outer')}
                >
                  <span className='text-inherit font-bold text-sm'>{t('MARKETPLACE')}</span>
                </div>
                <div className='cursor-pointer hover:underline' onClick={() => gotoPath('/cs/board')}>
                  <span className='text-inherit text-sm'>{t('BOARD')}</span>
                </div>
                <div className='cursor-pointer hover:underline' onClick={() => gotoPath('/cs/notice')}>
                  <span className='text-inherit text-sm'>{t('NOTICE')}</span>
                </div>

                <div className='cursor-pointer hover:underline' onClick={() => gotoPath('/cs/faq')}>
                  <span className='text-inherit text-sm'>FAQ</span>
                </div>

                <div className='cursor-pointer hover:underline' onClick={() => gotoPath('/cs/contact_us')}>
                  <span className='text-inherit text-sm'>Contact Us</span>
                </div>
              </div>
              <div className='items-end'>
                <button
                  className={
                    isKorean
                      ? 'border border-solid border-white bg-slate-700 text-white px-2 p-1'
                      : 'border border-solid border-white bg-slate-100 text-slate-500 px-2 p-1'
                  }
                  onClick={() => handleChangeLanguage('ko')}
                >
                  KO
                </button>
                <button
                  className={
                    isKorean
                      ? 'border border-solid border-white bg-slate-100 text-slate-500 px-2 p-1'
                      : 'border border-solid border-white bg-slate-700 text-white px-2 p-1'
                  }
                  onClick={() => handleChangeLanguage('en')}
                >
                  EN
                </button>
              </div>
            </section>
          </section>
          <article
            className={userInfo ? 'pc-nav__main pc-nav__wrapper loggedin pb-2' : 'pc-nav__main pc-nav__wrapper pb-2'}
          >
            <section className='left__side'>
              <section className='nav__box'>
                <ul>
                  {LEFT_LIST.map((item, index) => {
                    if (check_show_menu(item.text)) {
                      return (
                        <li
                          key={'left-list-index' + index}
                          onClick={() => {
                            if (item.type === 'link') window.open(item.path);
                          }}
                          onMouseEnter={(e) => {
                            onMouseEnterHandler({
                              index: index,
                              type: 'left',
                            });
                          }}
                          onMouseLeave={(e) => {
                            onMouseLeaveHandler();
                          }}
                          className={renderNavClassname('left', index)}
                        >
                          <span>{NAVIGATION[item.text]}</span>
                        </li>
                      );
                    }
                  })}
                </ul>
              </section>
            </section>
            <section className='right__side'>
              <ul className={userInfo ? 'loggedin' : ''}>
                {userMenu &&
                  userMenu.map((item, index) => {
                    const { type, text, path, action } = item;
                    switch (type) {
                      case 'sub':
                      case 'mypage': {
                        return (
                          <li
                            key={'sub-' + index}
                            onMouseEnter={(e) => {
                              onMouseEnterHandler({
                                index: index,
                                type: 'right',
                              });
                            }}
                            onMouseLeave={(e) => {
                              onMouseLeaveHandler();
                            }}
                            className={renderNavClassname('right', index)}
                          >
                            <span>{t(text)}</span>
                          </li>
                        );
                      }
                      case 'link': {
                        return (
                          <li
                            key={'link-' + index}
                            onClick={() => {
                              window.open(item.path);
                            }}
                          >
                            <span className='registry'>{t(text)}</span>
                            {<span className={isTransparent() ? 'ico outer' : 'ico outer_black'}></span>}
                          </li>
                        );
                      }
                      case 'sign': {
                        return (
                          <li className='sign' key={'link-' + index}>
                            <span
                              onClick={() => {
                                history.push(item.list[0].path);
                              }}
                            >
                              {t(item.list[0].text)}
                            </span>
                            <span className='divider'></span>
                            <span
                              onClick={() => {
                                history.push(item.list[1].path);
                              }}
                            >
                              {t(item.list[1].text)}
                            </span>
                          </li>
                        );
                      }
                      case 'action': {
                        return (
                          <li
                            key={'action-' + index}
                            onClick={() => {
                              action();
                            }}
                          >
                            <span>{t(text)}</span>
                          </li>
                        );
                      }
                      default: {
                        return (
                          <li key={'default-' + index}>
                            <span>{t(text)}</span>
                          </li>
                        );
                      }
                    }
                  })}
              </ul>
            </section>
          </article>
        </PcNavigation>
        <PcSubNavigation
          className={hoverItem ? 'open' : ''}
          onMouseEnter={(e) => {
            onMouseEnterHandler(hoverItem);
          }}
          onMouseLeave={(e) => {
            onMouseLeaveHandler();
          }}
        >
          {hoverItem && (
            <article className={userInfo ? 'pc-nav__wrapper loggedin' : 'pc-nav__wrapper'}>
              <section className='left__side'>
                <section className='sub-nav__list sub-nav__box'>{renderSubMenu(LEFT_LIST, 'MAIN')}</section>
              </section>
              <section
                className={userInfo ? 'sub-nav__list sub-right__side loggedin' : 'sub-nav__list sub-right__side'}
              >
                {renderSubMenu(userMenu, 'LOGIN')}
              </section>
            </article>
          )}
        </PcSubNavigation>
      </article>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Box sx={style}>
          <ModalReductionSelect
            onClose={() => {
              setOpen(false);
            }}
          ></ModalReductionSelect>
        </Box>
      </Modal>
    </MainHeader>
  );
};
