import _ from 'lodash';
import { default as React, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckBox } from '../../components';
import { LangContext } from '../../contexts';
import { useLabel } from '../../hooks';

export const SignUpTerms = (props) => {
  const { onChange } = props;
  const { t } = useTranslation();
  const [termsChecks, setTermsChecks] = useState([false, false, false, false]);
  const [termsAllCheck, setTermsAllCheck] = useState(false);
  const { MSG, ERROR, SIGN_UP_TERM } = useLabel();
  const { isKorean } = useContext(LangContext) || { isKorean: false };
  const onChangeTermsCheck = (new_value, index) => {
    let new_termsChecks = _.clone(termsChecks);
    new_termsChecks[index] = new_value;
    setTermsChecks(new_termsChecks);
  };

  useEffect(() => {
    if (termsChecks) {
      let is_all_true = termsChecks.every((e) => e);
      setTermsAllCheck(is_all_true);
    }
    onChange(termsChecks);
  }, [termsChecks, onChange]);

  return (
    <>
      <ul className='terms__list'>
        <header>
          <h2>{t('JOIN_SYSTEM')}</h2>
        </header>
        <li>
          <header>
            <CheckBox
              value={termsChecks[0]}
              onChange={(new_value) => {
                onChangeTermsCheck(new_value, 0);
              }}
              meta={{
                required: true,
                label: MSG['AGREE_PRIVACY_TERM'],
                subLabel: t('REQUIRED'),
                className: 'terms',
              }}
            ></CheckBox>
          </header>
          <div className='text__viewer'>
            <div className='online-terms__box'>
              {isKorean ? (
                <>
                  <p>'개인정보 수집 및 이용동의'</p>
                  <p>
                    그리너리(이하 '회사')는 회원에게 서비스를 제공하기 위해 회원가입 단계에서 아래와 같이 개인정보를
                    수집 이용합니다.
                  </p>
                  <p>수집ㆍ이용 목적</p>
                  <p>- 서비스 제공, 이용자 식별 및 본인여부 확인</p>
                  <p>- 광고성 정보 수신</p>
                  <p>수집 항목</p>
                  <p>
                    - 회사명, 사업자등록번호, 아이디, 비밀번호, 이름, 생년월일, 성별, 이메일주소, 휴대전화번호,
                    계좌번호, 주소
                  </p>
                  <p>보유ㆍ이용기간</p>
                  <p>- 회원탈퇴 또는 수집ㆍ이용 목적 달성시까지</p>
                  <p>
                    ※ 귀하는 개인정보 수집ㆍ이용에 동의하지 않을 수 있으나, 본 개인정보는 회사가 서비스 제공을 위해
                    필요한 최소한의 개인정보이므로 동의를 거부할 경우 서비스 이용이 불가능합니다
                  </p>
                </>
              ) : (
                <>
                  <p>'Consent to Collect and Use Personal Information'</p>
                  <p>
                    Greenery (hereinafter referred to as 'the Company') collects and uses personal information as
                    outlined below during the membership registration process in order to provide services to members.
                  </p>
                  <p>Purpose of Collection and Use</p>
                  <p>- To provide services, identify users, and verify their identity</p>
                  <p>- To receive advertising information</p>
                  <p>Items Collected</p>
                  <p>
                    - Company name, business registration number, ID, password, name, date of birth, gender, email
                    address, mobile phone number, account number, address
                  </p>
                  <p>Retention and Use Period</p>
                  <p>- Until membership withdrawal or the purpose of collection and use is achieved</p>
                  <p>
                    ※ You may refuse to consent to the collection and use of personal information; however, this
                    personal information is the minimum required for the company to provide services. Therefore, if you
                    refuse consent, you will not be able to use the services.
                  </p>
                </>
              )}
            </div>
          </div>
        </li>
        <li>
          <header>
            <CheckBox
              value={termsChecks[1]}
              onChange={(new_value) => {
                onChangeTermsCheck(new_value, 1);
              }}
              meta={{
                required: true,
                label: MSG['AGREE_CUSTOMER_TERM'],
                subLabel: t('REQUIRED'),
                className: 'terms',
              }}
            ></CheckBox>
          </header>
          <div className='text__viewer'>
            <div className='online-terms__box'>
              {isKorean ? (
                <>
                  <p>
                    <strong>이용약관</strong>
                  </p>
                  <p>
                    <strong>제 1장 총칙</strong>
                  </p>
                  <p>
                    <strong>제 1조 (목적)</strong>
                  </p>
                  <p>
                    이 약관은 (주)그리너리(이하 &ldquo;회사&rdquo;)가 운영하는 웹 사이트 (https://www.pople.kr, 이하
                    &ldquo;사이트&rdquo;)에서 제공하는 탄소 크레딧 판매 및 상쇄 서비스 및 기타 정보서비스(이하
                    &ldquo;서비스&rdquo;)와 관련하여 회사와 회원 간의 권리, 의무, 책임 사항 및 회원의 서비스 이용절차에
                    관한 사항을 규정함을 목적으로 합니다.
                  </p>
                  <p>
                    <strong>제 2조 (용어의 정의)</strong>
                  </p>
                  <p>
                    1.&ldquo;사이트&rdquo;라 함은 회사가 서비스를 회원에게 제공하기 위하여 컴퓨터 등 정보통신설비를
                    이용하여 탄소 크레딧 등을 거래할 수 있도록 설정한 가상의 영업장 또는 서비스 공간을 말하며, 회원
                    계정(ID 및 비밀번호)을 이용하여 서비스를 제공받을 수 있는 아래의 사이트를 말합니다. 아울러 사이트를
                    운영하는 사업자의 의미로도 사용합니다. (*그리너리: www.pople.kr)
                  </p>
                  <p>
                    2.&ldquo;회원&rdquo;이라 함은 본 약관에 따라 회사와의 이용계약을 체결하고 회사가 운영하는 사이트에서
                    서비스를 이용하는 자를 말합니다.
                  </p>
                  <p>
                    3.&ldquo;아이디(ID)&rdquo;라 함은 회원의 식별과 서비스 이용을 위하여 회원이 정하고 회사가 승인하는
                    문자와 숫자의 조합을 의미합니다.
                  </p>
                  <p>
                    4.&ldquo;비밀번호(Password)&rdquo;라 함은 회원이 부여 받은 아이디와 일치되는 회원임을 확인하고
                    비밀보호를 위해 회원 자신이 정한 문자 또는 숫자의 조합을 의미합니다.
                  </p>
                  <p>
                    5."사업개발자&rdquo;이라 함은 탄소감축(흡수)사업을 개발, 운영하는 법인으로 탄소 크레딧을 생산하고
                    판매하는 회사를 의미합니다.
                  </p>
                  <p>
                    6."탄소 크레딧&rdquo;이라 함은, 사업 개발자가 탄소감축(흡수) 사업의 결과물로 발생한 온실가스 배출량
                    감축분을 제3자 검증을 거쳐 상쇄(Offset) 크레딧으로 인정받아 거래가능한 단위로 발행된 재산권을
                    의미합니다. 탄소 감축(흡수)량 1톤은 1크레딧과 대등한 가치를 가집니다.
                  </p>
                  <p>
                    7.&ldquo;상쇄&rdquo;라 함은 기업이 사업 중에 배출한 온실가스를 상쇄 크레딧을 구매하여 배출량을
                    보상하는 것을 말합니다.
                  </p>
                  <p>
                    8.&ldquo;해지&rdquo;라 함은 회사 또는 회원이 이용계약의 효력을 장래를 향해 소멸시키는 것을 말합니다.
                  </p>
                  <p>
                    9.이 약관에서 사용하는 용어 중 제2조에서 정하지 아니한 것은, 관계 법령 및 서비스별 안내에서 정하는
                    바에 따르며, 그 외에는 일반 거래관행에 따릅니다.
                  </p>
                  <p>
                    <strong>제 3조 (약관의 게시와 개정)</strong>
                  </p>
                  <p>
                    1.회사는 이 약관의 내용을 회원이 쉽게 알 수 있도록 서비스 초기 화면에 게시합니다. 다만, 약관의
                    내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.
                  </p>
                  <p>
                    2.회사는 약관의 규제에 관한 법률, 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하
                    &ldquo;정보통신망법&rdquo;) 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
                  </p>
                  <p>
                    3.회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 제1항의 방식에 따라
                    그 개정약관의 적용일자 7일 전부터 적용일자 전일까지 공지함으로써 그 효력이 발생합니다. 다만,
                    회원에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다.
                    이 경우 회사는 개정 전 내용과 개정 후 내용을 명확하게 비교하여 회원이 알기 쉽도록 표시합니다.
                  </p>
                  <p>
                    4.회사가 전항에 따라 개정약관을 공지 또는 통지하면서 회원에게 7일 기간 내에 의사표시를 하지 않으면
                    의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 회원이 명시적으로 거부의
                    의사표시를 하지 아니한 경우 회원이 개정약관에 동의한 것으로 봅니다.
                  </p>
                  <p>
                    5.회원이 개정약관의 적용에 동의하지 않는 경우 회사는 개정 약관의 내용을 적용할 수 없으며, 이 경우
                    회원은 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는
                    회사는 이용계약을 해지할 수 있습니다. 이 경우 회사는 제17조 (회원에 대한 통지)에서 정한 방법으로
                    회원에게 통지합니다.
                  </p>
                  <p>
                    6.본 약관은 회사와 회원 간에 성립되는 서비스이용계약의 기본 약정입니다. 회사는 필요한 경우 특정
                    서비스에 관하여 적용될 사항(이하 &ldquo;개별약관&rdquo;)을 정하여 미리 공지할 수 있습니다. 회원이
                    이러한 개별약관에 동의하고 특정 서비스를 이용하는 경우에는 개별약관이 우선적으로 적용되고, 본 약관은
                    보충적인 효력을 갖습니다. 개별약관의 변경에 관해서는 개별약관에 특별한 규정이 없는 한 위 3, 4항을
                    준용합니다. 회원이 사이트에서 탄소 크레딧 구매 및 판매 시 신청절차 상에서 요청되는 동의사항(이하
                    &ldquo;동의사항&rdquo;)은 개별 약관으로 봅니다.
                  </p>
                  <p>
                    <strong>제 4조 (관련 법령과의 관계)</strong>
                  </p>
                  <p>
                    1.본 약관 또는 개별약관에서 정하지 않은 사항은 전기통신사업법, 전자문서 및 전자거래기본법,
                    정보통신망법, 전자상거래 등에서의 소비자보호에 관한 법률, 저작권법 등 관련 법령의 규정과 일반적인
                    상관례에 의합니다.
                  </p>
                  <p>
                    2.회사와 회원은 이 서비스를 이용, 제공함에 있어서 전자상거래 등에서의 소비자보호에 관한 법률,
                    전자문서 및 전자거래기본법, 소비자기본법, 표시&middot;광고의 공정화에 관한 법률, 정보통신망법 등
                    관련법령을 준수하여야 합니다.
                  </p>
                  <p>
                    <strong>제 2장 서비스의 이용</strong>
                  </p>
                  <p>
                    <strong>제 5조 (이용계약의 체결)</strong>
                  </p>
                  <p>
                    1.이용계약은 회원이 되고자 하는 자(이하 &ldquo;가입신청자&rdquo;)가 약관의 내용에 대하여 동의를 한
                    다음 회원가입신청을 하고 회사가 이러한 신청에 대하여 승낙함으로써 체결됩니다.
                  </p>
                  <p>
                    2.회사는 가입신청자의 신청에 대하여 서비스 이용을 승낙함을 원칙으로 합니다. 다만, 회사는 다음 각
                    호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.
                  </p>
                  <p>
                    ①가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 단 회사의 회원 재가입 승낙을
                    얻은 경우에는 예외로 함. ②실명이 아니거나 타인의 명의를 이용한 경우 ③허위의 정보를 기재하거나,
                    회사가 제시하는 내용을 기재하지 않은 경우 ④회원의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한
                    제반 사항을 위반하며 신청하는 경우
                  </p>
                  <p>
                    3.제1항에 따른 신청에 있어 회사는 회원의 종류에 따라 전문기관을 통한 실명확인 및 본인인증을 요청할
                    수 있습니다.
                  </p>
                  <p>
                    4.회사는 서비스관련설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수
                    있습니다.
                  </p>
                  <p>
                    5.제2항과 제4항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한 경우, 회사는 원칙적으로 이를
                    가입신청자에게 알리도록 합니다.
                  </p>
                  <p>6.이용계약의 성립 시기는 회사가 가입완료를 신청절차 상에서 표시한 시점으로 합니다.</p>
                  <p>
                    7.회원은 개인회원과 법인회원으로 구분되며, 회사의 정책에 따라 회원을 등급별로 구분하여 서비스
                    이용범위나 혜택 또는 서비스 사용료 징수 등을 세분하여 이용에 차등을 둘 수 있습니다.
                  </p>
                  <p>
                    <strong>제 6조 (서비스의 제공 및 중단 등)</strong>
                  </p>
                  <p>1.회사는 회원에게 아래와 같은 서비스를 제공합니다.</p>
                  <p>
                    ①탄소감축(흡수) 사업을 통해 발급된 탄소 크레딧의 상쇄 및 거래 서비스(이하 &ldquo;플랫폼
                    서비스&rdquo;)
                  </p>
                  <ol>
                    <li>
                      {' '}
                      회사는 POPLE Standard (이하 &ldquo;파플 탄소인증 표준&rdquo;) 에 등록된 탄소 감축(흡수)사업의
                      감축량인 탄소 크레딧을 사이트를 통해 회원에게 판매합니다.&nbsp;
                    </li>
                    <li>
                      {' '}
                      회원이 취득하는 탄소 크레딧은 이미 발급된 현물 크레딧의 경우, 본인과 회사의 탄소 배출량을
                      상쇄하는데 사용할 수 있고 향후 발급 예정인 탄소 크레딧을 선도거래로 구매하여 미리 확보할 수도
                      있습니다.&nbsp;
                    </li>
                  </ol>
                  <p>
                    iii. 상쇄는 현물 크레딧 구매 즉시 상쇄되며, 기업이 선도거래로 확보한 크레딧은 미래의 발급 시점에서
                    사업개발자로부터 이전 받아 상쇄 됩니다.&nbsp;
                  </p>
                  <p>
                    iv.회원이 탄소 크레딧을 취득하려면 본 플랫폼 서비스를 통해 구매신청 등을 해야 하며, 회사가 탄소
                    크레딧 판매를 위해 사이트에 공지, 제공하는 각 탄소감축(흡수)사업의 내용, 크레딧 정보, 특성, 절차 및
                    방법에 대한 사항을 사전에 충분히 읽고 이해해야 합니다.
                  </p>
                  <p>
                    ②기타 서비스: 회사의 플랫폼 서비스 이외에 회사의 웹사이트를 통하여 제공하는 홍보 서비스 등 일체의
                    기타 서비스.
                  </p>
                  <p>
                    2.회원은 아이디(ID)와 비밀번호(Password)를 통하여 회사가 제공하는 서비스를 사용할 수 있고, 동
                    서비스를 이용하여 탄소 크레딧을 상쇄하거나 선도구매로 향후 기업회원이 필요한 탄소 크레딧을 미리
                    확보할 수 있습니다.
                  </p>
                  <p>
                    3.회원이 상쇄를 목적으로 탄소 크래딧을 구매한 경우 크레딧은 즉시 상쇄되며 결제금은 거래를 위한 일정
                    수수료를 제외하고 사업개발자에게 지급됩니다. 이에 대해 어떤 이의도 제기할 수 없습니다.
                  </p>
                  <p>
                    4.회사는 회사의 중개 없는 회원 과 회원 간의 직거래 또는 회원과 사업개발자 간의 직거래로 인하여
                    발생한 문제에 대하여 책임지지 않습니다.
                  </p>
                  <p>
                    5.회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는
                    경우 서비스의 제공을 일시적으로 중단할 수 있습니다. 이 경우 회사는 제17조 (회원에 대한 통지)에서
                    정한 방법으로 회원에게 통지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우
                    사후에 통지할 수 있습니다.
                  </p>
                  <p>
                    6.회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 서비스 제공화면에
                    공지한 바에 따릅니다.
                  </p>
                  <p>
                    <strong>제 7조 (서비스의 변경)</strong>
                  </p>
                  <ol>
                    <li>
                      {' '}
                      회사는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부
                      서비스를 변경할 수 있습니다.
                    </li>
                    <li>
                      {' '}
                      회사는 서비스 변경 시에 그 변경되는 서비스의 내용을 제17조 (회원에 대한 통지)에서 정한 방법으로
                      회원에게 통지합니다.
                    </li>
                    <li>
                      {' '}
                      회사는 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경할 수
                      있으며, 이에 대하여 회사는 고의 또는 과실이 없는 한 회원에게 별도의 보상을 하지 않습니다.
                    </li>
                  </ol>
                  <p>
                    <strong>제 8조 (서비스 이용계약의 해지, 종료 등)</strong>
                  </p>
                  <ol>
                    <li>
                      {' '}
                      회원은 언제든지 서비스 초기화면의 내 정보 관리 메뉴를 통하여 이용계약 해지 신청을 할 수 있으며,
                      회사는 관련법 등이 정하는 바에 따라 이를 즉시 처리하여야 합니다.
                    </li>
                    <li>
                      {' '}
                      회원이 계약을 해지할 경우, 관련법 및 개인정보처리방침에 따라 회사가 회원정보를 보유하는 경우를
                      제외하고는 해지 즉시 회원의 모든 데이터는 소멸됩니다.
                    </li>
                  </ol>
                  <p>
                    <strong>제 9조 (이용제한 등)</strong>
                  </p>
                  <p>
                    1.회사는 회원이 이 약관의 의무를 위반하거나 서비스의 정상적인 운영을 방해한 경우, 경고, 일시정지,
                    영구이용정지 등으로 서비스 이용을 단계적으로 제한할 수 있습니다.
                  </p>
                  <p>
                    2.회사는 전항에도 불구하고, 주민등록법을 위반한 명의도용 및 결제도용, 정보통신망법을 위반한 불법통신
                    및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련 법령을 위반한 경우에는 즉시
                    영구이용정지를 할 수 있습니다. 본 항에 따른 영구이용정지에 따른 각종 불이익에 대해 회사는 별도로
                    보상하지 않습니다.
                  </p>
                  <p>
                    3.회사는 회원이 계속해서 6개월 이상 로그인하지 않는 경우, 회원정보의 보호 및 운영의 효율성을 위해
                    이용을 제한할 수 있습니다.
                  </p>
                  <p>
                    4.회사는 본 조의 이용제한 범위 내에서 제한의 조건 및 세부내용은 이용제한정책 및 개별 서비스상의
                    운영정책에서 정하는 바에 의합니다.
                  </p>
                  <p>
                    5.본 조 제1항 및 제2항에 따라 이용제한 된 회원의 탄소 크레딧은 회사가 회원의 최초 매입가에 매입할 수
                    있는 권리가 있습니다. 회사는 회원이 사이트에 제공한 계좌정보로 회원이 취득 시 동의한 수수료를 제한
                    대금을 지급하는 것으로 탄소 크레딧을 다시 환수할 수 있습니다. 회사는 본 조 제1항 및 제2항에 따라
                    이용제한 된 회원이 탄소 크레딧을 이용제한 시점에 보유하고 있음에 따라 발생할 수 있는 향후 탄소
                    크레딧으로 받을 수 있는 금원 정산 및 지급 등의 문제에 대하여 어떠한 책임도 부담하지 않습니다.
                  </p>
                  <ol start='6'>
                    <li>
                      {' '}
                      본 조에 따라 서비스 이용을 제한하거나 계약을 해지하는 경우에는 회사는 제17조 (회원에 대한
                      통지)에서 정한 방법으로 회원에게 통지합니다.
                    </li>
                    <li>
                      {' '}
                      회원은 본 조에 따른 이용제한 등에 대해 회사가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때
                      이의가 정당하다고 회사가 인정하는 경우 회사는 즉시 서비스의 이용을 재개합니다.
                    </li>
                  </ol>
                  <p>
                    <strong>제 3장 회원 정보의 보호</strong>
                  </p>
                  <p>
                    <strong>제 10조 (회원정보의 변경)</strong>
                  </p>
                  <ol>
                    <li>
                      {' '}
                      회원은 개인정보관리화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만,
                      서비스 관리를 위해 필요한 실명, 아이디(이메일 포함) 등은 수정이 불가능합니다.
                    </li>
                    <li>
                      {' '}
                      회원은 회원가입 신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 기타
                      방법으로 회사에 대하여 그 변경 사항을 알려야 합니다.
                    </li>
                    <li> 제2항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.</li>
                  </ol>
                  <p>
                    <strong>제 11조 (개인정보보호 의무)</strong>
                  </p>
                  <p>
                    1.회사는 정보통신망법 등 관계 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다.
                    개인정보 보호 및 처리에 대해서는 관련 법령 및 회사의 개인정보처리방침이 적용됩니다. 다만, 회사의
                    공식 사이트 이외의 링크된 사이트에서는 회사의 개인정보처리방침이 적용되지 않습니다.
                  </p>
                  <p>
                    2.회사는 회원의 개인정보 수집 시 서비스제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다.
                  </p>
                  <p>
                    3.회사는 회원의 개인정보를 수집&middot;이용하거나 제3자에게 제공하고자 하는 때에는 회원으로부터
                    동의를 받습니다.
                  </p>
                  <p>
                    4.회사가 제3항에 따라 회원의 동의를 받는 경우, 회사는 개인정보의 수집&middot;이용에 관한
                    사항(수집&middot;이용 목적, 수집하는 개인정보의 항목, 개인정보의 보유&middot;이용기간 등),
                    개인정보의 제3자 제공에 관한 사항(제공받는 자, 제공받는 자의 이용목적, 제공하는 개인정보의 항목,
                    제공받는 자의 보유 및 이용기간 등)을 미리 명시하거나 고지합니다.
                  </p>
                  <p>5.회원은 제3항에 따라 동의하더라도 언제든지 그 동의를 철회할 수 있습니다.</p>
                  <p>
                    6.회원은 언제든지 회사가 가지고 있는 자신의 개인정보에 대해 열람 및 요구정정을 요구할 수 있으며,
                    회사는 이에 대해 지체 없이 필요한 조치를 취할 의무가 있습니다. 회원이 오류의 정정을 요구한 경우에는
                    회사는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.
                  </p>
                  <ol start='7'>
                    <li>
                      {' '}
                      회사는 개인정보의 수집&middot;이용 목적을 달성한 때에는 당해 회원의 개인정보를 지체 없이 파기하는
                      것을 원칙으로 합니다. 다만, 아래의 경우에는 회원정보를 보관합니다. 이 경우 회사는 보관하고 있는
                      회원정보를 그 보관의 목적으로만 이용합니다. 상법 등 관계법령의 규정에 의하여 보존할 필요가 있는
                      경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.
                    </li>
                  </ol>
                  <p>
                    ①회원의 탈퇴 신청 등으로 서비스 이용계약이 종료된 경우, 회사는 구매자 보호 및 본 약관이 정한 제한을
                    의도적으로 회피하기 위한 임의해지 방지를 위하여 상당하다고 인정되는 경우에 한하여 이용계약종료 후
                    1년간 아이디(이메일 포함), 성명 또는 상호, 연락처, 거래상세내역, 약관위반 행위자료 등 최소한의
                    필요정보를 보관합니다. ②회사가 이용계약을 해지하거나 회사로부터 서비스 이용제한 조치를 받은 회원에
                    대해서는 재가입에 대한 승낙거부사유가 존재하는지 여부를 확인하기 위한 목적으로 이용계약종료 또는
                    이용제한 조치를 받은 후 1년간 아이디(이메일 포함), 성명 또는 상호, 연락처, 주소, 해지와
                    회원자격정지와 관련된 정보 등 최소한의 필요정보를 보관합니다. ③기타 정보수집에 관한 동의를 받을 때
                    보유기간을 명시한 경우에는 그 보유기간까지 회원정보를 보관합니다.
                  </p>
                  <ol start='8'>
                    <li>
                      {' '}
                      회사는 회원의 개인정보의 보호 및 처리에 관한 개인정보처리방침을 회원과 회사의 서비스를 이용하고자
                      하는 자가 알 수 있도록 웹 사이트(www.pople.credit)에 공지합니다.
                    </li>
                  </ol>
                  <p>
                    <strong>제 12조 (회원의 아이디(이메일 포함) 및 비밀번호(Password)의 관리에 대한 의무)</strong>
                  </p>
                  <p>
                    1.회원의 아이디와 비밀번호에 관한 관리책임은 회원에게 있으며, 이를 제3자가 이용하도록 하여서는 안
                    됩니다.
                  </p>
                  <p>
                    2.회사는 회원의 아이디가 개인정보 유출 우려가 있거나, 반사회적 또는 미풍양속에 어긋나거나 회사 및
                    회사의 운영자로 오인될 우려가 있는 경우, 해당 아이디의 이용을 제한할 수 있습니다.
                  </p>
                  <p>
                    3.회원은 아이디 및 비밀번호가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 회사에
                    통지하고 회사의 안내에 따라야 합니다.
                  </p>
                  <p>
                    4.제3항의 경우에 해당 회원이 회사에 그 사실을 통지하지 않거나, 통지한 경우에도 회사의 안내에 따르지
                    않아 발생한 불이익에 대하여 회사는 고의 또는 중과실이 없는 한 책임지지 않습니다.
                  </p>
                  <p>
                    <strong>제 4장 회사 및 회원의 의무</strong>
                  </p>
                  <p>
                    <strong>제 13조 (회사의 의무)</strong>
                  </p>
                  <ol>
                    <li>
                      {' '}
                      회사는 관련 법령과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 이 약관이 정하는
                      바에 따라 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다하여 노력합니다.
                    </li>
                    <li>
                      {' '}
                      회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을
                      갖추어야 하며 개인정보처리방침을 공개하고 준수합니다.
                    </li>
                    <li>
                      {' '}
                      회사는 서비스이용과 관련하여 발생하는 회원의 불만 또는 피해구제요청을 적절하게 처리할 수 있도록
                      필요한 인력 및 시스템을 구비합니다.
                    </li>
                    <li>
                      {' '}
                      회사는 서비스이용과 관련하여 회원으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를
                      처리하여야 합니다. 회원이 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편 등을
                      통하여 &ldquo;회원&rdquo;에게 처리과정 및 결과를 전달합니다.
                    </li>
                  </ol>
                  <p>
                    <strong>제 14조 (회원의 의무)</strong>
                  </p>
                  <ol>
                    <li> 회원은 다음 행위를 하여서는 안 됩니다.</li>
                  </ol>
                  <p>
                    ①신청 또는 변경 시 허위내용의 등록 ②타인의 정보도용 ③회사가 게시한 정보의 변경 ④회사가 정한 정보
                    이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시 ⑤회사 기타 제3자의 저작권 등 지적재산권에 대한
                    침해 ⑥회사 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위 ⑦외설 또는 폭력적인 메시지, 화상,
                    음성, 기타 공서양속에 반하는 정보를 사이트에 공개 또는 게시하는 행위 ⑧기타 불법적이거나 부당한 행위
                  </p>
                  <ol start='2'>
                    <li>
                      {' '}
                      회원은 관계 법령, 이 약관의 규정, 이용안내 및 서비스와 관련하여 공지한 주의사항, 회사가 통지하는
                      사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.
                    </li>
                  </ol>
                  <p>
                    <strong>제 5장 기타사항</strong>
                  </p>
                  <p>
                    <strong>제 15조 (서비스이용료의 내용)</strong>
                  </p>
                  <ol>
                    <li> 회사는 서비스 이용과 관련하여 각종 이용료를 회원에게 징수할 수 있습니다.</li>
                    <li>
                      {' '}
                      회사는 재량 내에서 이용료의 추가 및 폐지, 이용료율의 변경을 할 수 있으며, 개정할 경우에는 적용일자
                      및 개정사유를 명시하여 그 적용일자 30일 이전부터 적용일자 전일까지 사이트 화면에 게시하거나 기타의
                      방법으로 회원에게 공지합니다.
                    </li>
                  </ol>
                  <p>
                    <strong>제 16조 (책임 제한)</strong>
                  </p>
                  <ol>
                    <li>
                      {' '}
                      회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 회사의 고의
                      또는 과실이 없는 한 서비스 제공에 관한 책임이 면제됩니다.
                    </li>
                    <li>
                      {' '}
                      회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여는 회사의 고의 또는 중과실이 없는 한
                      책임을 지지 않습니다.
                    </li>
                    <li>
                      {' '}
                      회사는 회원이 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는
                      회사의 고의 또는 중과실이 없는 한 책임을 지지 않습니다.
                    </li>
                    <li>
                      {' '}
                      회사는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.
                    </li>
                    <li>
                      {' '}
                      회사는 서비스상에 게재되어 있거나 본 서비스를 통한 제휴업체 등의 판촉활동에 회원이 참여하거나 교신
                      또는 거래를 함으로써 발생하는 손실과 손해에 대해 회사의 고의 또는 과실이 없는 한 책임을 지지
                      않습니다. 회원은 서비스 내에 포함되어 있는 링크를 통하여 다른 웹사이트로 옮겨갈 경우, 회사는 해당
                      사이트에서 제공하는 정보내용 및 이로 인한 손해 등에 대하여 회사의 고의 또는 중과실이 없는 한
                      책임을 지지 않습니다.
                    </li>
                  </ol>
                  <p>
                    <strong>제 17조 (회원에 대한 통지)</strong>
                  </p>
                  <ol>
                    <li>
                      {' '}
                      회사가 회원에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는 한 회원 가입 시 제공한 전자우편
                      또는 문자메시지 등으로 할 수 있습니다.
                    </li>
                    <li>
                      {' '}
                      회사는 불특정다수 회원에 대한 통지의 경우 7일 이상 서비스 초기 화면에 게시함으로써 제1항의 통지에
                      갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는
                      개별통지를 합니다.
                    </li>
                  </ol>
                  <p>
                    <strong>제 18조 (분쟁조정 및 관할법원)</strong>
                  </p>
                  <ol>
                    <li> 회사와 회원간 제기된 소송은 대한민국법을 준거법으로 합니다.</li>
                    <li>
                      {' '}
                      회사와 회원간 발생한 분쟁에 관한 소송은 제소 당시 회원의 주소를 관할하는 지방법원을
                      전속관할법원으로 하되, 주소가 없는 경우 거소를 관할하는 지방법원을 전속관할법원으로 합니다. 단,
                      제소 당시 회원의 주소 또는 거소가 명확하지 않거나 회원이 외국 거주자인 경우 관할법원은
                      민사소송법에 따라 정합니다.
                    </li>
                  </ol>
                  <p>이 약관은 2022년 04월 22일부터 적용됩니다.</p>
                  <p>
                    <br />
                    <br />
                  </p>
                </>
              ) : (
                <>
                  {' '}
                  <p>
                    <strong>Terms of Use</strong>
                  </p>
                  <p>
                    <strong>Chapter I. General Provisions</strong>
                  </p>
                  <p>
                    <strong>Article 1 Purpose</strong>
                  </p>
                  <p>
                    The purpose of these Terms and Conditions ("Terms") is to define the rights, obligations,
                    responsibilities, and procedures for the use of services provided by Greenery Inc. ("Company") and
                    its customers or users ("Members") in relation to carbon credit sales and offset services, as well
                    as other information services on our website at https://www.pople.credit ("Website").
                  </p>
                  <p>
                    <strong> Article 2 Definitions</strong>
                  </p>
                  <p>
                    1. "Site" refers to a virtual business place or service space provided by the Company for trading
                    carbon credits using Information and Communication facilities to Members. It specifically denotes
                    the website where services can be accessed using Member accounts (ID and password). It is also used
                    to refer to the license of the site. (*Greenery: www.pople.kr)
                  </p>
                  <p>
                    2. "Member" refers to an individual who enters a Use Contract with the Company according to these
                    terms and conditions and utilizes the service on the site operated by the Company.
                  </p>
                  <p>
                    3. "ID" denotes a combination of letters and numbers chosen by the Member and approved by the
                    Company for identifying the Member and accessing the service.
                  </p>
                  <p>
                    4. "Password" denotes a combination of letters or numbers chosen by the Member to confirm their
                    status as a Member corresponding to the provided ID.
                  </p>
                  <p>
                    5. "Business Developer" refers to a corporation that develops and operates a carbon reduction
                    (absorption) business, producing and selling carbon credits.
                  </p>
                  <p>
                    6. "Carbon Credit" refers to property rights issued by business developers in units that can be
                    traded, acknowledging the reduction of carbon emissions resulting from carbon reduction (absorption)
                    projects as offset credit through third-party verification. A ton of carbon reduction (absorption)
                    is equivalent to one credit.
                  </p>
                  <p>
                    7. "Offset" refers to the compensatory action of individuals offsetting their own emissions by
                    purchasing credits for greenhouse gases emitted during daily life or business activities.
                  </p>
                  <p>
                    8. "Termination" refers to the nullification of the future effect of the use contract by the Company
                    or Member.
                  </p>
                  <p>
                    9. Terms not defined in Article 2 will be governed by relevant laws and regulations specific to each
                    service, as well as general trading practices..
                  </p>
                  <p>
                    <strong>Article 3 Posting and revising the terms and conditions</strong>
                  </p>
                  <p>
                    1. The Company will prominently display the contents of these terms and conditions on the initial
                    screen of the service for easy Member recognition. However, the details of the terms and conditions
                    can be accessed through the connection screen.
                  </p>
                  <p>
                    2. The Company may amend these terms and conditions within the bounds of the Act on Regulation of
                    Terms and Conditions, the Act on Promotion of Information and Communication Network Utilization and
                    Information Protection, etc. ("Information Network Act").
                  </p>
                  <p>
                    3. If the Company revises the terms and conditions, it will announce the effective date of the
                    revision from 7 days prior to the date of revision until the day before the revision, along with the
                    reason for the revision, in accordance with the current terms and conditions. However, if the
                    revision contradicts the Member's interests, the Company will provide a grace period of at least 30
                    days in advance. In such cases, the Company will explain the differences before and after the
                    revision to ensure Member understanding.
                  </p>
                  <p>
                    4. If the Member fails to express their rejection of the revised terms and conditions within a
                    seven-day period after clear notification by the Company, it will be assumed that the Member has
                    agreed to the revised terms and conditions.
                  </p>
                  <p>
                    5. If the Member does not agree to the revised Terms, the Company cannot apply the revised contents,
                    and in such cases, the Member may terminate the Use Contract. However, if there are exceptional
                    circumstances where the existing Terms cannot be applied, the Company may terminate the Use
                    Contract. In this case, the Company will notify the Member as prescribed in Article 17 (Notification
                    to Members).
                  </p>
                  <p>
                    6. These Terms are the basic agreement of the service Use Contract established between the Company
                    and the Member. If necessary, the Company may determine and notify in advance the matters to be
                    applied to a particular service ("Separate Provision"). If the Member agrees to these Separate
                    Provision and uses certain services, the Separate Provision will apply first, and it will have
                    supplementary effect. Regarding the change of the Separate Provision, section 3 and 4 above shall
                    apply mutatis mutandis unless there are special provisions. When a Member purchases and sells the
                    carbon credits on the site, the consent requested in the registration process ("Consent") is
                    considered as an individual agreement.
                  </p>
                  <p>
                    <strong>Article 4 Relationship with relevant laws</strong>
                  </p>
                  <p>
                    1. Matters not prescribed in these Terms or Separate Terms shall be governed by the provisions of
                    related laws and regulations, such as the Telecommunications Business Act, the Framework Act on
                    Electronic Documents and Electronic Transactions, the Information and Communication Network Act, the
                    Copyright Act, etc.
                  </p>
                  <p>
                    2. In using and providing this service, Companies and Members must comply with related laws such as
                    the Consumer Protection Act, the Framework Act on Electronic Documents and Electronic Transactions,
                    the Framework Act on Consumers, the Fair Labeling and Advertising Act, and the Information and
                    Communication Network Act
                  </p>
                  <p>
                    <strong>Chapter 2 Use of Services</strong>
                  </p>
                  <p>
                    <strong>Article 5 (Conclusion of a Use Contract)</strong>
                  </p>
                  <p>
                    1. The Use Contract is closed when the Company accepts the application of an individual
                    ("Applicant") who desires to become a Member, agrees to the Terms, and applies for Membership.
                  </p>
                  <p>
                    2. Generally, the Company agrees to provide the service upon the Applicant's application. However,
                    the Company may reject or terminate the Use Contract under the following circumstances:
                  </p>
                  <p>
                    ① If the Applicant has previously had their Membership revoked under these terms and conditions,
                    unless the Company has granted consent for re-enrollment; ② If the Applicant provides false
                    information, uses a fake identity, or violates other regulations, rendering approval impossible at
                    the Company's discretion.
                  </p>
                  <p>
                    3. Depending on the type of Member, the Company may require real-name verification and identity
                    verification through a specialized institution during the application process.
                  </p>
                  <p>
                    4. The Company may withhold consent if it is unable to provide service-related facilities or
                    encounters technical or operational issues.
                  </p>
                  <p>
                    5. If the application is not approved or is put on hold in accordance with paragraphs 2 and 4, the
                    Company will generally notify the Applicant.
                  </p>
                  <p>
                    6. The Use Contract is considered established when the Company indicates the completion of the
                    subscription during the application process. .
                  </p>
                  <p>
                    7. Members are categorized as individual Members and corporate Members. Depending on the Company's
                    policy, Members may be further classified based on their grade and the scope of service usage. This
                    classification may result in variations in service benefits and fees.
                  </p>
                  <p>
                    <strong>Article 6 Provision and suspension of services, etc.</strong>
                  </p>
                  <p>1. The company provides the following services to its members</p>
                  <p>
                    ① Registration and issuance of carbon credits for carbon reduction (sequestration) projects
                    (hereinafter referred to as “Platform Services”)
                  </p>
                  <ol>
                    <p>
                      {' '}
                      i. The company verifies projects according to the methodologies under the POPLE Standard
                      (hereinafter referred to as “POPLE Carbon Certification Standard”. Once verified, it gets
                      registered to POPLE if they meet the requirements. Members must submit documents and attachments
                      in accordance with the format presented on the site, and the project may be approved or denied
                      following an internal and external review process. Resubmission and supplementation are possible,
                      but once the final decision is announced, no objections can be raised against it.
                    </p>
                    <p>
                      {' '}
                      ii. Members must start their projects within a specified period and are obligated to monitor them
                      within the validity period of the project. They are required to apply for the verification for the
                      carbon reduction(removal) amount according to the given monitoring procedures defined by the
                      methodology which allows for supplementation and resubmission based on the outcomes. However, no
                      objections can be raised against the results of 3rd party verification.
                    </p>
                  </ol>
                  <p>
                    iii. Members can request for the issuance of carbon credits for the previously approved carbon
                    reduction amount. Company will issue the carbon credits based on the submitted evidence. Members can
                    sell the issued carbon credits to individuals and corporations through the POPLE Marketplace.
                  </p>
                  <p>
                    iv. Carbon credits acquired by members can be sold in the Marketplace. The company settles the sales
                    proceeds with the member company each month, excluding a certain fee, when an individual or
                    corporation purchases carbon credits. Carbon credits are immediately transferred to the buyer's
                    account upon purchase for offsetting. The price of carbon credits sold in the marketplace is
                    determined by the member, and POPLE does not intervene in the pricing of the carbon credits.
                  </p>
                  <p>
                    v. The members are fully responsible for any losses incurred from the inability to continue the
                    project or from issuing fewer carbon credits than planned, and the company bears no responsibility
                    for such losses.
                  </p>
                  <p>
                    vi. vi. To register a carbon reduction(removal) project and get the carbon credit issuance, members
                    must apply through the platform service. They are required to thoroughly read and understand the
                    contents, application and verification processes, characteristics, procedures, and methods of each
                    methodology announced and provided on the site beforehand.
                  </p>
                  <p>
                    ② Other Services: All additional services provided through the company's website, including
                    promotional services, beyond the platform services offered by the company.
                  </p>
                  <p>
                    2. Members can access and use the services provided by the company using ID and Password. Using that
                    information, members can register carbon reduction (removal) projects or request for the carbon
                    credit issuance.
                  </p>
                  <p>
                    3. The company is not responsible for any issues from direct transactions between members without
                    the company's mediation, or from direct transactions between members and project developers.
                  </p>
                  <p>
                    4. The Company may temporarily suspend the provision of services for maintenance inspections,
                    replacement of information and communication facilities, computer malfunctions, loss of
                    communication, or operational reasons. In such cases, the Company will notify Members according to
                    the provisions stated in Article 17 (Notification to Members). However, if an unforeseen
                    circumstance prevents advance notification, the Company will provide notification afterward.
                  </p>
                  <p>
                    5. The Company may conduct regular inspections as necessary for service provision, and the scheduled
                    inspection times will be announced on the service provision screen.
                  </p>
                  <p>
                    <strong>Article 7 Change of Service</strong>
                  </p>
                  <ol>
                    <p>
                      {' '}
                      1. The Company reserves the right to modify some or all of its services to meet operational and
                      technical requirements, provided that such changes are deemed reasonabl
                    </p>
                    <p>
                      {' '}
                      2. In the event of any service changes, the Company will notify the Member in accordance with the
                      procedures outlined in Article 17 (Notification to the Member).
                    </p>
                    <p>
                      3. The Company may modify, suspend, or terminate any or all of the provided services based on its
                      policies and operational needs. The Company will not be liable to compensate the Member separately
                      unless such actions were intentionally or negligently undertaken.
                    </p>
                  </ol>
                  <p>
                    <strong>Article 8 Termination of Service Use Contract</strong>
                  </p>
                  <ol>
                    <p>
                      {' '}
                      1. Members have the right to request termination of the contract at any time by submitting an
                      application through the "My Information Management" menu on the service's initial screen. The
                      Company must promptly process the request in accordance with applicable laws and regulations.
                    </p>
                    <p>
                      {' '}
                      2. Upon contract cancellation, all of the Member's data will be promptly destroyed, unless the
                      Company is required to retain Member information in accordance with relevant laws and its personal
                      information processing policies.
                    </p>
                  </ol>
                  <p>
                    <strong>Article 9 Restrictions on Use, etc.</strong>
                  </p>
                  <p>
                    1. If a Member violates the obligations stipulated in these terms and conditions or disrupts the
                    normal operation of the service, the Company may impose gradual usage restrictions, such as
                    warnings, temporary suspension, or permanent suspension.
                  </p>
                  <p>
                    2. Notwithstanding the preceding paragraph, the Company may immediately suspend a Member's usage if
                    the Member violates relevant laws, such as identity theft and payment fraud in violation of the
                    Resident Registration Act, illegal communication and hacking in violation of the Information and
                    Communication Network Act, distribution of malicious programs, or exceeding access rights. The
                    Company will not be liable to compensate separately for any disadvantages resulting from the
                    permanent suspension of usage under this paragraph.
                  </p>
                  <p>
                    3. If a Member does not log in for a period exceeding six months, the Company may restrict the use
                    of Member information in order to protect and maintain the operational efficiency of such
                    information.
                  </p>
                  <p>
                    4. Within the scope of usage restrictions outlined in this section, the Company will determine the
                    conditions and specifics of the restrictions in accordance with the usage restriction policy and
                    operation policy applicable to individual services.
                  </p>
                  <p>
                    5. Carbon credits belonging to Members that are restricted under paragraphs 1 and 2 of this Article
                    may be repurchased by the Company at the Member's initial purchase price. The Company may reissue
                    carbon credits to the Member by paying a limited fee agreed upon at the time of acquisition, using
                    the Member's account information provided on the website. The Company will not be liable for any
                    future settlements or payment issues that may arise from the usage of carbon credits by Members
                    restricted under paragraphs 1 and 2 of this Article.
                  </p>
                  <p></p>
                  <p></p>
                  <ol start='6'>
                    <p>
                      {' '}
                      6. In the case of usage restrictions or contract termination pursuant to this Article, the Company
                      will notify the Member in the manner prescribed in Article 17 (Notification to Members).
                    </p>
                    <p>
                      {' '}
                      7. Members have the right to file objections to usage restrictions under this section following
                      the procedures established by the Company. If the Company determines that the objection is valid,
                      it will promptly resume the Member's usage of the service.
                    </p>
                  </ol>
                  <p>
                    <strong>Chapter 3 Protection of Member Information</strong>
                  </p>
                  <p>
                    <strong>Article 10 Change of Member Information</strong>
                  </p>
                  <ol>
                    <p>
                      {' '}
                      1. Members can view and modify their personal information at any time through the personal
                      information management screen. However, real names and IDs (including e-mails) necessary for
                      service management cannot be modified.
                    </p>
                    <p>
                      {' '}
                      2. If the matters entered at the time of applying for Membership are changed, the Member shall
                      revise them online or notify the Company of the changes by e-mail or other means.
                    </p>
                    <p>
                      {' '}
                      3. The Company is not responsible for any disadvantages caused by not informing the Company of the
                      changes in paragraph 2.
                    </p>
                  </ol>
                  <p>
                    <strong>Article 11 Obligation to Protect Personal Information</strong>
                  </p>
                  <p>
                    1. The Company strives to protect the personal information of its Members as prescribed by related
                    laws such as the Information and Communication Network Act. The relevant laws and the Company's
                    personal information processing policy apply to the protection and processing of personal
                    information. However, the Company's personal information processing policy does not apply to linked
                    sites other than the Company's official site.
                  </p>
                  <p>
                    2. When collecting personal information of Members, the Company collects only the minimum personal
                    information necessary for providing services.
                  </p>
                  <p>
                    3. When the Company intends to collect and use the Member's personal information or provide it to a
                    third party, it obtains consent from the Member.
                  </p>
                  <p>
                    4. If the Company obtains the consent of the Member pursuant to paragraph (3), the Company shall
                    specify or notify the matters concerning the collection and use of personal information, such as the
                    purpose of collection and use, items of personal information to be collected, items of personal
                    information to be provided, and period of use.
                  </p>
                  <p>5. Members may withdraw their consent at any time, even if they agreed under paragraph 3.</p>
                  <p>
                    6. A Member may request access to his or her personal information held by the Company at any time,
                    and the Company is obligated to take necessary measures without delay. If a Member requests the
                    correction of an error, the Company will not use the personal information until the error is
                    corrected
                  </p>
                  <ol start='7'>
                    <p>
                      {' '}
                      7. In principle, when the Company achieves the purpose of collecting and using personal
                      information, it shall promptly destroy the personal information of the relevant Member. However,
                      in the following cases, the Membership information is retained. In this case, the Company uses the
                      Member information stored only for the purpose of retention. If it is necessary to preserve it in
                      accordance with the provisions of related laws, such as the Commercial Act, the Company keeps
                      Member information for a certain period of time prescribed by the relevant laws. (1) If a service
                      contract is terminated due to a Member's application for withdrawal, the Company shall retain at
                      least necessary information such as ID (including email), name or trade name, contact information,
                      transaction details, and violation of the terms and conditions. (2) For Members who have
                      terminated or been restricted from using the service, the Company retains minimum necessary
                      information such as ID (including email), name or trade name, contact number, address, and
                      cancellation and suspension of Membership for one year. (3) If the retention period is specified
                      when obtaining consent for other information collection, Member information is kept until the
                      retention period.
                    </p>
                  </ol>
                  <p>
                    8. The Company announces the personal information processing policy on the protection and processing
                    of Members' personal information on the website (www.pople.credit) so that Members and those who
                    want to use the Company's services can be informed.
                  </p>
                  <p>
                    <strong>Article 12 Obligation to manage the Member's ID (including e-mail) and Password</strong>
                  </p>
                  <p>
                    1. The Member is responsible for managing their ID and password and should not allow a third party
                    to use them.
                  </p>
                  <p>
                    2. The Company may restrict the use of a Member's ID if there is a risk of personal information
                    leakage, engagement in antisocial or unfamiliar activities, or if it is likely to be confused with
                    the Company and its operator.
                  </p>
                  <p>
                    3. If the Member's ID and password are stolen or used by a third party, the Member must immediately
                    notify the Company and follow the Company's instructions. 다.
                  </p>
                  <p>
                    4. In the case described in paragraph (3), even if the Member fails to notify the Company or does
                    notify the Company but does not follow the Company's instructions, the Company is not responsible
                    for any disadvantages caused, unless there is intentional or gross negligence on the part of the
                    Company.
                  </p>
                  <p>
                    <strong>Chapter 4 Obligations of the Company and its Members</strong>
                  </p>
                  <p>
                    <strong>Article 13 (Duty of Company)</strong>
                  </p>
                  <ol>
                    <p>
                      {' '}
                      1. The Company shall not engage in any activities prohibited by relevant laws and regulations or
                      contrary to customs and practices, and shall make every effort to provide the service continuously
                      and stably as stipulated in these terms and conditions.
                    </p>
                    <p>
                      {' '}
                      2. The Company shall have a security system in place to protect personal information (including
                      credit information) so that Members can use the service safely. The Company shall also disclose
                      and comply with the personal information processing policy.
                    </p>
                    <p>
                      {' '}
                      3. The Company has the necessary staff and systems to handle complaints or requests for damage
                      relief from Members regarding service usage.
                    </p>
                    <p>
                      4. If the Company determines that opinions or complaints raised by Members regarding the use of
                      the service are valid, it will address them. The process and outcomes of handling Member opinions
                      or complaints will be communicated to Members through bulletin boards or email.
                    </p>
                  </ol>
                  <p>
                    <strong>Article 14 (Duty of Members)</strong>
                  </p>
                  <p> 1. Members are prohibited from engaging in the following activities: </p>
                  <p>
                    (①) Registering false information during application or changes. (②) Modifying information posted by
                    the Company. (③) Transmitting or posting information (including computer programs, etc.) that are
                    not authorized by the Company. (④) Infringing upon intellectual property rights, such as copyrights
                    of companies and third parties. (⑤) Posting obscene or violent messages, images, voice recordings,
                    or other illegal public information.
                  </p>
                  <p>
                    2. Members must comply with relevant laws and regulations, the provisions of these terms and
                    conditions, the guidelines and precautions announced regarding the use of the service, and any
                    instructions or notifications provided by the Company. Members must not interfere with the Company's
                    business operations.
                  </p>
                  <p>
                    <strong>Chapter 5 Other Matters</strong>
                  </p>
                  <p>
                    <strong>Article 15 Content of Service Fees</strong>
                  </p>
                  <ol>
                    <p>1. The Company may collect various usage fees from Members for the use of the service</p>
                    <p>
                      2. The Company reserves the right to add, remove, or modify the usage fee rates at its discretion.
                      In the event of a revision, the Company shall specify the effective date and the reason for the
                      revision. The revised fees will be posted on the website screen at least 30 days prior to the
                      effective date or communicated to the Member through other means.
                    </p>
                  </ol>
                  <p>
                    <strong>Article 16 (Limitation of Liability)</strong>
                  </p>
                  <ol>
                    <p>
                      {' '}
                      1. The company is exempt from liability for service provision if it is unable to offer services
                      due to natural disasters or force majeure events if there is no intent or negligence on the part
                      of the company.
                    </p>
                    <p>
                      {' '}
                      2. The company is not liable for any disruption in service use due to the fault of the members if
                      there is no intent or gross negligence on the part of the company.
                    </p>
                    <p>
                      {' '}
                      3. The Company shall not be held liable for any information, data, reliability of facts, accuracy,
                      etc., posted by Members in relation to the service, unless there is intentional or gross
                      negligence on the part of the Company.
                    </p>
                    <p>
                      {' '}
                      4. The Company shall not be liable for the use of services provided free of charge, unless
                      otherwise stipulated by relevant laws.
                    </p>
                    <p>
                      {' '}
                      5. The Company shall not be responsible for any losses and damages caused by Members'
                      participation, communication, or transactions in promotional activities of affiliates, etc.,
                      posted on the Service or through this Service, unless the Company is intentionally or negligently
                      at fault. If a Member navigates to another website through a link included in the service, the
                      Company shall not be held responsible for the information provided by the site or any resulting
                      damages, unless there is intentional or gross negligence on the part of the Company.
                    </p>
                  </ol>
                  <p>
                    <strong>Article 17 Notification to Members</strong>
                  </p>
                  <ol>
                    <p>
                      {' '}
                      1. When the Company needs to notify a Member, it may do so through email or text message using the
                      contact information provided during Membership registration, unless otherwise specified in these
                      terms and conditions.
                    </p>
                    <p>
                      {' '}
                      2. In the case of notifying many Members, the Company may substitute the notification method
                      described in paragraph 1 by posting the notification on the initial screen of the service for a
                      minimum of 7 days. However, for matters that have a significant impact on the Member's
                      transactions, individual notifications will be provided.
                    </p>
                  </ol>
                  <p>
                    <strong>Article 18 Dispute Mediation and Jurisdiction Court</strong>
                  </p>
                  <ol>
                    <p>
                      1. Litigation between the Company and its Members will be governed by the laws of the Republic of
                      Korea.
                    </p>
                    <p>
                      2. In the event of a lawsuit involving a dispute between the Company and a Member, the district
                      court with jurisdiction over the Member's address at the time of filing the lawsuit shall have
                      exclusive jurisdiction. If no address is available, the district court with jurisdiction over the
                      Member's residence shall have exclusive jurisdiction. However, if the address or residence of the
                      Member is unclear at the time of filing the complaint, or if the Member is a foreign resident, the
                      competent court will determine the jurisdiction in accordance with the Civil Procedure Act.
                    </p>
                  </ol>
                  <p>These terms and conditions have been in effect since February 29, 2024.</p>
                  <p>
                    <br />
                    <br />
                  </p>
                </>
              )}
            </div>
          </div>
        </li>
        <li>
          <header>
            <CheckBox
              value={termsChecks[2]}
              onChange={(new_value) => {
                onChangeTermsCheck(new_value, 2);
              }}
              meta={{
                label: t('AGREE_TO_SELLER'),
                subLabel: t('REQUIRED'),
                className: 'terms',
                required: true,
              }}
            ></CheckBox>
          </header>
          <div className='text__viewer'>
            <div className='online-terms__box'>
              {isKorean ? (
                <div>
                  <p>
                    <strong>이용약관</strong>
                  </p>
                  <p>
                    <strong>제 1장 총칙</strong>
                  </p>
                  <p>
                    <strong>제 1조 (목적)</strong>
                  </p>
                  <p>
                    이 약관은 (주)그리너리(이하 &ldquo;회사&rdquo;)가 운영하는 웹 사이트 (https://www.pople.credit, 이하
                    &ldquo;사이트&rdquo;)에서 제공하는 탄소 감축(흡수)사업의 등록, 탄소크레딧 발급 및 기타
                    정보서비스(이하 &ldquo;서비스&rdquo;)와 관련하여 회사와 회원사 간의 권리, 의무, 책임 사항 및
                    회원사의 서비스 이용절차에 관한 사항을 규정함을 목적으로 합니다.
                  </p>
                  <p>
                    <strong>제 2조 (용어의 정의)</strong>
                  </p>
                  <p>
                    1.&ldquo;사이트&rdquo;라 함은 회사가 서비스를 회원사에게 제공하기 위하여 컴퓨터 등 정보통신설비를
                    이용하여 탄소크레딧 등을 거래할 수 있도록 설정한 가상의 영업장 또는 서비스 공간을 말하며, 회원사
                    계정(ID 및 비밀번호)을 이용하여 서비스를 제공받을 수 있는 아래의 사이트를 말합니다. 아울러 사이트를
                    운영하는 사업자의 의미로도 사용합니다. (*그리너리: www.pople.credit)
                  </p>
                  <p>
                    2.&ldquo;회원사&rdquo;이라 함은 본 약관에 따라 회사와의 이용계약을 체결하고 회사가 운영하는
                    사이트에서 서비스를 이용하는 자를 말합니다.
                  </p>
                  <p>
                    3.&ldquo;아이디(ID)&rdquo;라 함은 회원사의 식별과 서비스 이용을 위하여 회원사가 정하고 회사가
                    승인하는 문자와 숫자의 조합을 의미합니다.
                  </p>
                  <p>
                    4.&ldquo;비밀번호(Password)&rdquo;라 함은 회원사가 부여 받은 아이디와 일치되는 회원사임을 확인하고
                    비밀보호를 위해 회원사 자신이 정한 문자 또는 숫자의 조합을 의미합니다.
                  </p>
                  <p>
                    5."사업개발자&rdquo;이라 함은 탄소감축(흡수)사업을 개발, 운영하는 법인으로 탄소크레딧을 생산하고
                    판매하는 회사를 의미합니다.
                  </p>
                  <p>
                    6."탄소 크레딧&rdquo;이라 함은, 사업개발자가 탄소감축(흡수) 사업의 결과물로 발생한 온실가스 배출량
                    감축분을 제3자 검증을 거쳐 상쇄(Offset) 크레딧으로 인정받아 거래가능한 단위로 발행받은 재산권을
                    의미합니다. 탄소 감축(흡수)량 1톤은 1크레딧과 대등한 가치를 가집니다.
                  </p>
                  <p>
                    7.&ldquo;상쇄&rdquo;라 함은 개인 혹은 기업이 일상이나 사업 중에 배출한 온실가스를 상쇄 크레딧을
                    구매하여 배출량을 보상하는 것을 말합니다.
                  </p>
                  <p>8.&ldquo;해지&rdquo;라 함은 회원사가 이용계약의 효력을 장래를 향해 소멸시키는 것을 말합니다.</p>
                  <p>
                    9.이 약관에서 사용하는 용어 중 제2조에서 정하지 아니한 것은, 관계 법령 및 서비스별 안내에서 정하는
                    바에 따르며, 그 외에는 일반 거래관행에 따릅니다.
                  </p>
                  <p>
                    <strong>제 3조 (약관의 게시와 개정)</strong>
                  </p>
                  <p>
                    1.회사는 이 약관의 내용을 회원사가 쉽게 알 수 있도록 서비스 초기 화면에 게시합니다. 다만, 약관의
                    내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.
                  </p>
                  <p>
                    2.회사는 약관의 규제에 관한 법률, 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하
                    &ldquo;정보통신망법&rdquo;) 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
                  </p>
                  <p>
                    3.회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 제1항의 방식에 따라
                    그 개정약관의 적용일자 7일 전부터 적용일자 전일까지 공지함으로써 그 효력이 발생합니다. 다만,
                    회원사에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다.
                    이 경우 회사는 개정 전 내용과 개정 후 내용을 명확하게 비교하여 회원사가 알기 쉽도록 표시합니다.
                  </p>
                  <p>
                    4.회사가 전항에 따라 개정약관을 공지 또는 통지하면서 회원사원사에게 7일 기간 내에 의사표시를 하지
                    않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 회원사가 명시적으로
                    거부의 의사표시를 하지 아니한 경우 회원사가 개정약관에 동의한 것으로 봅니다.
                  </p>
                  <p>
                    5.회원사가 개정약관의 적용에 동의하지 않는 경우 회사는 개정 약관의 내용을 적용할 수 없으며, 이 경우
                    회원사는 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는
                    회사는 이용계약을 해지할 수 있습니다. 이 경우 회사는 제17조 (회원사에 대한 통지)에서 정한 방법으로
                    회원사에게 통지합니다.
                  </p>
                  <p>
                    6.본 약관은 회사와 회원사 간에 성립되는 서비스이용계약의 기본 약정입니다. 회사는 필요한 경우 특정
                    서비스에 관하여 적용될 사항(이하 &ldquo;개별약관&rdquo;)을 정하여 미리 공지할 수 있습니다. 회원사가
                    이러한 개별약관에 동의하고 특정 서비스를 이용하는 경우에는 개별약관이 우선적으로 적용되고, 본 약관은
                    보충적인 효력을 갖습니다. 개별약관의 변경에 관해서는 개별약관에 특별한 규정이 없는 한 위 3, 4항을
                    준용합니다. 회원사가 사이트에서 탄소 배출권 구매 및 판매 시 신청절차 상에서 요청되는 동의사항(이하
                    &ldquo;동의사항&rdquo;)은 개별 약관으로 봅니다.
                  </p>
                  <p>
                    <strong>제 4조 (관련 법령과의 관계)</strong>
                  </p>
                  <p>
                    1.본 약관 또는 개별약관에서 정하지 않은 사항은 전기통신사업법, 전자문서 및 전자거래기본법,
                    정보통신망법, 전자상거래 등에서의 소비자보호에 관한 법률, 저작권법 등 관련 법령의 규정과 일반적인
                    상관례에 의합니다.
                  </p>
                  <p>
                    2.회사와 회원사는 이 서비스를 이용, 제공함에 있어서 전자상거래 등에서의 소비자보호에 관한 법률,
                    전자문서 및 전자거래기본법, 소비자기본법, 표시&middot;광고의 공정화에 관한 법률, 정보통신망법 등
                    관련법령을 준수하여야 합니다.
                  </p>
                  <p>
                    <strong>제 2장 서비스의 이용</strong>
                  </p>
                  <p>
                    <strong>제 5조 (이용계약의 체결)</strong>
                  </p>
                  <p>
                    1.이용계약은 회원사가 되고자 하는 자(이하 &ldquo;가입신청자&rdquo;)가 약관의 내용에 대하여 동의를 한
                    다음 회원사가입신청을 하고 회사가 이러한 신청에 대하여 승낙함으로써 체결됩니다.
                  </p>
                  <p>
                    2.회사는 가입신청자의 신청에 대하여 서비스 이용을 승낙함을 원칙으로 합니다. 다만, 회사는 다음 각
                    호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.
                  </p>
                  <p>
                    ①가입신청자가 이 약관에 의하여 이전에 회원사자격을 상실한 적이 있는 경우, 단 회사의 회원사 재가입
                    승낙을 얻은 경우에는 예외로 함. ②실명이 아니거나 타인의 명의를 이용한 경우 ③허위의 정보를
                    기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우 ④회원사의 귀책사유로 인하여 승인이
                    불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우
                  </p>
                  <p>
                    3.제1항에 따른 신청에 있어 회사는 회원사의 종류에 따라 전문기관을 통한 실명확인 및 본인인증을 요청할
                    수 있습니다.
                  </p>
                  <p>
                    4.회사는 서비스관련설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수
                    있습니다.
                  </p>
                  <p>
                    5.제2항과 제4항에 따라 회원사가입신청의 승낙을 하지 아니하거나 유보한 경우, 회사는 원칙적으로 이를
                    가입신청자에게 알리도록 합니다.
                  </p>
                  <p>6.이용계약의 성립 시기는 회사가 가입완료를 신청절차 상에서 표시한 시점으로 합니다.</p>
                  <p>
                    7.회원사는 개인회원사와 법인회원사로 구분되며, 회사의 정책에 따라 회원사를 등급별로 구분하여 서비스
                    이용범위나 혜택 또는 서비스 사용료 징수 등을 세분하여 이용에 차등을 둘 수 있습니다.
                  </p>
                  <p>
                    <strong>제 6조 (서비스의 제공 및 중단 등)</strong>
                  </p>
                  <p>1.회사는 회원사에게 아래와 같은 서비스를 제공합니다.</p>
                  <p>①탄소감축(흡수) 사업의 등록 및 탄소크레딧 발급 서비스(이하 &ldquo;플랫폼 서비스&rdquo;)</p>
                  <ol>
                    <li>
                      {' '}
                      회사는 POPLE Standard(이하 &ldquo;파플 탄소인증 표준&rdquo;)에 등록된 방법론에 따라 사업을
                      검증하고 요건에 부합할 경우 등록합니다. 회원사는 사이트에서 제시하는 자료와 첨부 문서를 양식에
                      맞춰 제출하여야 하며 내부 검토 절차를 거쳐 사업이 승인되거나 거부될 수 있습니다. 총 3회까지 수정,
                      재신청이 가능하며 최종 검증 결과에 대해서는 어떠한 이의도 제기할 수 없습니다.
                    </li>
                    <li>
                      {' '}
                      회원사는 정해진 기간 내 사업을 착수 하여야 하고 사업 유효 기간내 모니터링 의무를 가집니다.
                      방법론에 정의된 모니터링 절차에 따라 탄소감축(흡수)량을 신청해야 하며 회사는 제출한 근거를 토대로
                      내부 검토 절차를 거쳐 감축량을 승인하거나 거부할 수 있습니다. 총 3회까지 수정, 재신청이 가능하며
                      최종 검증 결과에 대해서는 어떠한 이의도 제기할 수 없습니다.
                    </li>
                  </ol>
                  <p>
                    iii. 회원사는 발급 승인된 탄소감축량에 대해 탄소크레딧 발급을 요청할 수 있고 회사는 제출한 근거를
                    토대로 탄소크레딧을 발급합니다. 회원사는 발급된 탄소크레딧을 파플 마켓플레이스를 통해 개인과
                    기업에게 판매할 수 있습니다.
                  </p>
                  <ol>
                    <li>
                      {' '}
                      회원사가 취득한 탄소크레딧은 마켓플레이스에서 현물 크레딧과 선도 크레딧으로 구분하여 판매할 수
                      있습니다. 회사는 현물 크레딧과 선물 크레딧 모두 개인 또는 기업이 구매한 즉시 일정 수수료를 제외한
                      판매 대금을 회원사 통장으로 정산합니다. 현물 크레딧은 구매 즉시 구매자의 계정으로 이전되어
                      상쇄되고 선도 크레딧은 향후 발급되는 시점에서 구매자의 계정으로 옮겨져 상쇄됩니다.
                    </li>
                    <li>
                      {' '}
                      마켓플레이스에 등록하는 탄소크레딧의 최초 가격은 사업개발자가 결정합니다. 고객에게 노출되는
                      탄소크레딧의 가격은EU-ETS와 연동하여 정해진 주기에 따라 변동됩니다. 탄소크레딧 가격 변동에 따라
                      탄소크레딧의 정산 금액은 변동될 수 있으며 가격과 정산 금액의 변동에 대해 어떠한 이의도 제기할 수
                      없습니다.
                    </li>
                    <li>
                      {' '}
                      사업을 지속하지 못하거나 예정 발급량에 미달하여 발생하는 탄소크레딧의 미정산에 따른 손실에 대해
                      회원사에 책임이 있으며 회사는 어떠한 책임도 없습니다.
                    </li>
                  </ol>
                  <p>
                    vii. 회원사가 탄소감축(흡수)사업을 등록하고 탄소크레딧을 발급받으려면 본 플랫폼 서비스를 통해
                    등록신청 등을 해야 하며, 회사가 탄소감축(흡수)사업과 탄소크레딧 발급을 위해 사이트에 공지, 제공하는
                    각 방법론의 내용, 신청 및 검증, 특성, 절차 및 방법에 대한 사항을 사전에 충분히 읽고 이해해야 합니다.
                  </p>
                  <p>
                    ②기타 서비스: 회사의 플랫폼 서비스 이외에 회사의 웹사이트를 통하여 제공하는 홍보 서비스 등 일체의
                    기타 서비스.
                  </p>
                  <p>
                    2.회원사는 아이디(ID)와 비밀번호(Password)를 통하여 회사가 제공하는 서비스를 사용할 수 있고, 동
                    서비스를 이용하여 탄소감축(흡수)사업을 등록하거나 탄소크레딧을 발급 받을 수 있습니다.
                  </p>
                  <p>
                    3.회사는 회사의 중개 없는 회원사 과 회원사 간의 직거래 또는 회원사와 사업개발자 간의 직거래로 인하여
                    발생한 문제에 대하여 책임지지 않습니다.
                  </p>
                  <p>
                    4.회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는
                    경우 서비스의 제공을 일시적으로 중단할 수 있습니다. 이 경우 회사는 제17조 (회원사에 대한 통지)에서
                    정한 방법으로 회원사에게 통지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우
                    사후에 통지할 수 있습니다.
                  </p>
                  <p>
                    5.회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 서비스 제공화면에
                    공지한 바에 따릅니다.
                  </p>
                  <p>
                    <strong>제 7조 (서비스의 변경)</strong>
                  </p>
                  <ol>
                    <li>
                      {' '}
                      회사는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부
                      서비스를 변경할 수 있습니다.
                    </li>
                    <li>
                      {' '}
                      회사는 서비스 변경 시에 그 변경되는 서비스의 내용을 제17조 (회원사에 대한 통지)에서 정한 방법으로
                      회원사에게 통지합니다.
                    </li>
                    <li>
                      {' '}
                      회사는 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경할 수
                      있으며, 이에 대하여 회사는 고의 또는 과실이 없는 한 회원사에게 별도의 보상을 하지 않습니다.
                    </li>
                  </ol>
                  <p>
                    <strong>제 8조 (서비스 이용계약의 해지, 종료 등)</strong>
                  </p>
                  <ol>
                    <li>
                      {' '}
                      회원사는 언제든지 서비스 초기화면의 내 정보 관리 메뉴를 통하여 이용계약 해지 신청을 할 수 있으며,
                      회사는 관련법 등이 정하는 바에 따라 이를 즉시 처리하여야 합니다.
                    </li>
                    <li>
                      {' '}
                      회원사가 계약을 해지할 경우, 관련법 및 개인정보처리방침에 따라 회사가 회원사정보를 보유하는 경우를
                      제외하고는 해지 즉시 회원사의 모든 데이터는 소멸됩니다.
                    </li>
                  </ol>
                  <p>
                    <strong>제 9조 (이용제한 등)</strong>
                  </p>
                  <p>
                    1.회사는 회원사가 이 약관의 의무를 위반하거나 서비스의 정상적인 운영을 방해한 경우, 경고, 일시정지,
                    영구이용정지 등으로 서비스 이용을 단계적으로 제한할 수 있습니다.
                  </p>
                  <p>
                    2.회사는 전항에도 불구하고, 주민등록법을 위반한 명의도용 및 결제도용, 정보통신망법을 위반한 불법통신
                    및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련 법령을 위반한 경우에는 즉시
                    영구이용정지를 할 수 있습니다. 본 항에 따른 영구이용정지에 따른 각종 불이익에 대해 회사는 별도로
                    보상하지 않습니다.
                  </p>
                  <p>
                    3.회사는 회원사가 계속해서 6개월 이상 로그인하지 않는 경우, 회원사정보의 보호 및 운영의 효율성을
                    위해 이용을 제한할 수 있습니다.
                  </p>
                  <p>
                    4.회사는 본 조의 이용제한 범위 내에서 제한의 조건 및 세부내용은 이용제한정책 및 개별 서비스상의
                    운영정책에서 정하는 바에 의합니다.
                  </p>
                  <p>
                    5.본 조 제1항 및 제2항에 따라 이용제한 된 회원사의 탄소크레딧은 회사가 회원사의 최초 매입가에 매입할
                    수 있는 권리가 있습니다. 회사는 회원사가 사이트에 제공한 계좌정보로 회원사가 취득 시 동의한 수수료를
                    제한 대금을 지급하는 것으로 탄소크레딧을 다시 환수할 수 있습니다. 회사는 본 조 제1항 및 제2항에 따라
                    이용제한 된 회원사가 탄소크레딧을 이용제한 시점에 보유하고 있음에 따라 발생할 수 있는 향후
                    탄소크레딧으로 받을 수 있는 금원 정산 및 지급 등의 문제에 대하여 어떠한 책임도 부담하지 않습니다.
                  </p>
                  <ol start='6'>
                    <li>
                      {' '}
                      본 조에 따라 서비스 이용을 제한하거나 계약을 해지하는 경우에는 회사는 제17조 (회원사에 대한
                      통지)에서 정한 방법으로 회원사에게 통지합니다.
                    </li>
                    <li>
                      {' '}
                      회원사는 본 조에 따른 이용제한 등에 대해 회사가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때
                      이의가 정당하다고 회사가 인정하는 경우 회사는 즉시 서비스의 이용을 재개합니다.
                    </li>
                  </ol>
                  <p>
                    <strong>제 3장 회원사 정보의 보호</strong>
                  </p>
                  <p>
                    <strong>제 10조 (회원사정보의 변경)</strong>
                  </p>
                  <ol>
                    <li>
                      {' '}
                      회원사는 개인정보관리화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만,
                      서비스 관리를 위해 필요한 실명, 아이디(이메일 포함) 등은 수정이 불가능합니다.
                    </li>
                    <li>
                      {' '}
                      회원사는 회원사가입 신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 기타
                      방법으로 회사에 대하여 그 변경 사항을 알려야 합니다.
                    </li>
                    <li> 제2항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.</li>
                  </ol>
                  <p>
                    <strong>제 11조 (개인정보보호 의무)</strong>
                  </p>
                  <p>
                    1.회사는 정보통신망법 등 관계 법령이 정하는 바에 따라 회원사의 개인정보를 보호하기 위해 노력합니다.
                    개인정보 보호 및 처리에 대해서는 관련 법령 및 회사의 개인정보처리방침이 적용됩니다. 다만, 회사의
                    공식 사이트 이외의 링크된 사이트에서는 회사의 개인정보처리방침이 적용되지 않습니다.
                  </p>
                  <p>
                    2.회사는 회원사의 개인정보 수집 시 서비스제공을 위하여 필요한 범위에서 최소한의 개인정보를
                    수집합니다.
                  </p>
                  <p>
                    3.회사는 회원사의 개인정보를 수집&middot;이용하거나 제3자에게 제공하고자 하는 때에는 회원사로부터
                    동의를 받습니다.
                  </p>
                  <p>
                    4.회사가 제3항에 따라 회원사의 동의를 받는 경우, 회사는 개인정보의 수집&middot;이용에 관한
                    사항(수집&middot;이용 목적, 수집하는 개인정보의 항목, 개인정보의 보유&middot;이용기간 등),
                    개인정보의 제3자 제공에 관한 사항(제공받는 자, 제공받는 자의 이용목적, 제공하는 개인정보의 항목,
                    제공받는 자의 보유 및 이용기간 등)을 미리 명시하거나 고지합니다.
                  </p>
                  <p>5.회원사는 제3항에 따라 동의하더라도 언제든지 그 동의를 철회할 수 있습니다.</p>
                  <p>
                    6.회원사는 언제든지 회사가 가지고 있는 자신의 개인정보에 대해 열람 및 요구정정을 요구할 수 있으며,
                    회사는 이에 대해 지체 없이 필요한 조치를 취할 의무가 있습니다. 회원사가 오류의 정정을 요구한
                    경우에는 회사는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.
                  </p>
                  <ol start='7'>
                    <li>
                      {' '}
                      회사는 개인정보의 수집&middot;이용 목적을 달성한 때에는 당해 회원사의 개인정보를 지체 없이
                      파기하는 것을 원칙으로 합니다. 다만, 아래의 경우에는 회원사정보를 보관합니다. 이 경우 회사는
                      보관하고 있는 회원사정보를 그 보관의 목적으로만 이용합니다. 상법 등 관계법령의 규정에 의하여
                      보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원사정보를 보관합니다.
                    </li>
                  </ol>
                  <p>
                    ①회원사의 탈퇴 신청 등으로 서비스 이용계약이 종료된 경우, 회사는 구매자 보호 및 본 약관이 정한
                    제한을 의도적으로 회피하기 위한 임의해지 방지를 위하여 상당하다고 인정되는 경우에 한하여
                    이용계약종료 후 1년간 아이디(이메일 포함), 성명 또는 상호, 연락처, 거래상세내역, 약관위반 행위자료
                    등 최소한의 필요정보를 보관합니다. ②회사가 이용계약을 해지하거나 회사로부터 서비스 이용제한 조치를
                    받은 회원사에 대해서는 재가입에 대한 승낙거부사유가 존재하는지 여부를 확인하기 위한 목적으로
                    이용계약종료 또는 이용제한 조치를 받은 후 1년간 아이디(이메일 포함), 성명 또는 상호, 연락처, 주소,
                    해지와 회원사자격정지와 관련된 정보 등 최소한의 필요정보를 보관합니다. ③기타 정보수집에 관한 동의를
                    받을 때 보유기간을 명시한 경우에는 그 보유기간까지 회원사정보를 보관합니다.
                  </p>
                  <ol start='8'>
                    <li>
                      {' '}
                      회사는 회원사의 개인정보의 보호 및 처리에 관한 개인정보처리방침을 회원사와 회사의 서비스를
                      이용하고자 하는 자가 알 수 있도록 웹 사이트(www.pople.credit)에 공지합니다.
                    </li>
                  </ol>
                  <p>
                    <strong>제 12조 (회원사의 아이디(이메일 포함) 및 비밀번호(Password)의 관리에 대한 의무)</strong>
                  </p>
                  <p>
                    1.회원사의 아이디와 비밀번호에 관한 관리책임은 회원사에게 있으며, 이를 제3자가 이용하도록 하여서는
                    안 됩니다.
                  </p>
                  <p>
                    2.회사는 회원사의 아이디가 개인정보 유출 우려가 있거나, 반사회적 또는 미풍양속에 어긋나거나 회사 및
                    회사의 운영자로 오인될 우려가 있는 경우, 해당 아이디의 이용을 제한할 수 있습니다.
                  </p>
                  <p>
                    3.회원사는 아이디 및 비밀번호가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 회사에
                    통지하고 회사의 안내에 따라야 합니다.
                  </p>
                  <p>
                    4.제3항의 경우에 해당 회원사가 회사에 그 사실을 통지하지 않거나, 통지한 경우에도 회사의 안내에
                    따르지 않아 발생한 불이익에 대하여 회사는 고의 또는 중과실이 없는 한 책임지지 않습니다.
                  </p>
                  <p>
                    <strong>제 4장 회사 및 회원사의 의무</strong>
                  </p>
                  <p>
                    <strong>제 13조 (회사의 의무)</strong>
                  </p>
                  <ol>
                    <li>
                      {' '}
                      회사는 관련 법령과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 이 약관이 정하는
                      바에 따라 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다하여 노력합니다.
                    </li>
                    <li>
                      {' '}
                      회사는 회원사가 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을
                      갖추어야 하며 개인정보처리방침을 공개하고 준수합니다.
                    </li>
                    <li>
                      {' '}
                      회사는 서비스이용과 관련하여 발생하는 회원사의 불만 또는 피해구제요청을 적절하게 처리할 수 있도록
                      필요한 인력 및 시스템을 구비합니다.
                    </li>
                    <li>
                      {' '}
                      회사는 서비스이용과 관련하여 회원사로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를
                      처리하여야 합니다. 회원사가 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편 등을
                      통하여 &ldquo;회원사&rdquo;에게 처리과정 및 결과를 전달합니다.
                    </li>
                  </ol>
                  <p>
                    <strong>제 14조 (회원사의 의무)</strong>
                  </p>
                  <ol>
                    <li> 회원사는 다음 행위를 하여서는 안 됩니다.</li>
                  </ol>
                  <p>
                    ①신청 또는 변경 시 허위내용의 등록 ②타인의 정보도용 ③회사가 게시한 정보의 변경 ④회사가 정한 정보
                    이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시 ⑤회사 기타 제3자의 저작권 등 지적재산권에 대한
                    침해 ⑥회사 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위 ⑦외설 또는 폭력적인 메시지, 화상,
                    음성, 기타 공서양속에 반하는 정보를 사이트에 공개 또는 게시하는 행위 ⑧기타 불법적이거나 부당한 행위
                  </p>
                  <ol start='2'>
                    <li>
                      {' '}
                      회원사는 관계 법령, 이 약관의 규정, 이용안내 및 서비스와 관련하여 공지한 주의사항, 회사가 통지하는
                      사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.
                    </li>
                  </ol>
                  <p>
                    <strong>제 5장 기타사항</strong>
                  </p>
                  <p>
                    <strong>제 15조 (서비스이용료의 내용)</strong>
                  </p>
                  <ol>
                    <li> 회사는 서비스 이용과 관련하여 각종 이용료를 회원사에게 징수할 수 있습니다.</li>
                    <li>
                      {' '}
                      회사는 재량 내에서 이용료의 추가 및 폐지, 이용료율의 변경을 할 수 있으며, 개정할 경우에는 적용일자
                      및 개정사유를 명시하여 그 적용일자 30일 이전부터 적용일자 전일까지 사이트 화면에 게시하거나 기타의
                      방법으로 회원사에게 공지합니다.
                    </li>
                  </ol>
                  <p>
                    <strong>제 16조 (책임 제한)</strong>
                  </p>
                  <ol>
                    <li>
                      {' '}
                      회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 회사의 고의
                      또는 과실이 없는 한 서비스 제공에 관한 책임이 면제됩니다.
                    </li>
                    <li>
                      {' '}
                      회사는 회원사의 귀책사유로 인한 서비스 이용의 장애에 대하여는 회사의 고의 또는 중과실이 없는 한
                      책임을 지지 않습니다.
                    </li>
                    <li>
                      {' '}
                      회사는 회원사가 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는
                      회사의 고의 또는 중과실이 없는 한 책임을 지지 않습니다.
                    </li>
                    <li>
                      {' '}
                      회사는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.
                    </li>
                    <li>
                      {' '}
                      회사는 서비스상에 게재되어 있거나 본 서비스를 통한 제휴업체 등의 판촉활동에 회원사가 참여하거나
                      교신 또는 거래를 함으로써 발생하는 손실과 손해에 대해 회사의 고의 또는 과실이 없는 한 책임을 지지
                      않습니다. 회원사는 서비스 내에 포함되어 있는 링크를 통하여 다른 웹사이트로 옮겨갈 경우, 회사는
                      해당 사이트에서 제공하는 정보내용 및 이로 인한 손해 등에 대하여 회사의 고의 또는 중과실이 없는 한
                      책임을 지지 않습니다.
                    </li>
                  </ol>
                  <p>
                    <strong>제 17조 (회원사에 대한 통지)</strong>
                  </p>
                  <ol>
                    <li>
                      {' '}
                      회사가 회원사에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는 한 회원사 가입 시 제공한 전자우편
                      또는 문자메시지 등으로 할 수 있습니다.
                    </li>
                    <li>
                      {' '}
                      회사는 불특정다수 회원사에 대한 통지의 경우 7일 이상 서비스 초기 화면에 게시함으로써 제1항의
                      통지에 갈음할 수 있습니다. 다만, 회원사 본인의 거래와 관련하여 중대한 영향을 미치는 사항에
                      대하여는 개별통지를 합니다.
                    </li>
                  </ol>
                  <p>
                    <strong>제 18조 (분쟁조정 및 관할법원)</strong>
                  </p>
                  <ol>
                    <li> 회사와 회원사간 제기된 소송은 대한민국법을 준거법으로 합니다.</li>
                    <li>
                      {' '}
                      회사와 회원사간 발생한 분쟁에 관한 소송은 제소 당시 회원사의 주소를 관할하는 지방법원을
                      전속관할법원으로 하되, 주소가 없는 경우 거소를 관할하는 지방법원을 전속관할법원으로 합니다. 단,
                      제소 당시 회원사의 주소 또는 거소가 명확하지 않거나 회원사가 외국 거주자인 경우 관할법원은
                      민사소송법에 따라 정합니다.
                    </li>
                  </ol>
                  <p>이 약관은 2022년 04월 22일부터 적용됩니다.</p>
                  <p>
                    <br />
                    <br />
                  </p>
                </div>
              ) : (
                <div>
                  <p>
                    <strong>Terms of Use</strong>
                  </p>
                  <p>
                    <strong>Chapter I. General Provisions</strong>
                  </p>
                  <p>
                    <strong>Article 1 Purpose</strong>
                  </p>
                  <p>
                    The purpose of these Terms and Conditions ("Terms") is to define the rights, obligations,
                    responsibilities, and procedures for the use of services provided by Greenery Inc. ("Company") and
                    its customers or users ("Members") in relation to carbon credit sales and offset services, as well
                    as other information services on our website at https://www.pople.credit ("Website").
                  </p>

                  <p>
                    <strong> Article 2 Definitions</strong>
                  </p>
                  <p>
                    1. "Site" refers to a virtual business place or service space provided by the Company for trading
                    carbon credits using Information and Communication facilities to Members. It specifically denotes
                    the website where services can be accessed using Member accounts (ID and password). It is also used
                    to refer to the license of the site. (*Greenery: www.pople.kr)
                  </p>
                  <p>
                    2. "Member" refers to an individual who enters a Use Contract with the Company according to these
                    terms and conditions and utilizes the service on the site operated by the Company.
                  </p>
                  <p>
                    3. "ID" denotes a combination of letters and numbers chosen by the Member and approved by the
                    Company for identifying the Member and accessing the service.
                  </p>
                  <p>
                    4. "Password" denotes a combination of letters or numbers chosen by the Member to confirm their
                    status as a Member corresponding to the provided ID.
                  </p>
                  <p>
                    5. "Business Developer" refers to a corporation that develops and operates a carbon reduction
                    (absorption) business, producing and selling carbon credits.
                  </p>
                  <p>
                    6. "Carbon Credit" refers to property rights issued by business developers in units that can be
                    traded, acknowledging the reduction of carbon emissions resulting from carbon reduction (absorption)
                    projects as offset credit through third-party verification. A ton of carbon reduction (absorption)
                    is equivalent to one credit.
                  </p>
                  <p>
                    7. "Offset" refers to the compensatory action of individuals offsetting their own emissions by
                    purchasing credits for greenhouse gases emitted during daily life or business activities.
                  </p>
                  <p>
                    8. "Termination" refers to the nullification of the future effect of the use contract by the Company
                    or Member.
                  </p>
                  <p>
                    9. Terms not defined in Article 2 will be governed by relevant laws and regulations specific to each
                    service, as well as general trading practices..
                  </p>
                  <p>
                    <strong>Article 3 Posting and revising the terms and conditions</strong>
                  </p>
                  <p>
                    1. The Company will prominently display the contents of these terms and conditions on the initial
                    screen of the service for easy Member recognition. However, the details of the terms and conditions
                    can be accessed through the connection screen.
                  </p>
                  <p>
                    2. The Company may amend these terms and conditions within the bounds of the Act on Regulation of
                    Terms and Conditions, the Act on Promotion of Information and Communication Network Utilization and
                    Information Protection, etc. ("Information Network Act").
                  </p>
                  <p>
                    3. If the Company revises the terms and conditions, it will announce the effective date of the
                    revision from 7 days prior to the date of revision until the day before the revision, along with the
                    reason for the revision, in accordance with the current terms and conditions. However, if the
                    revision contradicts the Member's interests, the Company will provide a grace period of at least 30
                    days in advance. In such cases, the Company will explain the differences before and after the
                    revision to ensure Member understanding.
                  </p>
                  <p>
                    4. If the Member fails to express their rejection of the revised terms and conditions within a
                    seven-day period after clear notification by the Company, it will be assumed that the Member has
                    agreed to the revised terms and conditions.
                  </p>
                  <p>
                    5. If the Member does not agree to the revised Terms, the Company cannot apply the revised contents,
                    and in such cases, the Member may terminate the Use Contract. However, if there are exceptional
                    circumstances where the existing Terms cannot be applied, the Company may terminate the Use
                    Contract. In this case, the Company will notify the Member as prescribed in Article 17 (Notification
                    to Members).
                  </p>
                  <p>
                    6. These Terms are the basic agreement of the service Use Contract established between the Company
                    and the Member. If necessary, the Company may determine and notify in advance the matters to be
                    applied to a particular service ("Separate Provision"). If the Member agrees to these Separate
                    Provision and uses certain services, the Separate Provision will apply first, and it will have
                    supplementary effect. Regarding the change of the Separate Provision, section 3 and 4 above shall
                    apply mutatis mutandis unless there are special provisions. When a Member purchases and sells the
                    carbon credits on the site, the consent requested in the registration process ("Consent") is
                    considered as an individual agreement.
                  </p>
                  <p>
                    <strong>Article 4 Relationship with relevant laws</strong>
                  </p>
                  <p>
                    1. Matters not prescribed in these Terms or Separate Terms shall be governed by the provisions of
                    related laws and regulations, such as the Telecommunications Business Act, the Framework Act on
                    Electronic Documents and Electronic Transactions, the Information and Communication Network Act, the
                    Copyright Act, etc.
                  </p>
                  <p>
                    2. In using and providing this service, Companies and Members must comply with related laws such as
                    the Consumer Protection Act, the Framework Act on Electronic Documents and Electronic Transactions,
                    the Framework Act on Consumers, the Fair Labeling and Advertising Act, and the Information and
                    Communication Network Act
                  </p>
                  <p>
                    <strong>Chapter 2 Use of Services</strong>
                  </p>
                  <p>
                    <strong>Article 5 (Conclusion of a Use Contract)</strong>
                  </p>
                  <p>
                    1. The Use Contract is closed when the Company accepts the application of an individual
                    ("Applicant") who desires to become a Member, agrees to the Terms, and applies for Membership.
                  </p>
                  <p>
                    2. Generally, the Company agrees to provide the service upon the Applicant's application. However,
                    the Company may reject or terminate the Use Contract under the following circumstances:
                  </p>
                  <p>
                    ① If the Applicant has previously had their Membership revoked under these terms and conditions,
                    unless the Company has granted consent for re-enrollment; ② If the Applicant provides false
                    information, uses a fake identity, or violates other regulations, rendering approval impossible at
                    the Company's discretion.
                  </p>
                  <p>
                    3. Depending on the type of Member, the Company may require real-name verification and identity
                    verification through a specialized institution during the application process.
                  </p>
                  <p>
                    4. The Company may withhold consent if it is unable to provide service-related facilities or
                    encounters technical or operational issues.
                  </p>
                  <p>
                    5. If the application is not approved or is put on hold in accordance with paragraphs 2 and 4, the
                    Company will generally notify the Applicant.
                  </p>
                  <p>
                    6. The Use Contract is considered established when the Company indicates the completion of the
                    subscription during the application process. .
                  </p>
                  <p>
                    7. Members are categorized as individual Members and corporate Members. Depending on the Company's
                    policy, Members may be further classified based on their grade and the scope of service usage. This
                    classification may result in variations in service benefits and fees.
                  </p>
                  <p>
                    <strong>Article 6 Provision and suspension of services, etc.</strong>
                  </p>
                  <p>1. The company provides the following services to its members</p>
                  <p>
                    ① Registration and issuance of carbon credits for carbon reduction (sequestration) projects
                    (hereinafter referred to as “Platform Services”)
                  </p>
                  <ol>
                    <p>
                      {' '}
                      i. The company verifies projects according to the methodologies under the POPLE Standard
                      (hereinafter referred to as “POPLE Carbon Certification Standard”. Once verified, it gets
                      registered to POPLE if they meet the requirements. Members must submit documents and attachments
                      in accordance with the format presented on the site, and the project may be approved or denied
                      following an internal and external review process. Resubmission and supplementation are possible,
                      but once the final decision is announced, no objections can be raised against it.
                    </p>
                    <p>
                      {' '}
                      ii. Members must start their projects within a specified period and are obligated to monitor them
                      within the validity period of the project. They are required to apply for the verification for the
                      carbon reduction(removal) amount according to the given monitoring procedures defined by the
                      methodology which allows for supplementation and resubmission based on the outcomes. However, no
                      objections can be raised against the results of 3rd party verification.
                    </p>
                  </ol>
                  <p>
                    iii. Members can request for the issuance of carbon credits for the previously approved carbon
                    reduction amount. Company will issue the carbon credits based on the submitted evidence. Members can
                    sell the issued carbon credits to individuals and corporations through the POPLE Marketplace.
                  </p>
                  <p>
                    iv. Carbon credits acquired by members can be sold in the Marketplace. The company settles the sales
                    proceeds with the member company each month, excluding a certain fee, when an individual or
                    corporation purchases carbon credits. Carbon credits are immediately transferred to the buyer's
                    account upon purchase for offsetting. The price of carbon credits sold in the marketplace is
                    determined by the member, and POPLE does not intervene in the pricing of the carbon credits.
                  </p>
                  <p>
                    v. The members are fully responsible for any losses incurred from the inability to continue the
                    project or from issuing fewer carbon credits than planned, and the company bears no responsibility
                    for such losses.
                  </p>
                  <p>
                    vi. vi. To register a carbon reduction(removal) project and get the carbon credit issuance, members
                    must apply through the platform service. They are required to thoroughly read and understand the
                    contents, application and verification processes, characteristics, procedures, and methods of each
                    methodology announced and provided on the site beforehand.
                  </p>
                  <p>
                    ② Other Services: All additional services provided through the company's website, including
                    promotional services, beyond the platform services offered by the company.
                  </p>
                  <p>
                    2. Members can access and use the services provided by the company using ID and Password. Using that
                    information, members can register carbon reduction (removal) projects or request for the carbon
                    credit issuance.
                  </p>
                  <p>
                    3. The company is not responsible for any issues from direct transactions between members without
                    the company's mediation, or from direct transactions between members and project developers.
                  </p>
                  <p>
                    4. The Company may temporarily suspend the provision of services for maintenance inspections,
                    replacement of information and communication facilities, computer malfunctions, loss of
                    communication, or operational reasons. In such cases, the Company will notify Members according to
                    the provisions stated in Article 17 (Notification to Members). However, if an unforeseen
                    circumstance prevents advance notification, the Company will provide notification afterward.
                  </p>
                  <p>
                    5. The Company may conduct regular inspections as necessary for service provision, and the scheduled
                    inspection times will be announced on the service provision screen.
                  </p>

                  <p>
                    <strong>Article 7 Change of Service</strong>
                  </p>
                  <ol>
                    <p>
                      {' '}
                      1. The Company reserves the right to modify some or all of its services to meet operational and
                      technical requirements, provided that such changes are deemed reasonabl
                    </p>
                    <p>
                      {' '}
                      2. In the event of any service changes, the Company will notify the Member in accordance with the
                      procedures outlined in Article 17 (Notification to the Member).
                    </p>
                    <p>
                      3. The Company may modify, suspend, or terminate any or all of the provided services based on its
                      policies and operational needs. The Company will not be liable to compensate the Member separately
                      unless such actions were intentionally or negligently undertaken.
                    </p>
                  </ol>
                  <p>
                    <strong>Article 8 Termination of Service Use Contract</strong>
                  </p>
                  <ol>
                    <p>
                      {' '}
                      1. Members have the right to request termination of the contract at any time by submitting an
                      application through the "My Information Management" menu on the service's initial screen. The
                      Company must promptly process the request in accordance with applicable laws and regulations.
                    </p>
                    <p>
                      {' '}
                      2. Upon contract cancellation, all of the Member's data will be promptly destroyed, unless the
                      Company is required to retain Member information in accordance with relevant laws and its personal
                      information processing policies.
                    </p>
                  </ol>
                  <p>
                    <strong>Article 9 Restrictions on Use, etc.</strong>
                  </p>
                  <p>
                    1. If a Member violates the obligations stipulated in these terms and conditions or disrupts the
                    normal operation of the service, the Company may impose gradual usage restrictions, such as
                    warnings, temporary suspension, or permanent suspension.
                  </p>
                  <p>
                    2. Notwithstanding the preceding paragraph, the Company may immediately suspend a Member's usage if
                    the Member violates relevant laws, such as identity theft and payment fraud in violation of the
                    Resident Registration Act, illegal communication and hacking in violation of the Information and
                    Communication Network Act, distribution of malicious programs, or exceeding access rights. The
                    Company will not be liable to compensate separately for any disadvantages resulting from the
                    permanent suspension of usage under this paragraph.
                  </p>
                  <p>
                    3. If a Member does not log in for a period exceeding six months, the Company may restrict the use
                    of Member information in order to protect and maintain the operational efficiency of such
                    information.
                  </p>
                  <p>
                    4. Within the scope of usage restrictions outlined in this section, the Company will determine the
                    conditions and specifics of the restrictions in accordance with the usage restriction policy and
                    operation policy applicable to individual services.
                  </p>
                  <p>
                    5. Carbon credits belonging to Members that are restricted under paragraphs 1 and 2 of this Article
                    may be repurchased by the Company at the Member's initial purchase price. The Company may reissue
                    carbon credits to the Member by paying a limited fee agreed upon at the time of acquisition, using
                    the Member's account information provided on the website. The Company will not be liable for any
                    future settlements or payment issues that may arise from the usage of carbon credits by Members
                    restricted under paragraphs 1 and 2 of this Article.
                  </p>
                  <p></p>
                  <p></p>

                  <ol start='6'>
                    <p>
                      {' '}
                      6. In the case of usage restrictions or contract termination pursuant to this Article, the Company
                      will notify the Member in the manner prescribed in Article 17 (Notification to Members).
                    </p>
                    <p>
                      {' '}
                      7. Members have the right to file objections to usage restrictions under this section following
                      the procedures established by the Company. If the Company determines that the objection is valid,
                      it will promptly resume the Member's usage of the service.
                    </p>
                  </ol>
                  <p>
                    <strong>Chapter 3 Protection of Member Information</strong>
                  </p>
                  <p>
                    <strong>Article 10 Change of Member Information</strong>
                  </p>
                  <ol>
                    <p>
                      {' '}
                      1. Members can view and modify their personal information at any time through the personal
                      information management screen. However, real names and IDs (including e-mails) necessary for
                      service management cannot be modified.
                    </p>
                    <p>
                      {' '}
                      2. If the matters entered at the time of applying for Membership are changed, the Member shall
                      revise them online or notify the Company of the changes by e-mail or other means.
                    </p>
                    <p>
                      {' '}
                      3. The Company is not responsible for any disadvantages caused by not informing the Company of the
                      changes in paragraph 2.
                    </p>
                  </ol>
                  <p>
                    <strong>Article 11 Obligation to Protect Personal Information</strong>
                  </p>
                  <p>
                    1. The Company strives to protect the personal information of its Members as prescribed by related
                    laws such as the Information and Communication Network Act. The relevant laws and the Company's
                    personal information processing policy apply to the protection and processing of personal
                    information. However, the Company's personal information processing policy does not apply to linked
                    sites other than the Company's official site.
                  </p>
                  <p>
                    2. When collecting personal information of Members, the Company collects only the minimum personal
                    information necessary for providing services.
                  </p>
                  <p>
                    3. When the Company intends to collect and use the Member's personal information or provide it to a
                    third party, it obtains consent from the Member.
                  </p>
                  <p>
                    4. If the Company obtains the consent of the Member pursuant to paragraph (3), the Company shall
                    specify or notify the matters concerning the collection and use of personal information, such as the
                    purpose of collection and use, items of personal information to be collected, items of personal
                    information to be provided, and period of use.
                  </p>
                  <p>5. Members may withdraw their consent at any time, even if they agreed under paragraph 3.</p>
                  <p>
                    6. A Member may request access to his or her personal information held by the Company at any time,
                    and the Company is obligated to take necessary measures without delay. If a Member requests the
                    correction of an error, the Company will not use the personal information until the error is
                    corrected
                  </p>
                  <ol start='7'>
                    <p>
                      {' '}
                      7. In principle, when the Company achieves the purpose of collecting and using personal
                      information, it shall promptly destroy the personal information of the relevant Member. However,
                      in the following cases, the Membership information is retained. In this case, the Company uses the
                      Member information stored only for the purpose of retention. If it is necessary to preserve it in
                      accordance with the provisions of related laws, such as the Commercial Act, the Company keeps
                      Member information for a certain period of time prescribed by the relevant laws. (1) If a service
                      contract is terminated due to a Member's application for withdrawal, the Company shall retain at
                      least necessary information such as ID (including email), name or trade name, contact information,
                      transaction details, and violation of the terms and conditions. (2) For Members who have
                      terminated or been restricted from using the service, the Company retains minimum necessary
                      information such as ID (including email), name or trade name, contact number, address, and
                      cancellation and suspension of Membership for one year. (3) If the retention period is specified
                      when obtaining consent for other information collection, Member information is kept until the
                      retention period.
                    </p>
                  </ol>
                  <p>
                    8. The Company announces the personal information processing policy on the protection and processing
                    of Members' personal information on the website (www.pople.credit) so that Members and those who
                    want to use the Company's services can be informed.
                  </p>

                  <p>
                    <strong>Article 12 Obligation to manage the Member's ID (including e-mail) and Password</strong>
                  </p>
                  <p>
                    1. The Member is responsible for managing their ID and password and should not allow a third party
                    to use them.
                  </p>
                  <p>
                    2. The Company may restrict the use of a Member's ID if there is a risk of personal information
                    leakage, engagement in antisocial or unfamiliar activities, or if it is likely to be confused with
                    the Company and its operator.
                  </p>
                  <p>
                    3. If the Member's ID and password are stolen or used by a third party, the Member must immediately
                    notify the Company and follow the Company's instructions. 다.
                  </p>
                  <p>
                    4. In the case described in paragraph (3), even if the Member fails to notify the Company or does
                    notify the Company but does not follow the Company's instructions, the Company is not responsible
                    for any disadvantages caused, unless there is intentional or gross negligence on the part of the
                    Company.
                  </p>
                  <p>
                    <strong>Chapter 4 Obligations of the Company and its Members</strong>
                  </p>
                  <p>
                    <strong>Article 13 (Duty of Company)</strong>
                  </p>
                  <ol>
                    <p>
                      {' '}
                      1. The Company shall not engage in any activities prohibited by relevant laws and regulations or
                      contrary to customs and practices, and shall make every effort to provide the service continuously
                      and stably as stipulated in these terms and conditions.
                    </p>
                    <p>
                      {' '}
                      2. The Company shall have a security system in place to protect personal information (including
                      credit information) so that Members can use the service safely. The Company shall also disclose
                      and comply with the personal information processing policy.
                    </p>
                    <p>
                      {' '}
                      3. The Company has the necessary staff and systems to handle complaints or requests for damage
                      relief from Members regarding service usage.
                    </p>
                    <p>
                      4. If the Company determines that opinions or complaints raised by Members regarding the use of
                      the service are valid, it will address them. The process and outcomes of handling Member opinions
                      or complaints will be communicated to Members through bulletin boards or email.
                    </p>
                  </ol>
                  <p>
                    <strong>Article 14 (Duty of Members)</strong>
                  </p>

                  <p> 1. Members are prohibited from engaging in the following activities: </p>

                  <p>
                    (①) Registering false information during application or changes. (②) Modifying information posted by
                    the Company. (③) Transmitting or posting information (including computer programs, etc.) that are
                    not authorized by the Company. (④) Infringing upon intellectual property rights, such as copyrights
                    of companies and third parties. (⑤) Posting obscene or violent messages, images, voice recordings,
                    or other illegal public information.
                  </p>
                  <p>
                    2. Members must comply with relevant laws and regulations, the provisions of these terms and
                    conditions, the guidelines and precautions announced regarding the use of the service, and any
                    instructions or notifications provided by the Company. Members must not interfere with the Company's
                    business operations.
                  </p>
                  <p>
                    <strong>Chapter 5 Other Matters</strong>
                  </p>
                  <p>
                    <strong>Article 15 Content of Service Fees</strong>
                  </p>
                  <ol>
                    <p>1. The Company may collect various usage fees from Members for the use of the service</p>
                    <p>
                      2. The Company reserves the right to add, remove, or modify the usage fee rates at its discretion.
                      In the event of a revision, the Company shall specify the effective date and the reason for the
                      revision. The revised fees will be posted on the website screen at least 30 days prior to the
                      effective date or communicated to the Member through other means.
                    </p>
                  </ol>
                  <p>
                    <strong>Article 16 (Limitation of Liability)</strong>
                  </p>
                  <ol>
                    <p>
                      {' '}
                      1. The company is exempt from liability for service provision if it is unable to offer services
                      due to natural disasters or force majeure events if there is no intent or negligence on the part
                      of the company.
                    </p>
                    <p>
                      {' '}
                      2. The company is not liable for any disruption in service use due to the fault of the members if
                      there is no intent or gross negligence on the part of the company.
                    </p>
                    <p>
                      {' '}
                      3. The Company shall not be held liable for any information, data, reliability of facts, accuracy,
                      etc., posted by Members in relation to the service, unless there is intentional or gross
                      negligence on the part of the Company.
                    </p>
                    <p>
                      {' '}
                      4. The Company shall not be liable for the use of services provided free of charge, unless
                      otherwise stipulated by relevant laws.
                    </p>
                    <p>
                      {' '}
                      5. The Company shall not be responsible for any losses and damages caused by Members'
                      participation, communication, or transactions in promotional activities of affiliates, etc.,
                      posted on the Service or through this Service, unless the Company is intentionally or negligently
                      at fault. If a Member navigates to another website through a link included in the service, the
                      Company shall not be held responsible for the information provided by the site or any resulting
                      damages, unless there is intentional or gross negligence on the part of the Company.
                    </p>
                  </ol>
                  <p>
                    <strong>Article 17 Notification to Members</strong>
                  </p>
                  <ol>
                    <p>
                      {' '}
                      1. When the Company needs to notify a Member, it may do so through email or text message using the
                      contact information provided during Membership registration, unless otherwise specified in these
                      terms and conditions.
                    </p>
                    <p>
                      {' '}
                      2. In the case of notifying many Members, the Company may substitute the notification method
                      described in paragraph 1 by posting the notification on the initial screen of the service for a
                      minimum of 7 days. However, for matters that have a significant impact on the Member's
                      transactions, individual notifications will be provided.
                    </p>
                  </ol>
                  <p>
                    <strong>Article 18 Dispute Mediation and Jurisdiction Court</strong>
                  </p>
                  <ol>
                    <p>
                      1. Litigation between the Company and its Members will be governed by the laws of the Republic of
                      Korea.
                    </p>
                    <p>
                      2. In the event of a lawsuit involving a dispute between the Company and a Member, the district
                      court with jurisdiction over the Member's address at the time of filing the lawsuit shall have
                      exclusive jurisdiction. If no address is available, the district court with jurisdiction over the
                      Member's residence shall have exclusive jurisdiction. However, if the address or residence of the
                      Member is unclear at the time of filing the complaint, or if the Member is a foreign resident, the
                      competent court will determine the jurisdiction in accordance with the Civil Procedure Act.
                    </p>
                  </ol>
                  <p>These terms and conditions have been in effect since February 29, 2024.</p>
                  <p>
                    <br />
                    <br />
                  </p>
                </div>
              )}
              ;
            </div>
          </div>
        </li>
        <li>
          <header>
            <CheckBox
              value={termsChecks[3]}
              onChange={(new_value) => {
                onChangeTermsCheck(new_value, 3);
              }}
              meta={{
                label: SIGN_UP_TERM['MARKETING'],
                className: 'terms',
              }}
            ></CheckBox>
          </header>

          <section style={{ display: 'flex', marginTop: 32, marginBottom: 32, justifyContent: 'center' }}>
            <CheckBox
              value={termsAllCheck}
              onChange={(new_value) => {
                if (new_value) {
                  setTermsChecks([true, true, true, true]);
                } else {
                  setTermsChecks([false, false, false, false]);
                }
                setTermsAllCheck(new_value);
              }}
              meta={{
                label: t('AGREE_ALL'),
                className: 'terms',
              }}
            ></CheckBox>
          </section>
        </li>
      </ul>
    </>
  );
};
