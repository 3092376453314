import { Button } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';
import API from '../../api';
import { DetailDocument, Download, PageHeader } from '../../components';
import { OtherDocument } from '../../components/table/OtherDocument';
import { LangContext, SnackbarContext } from '../../contexts';
import { useLabel } from '../../hooks';
import Govern from '../../styles/images/Frame 1261155277.svg';
import GovernEn from '../../styles/images/governanceEn.svg';

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    padding: '10px',
    height: 315,
    width: 874,
  },
  html: {
    scrollBehavior: 'smooth',
  },
  circleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '-3px',
    position: 'relative',
    width: 874,
    height: 130,
  },
  circle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(240, 240, 243)',
    border: 'transparent',
    color: '#999EAD',
    width: '104px',
    height: '104px',
    borderRadius: '50%',
    fontSize: '14px',
    textAlign: 'center',
    fontWeight: 400,
    transition: 'transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease',
    cursor: 'pointer',
    mixBlendMode: 'multiply',
    padding: '16px 12px',
  },
  activeCircle: {
    backgroundColor: 'rgba(142, 116, 237, 0.7)',
    color: '#fffff',
    padding: '16px 12px',
  },

  uprightLine: {
    borderLeft: '0px dotted #8E74ED',
    position: 'absolute',
    top: '90%',
  },
  arrowText: {
    color: '#606369',
    display: 'flex',
    alignItems: 'center',
  },
  sideBar: {
    'position': 'sticky',
    'display': 'block',
    'top': '200px',
    'margin': 20,
    '&:hover': {
      boxShadow: 0,
      background: 'transparent',
      outline: 'none',
      border: 'none',
    },
    '&:active': {
      boxShadow: 0,
      background: 'transparent',
      outline: 'none',
      border: 'none',
    },
  },

  paragraph: {
    fontSize: '16px',
    color: '#606369',
    fontWeight: 300,
  },
  button: {
    'padding': '10px 20px',
    'color': '#999EAD',
    'backgroundColor': 'transparent',
    '&:hover': {
      backgroundColor: '  #8E74ED',
      color: '#FFFFFF',
      border: '0px solid transparent',
      outline: 'none',
    },
    '&:active': {
      backgroundColor: '#5D38E5',
      border: '#5D38E5',
      outline: 'none',
      color: '#FFFFFF',
    },
  },
};

const circleDescriptions = [
  {
    korean:
      'POPLE은 자발적 탄소시장에 참여하는 주체들이 합리적인 판단을 내릴 수 있도록\n프로젝트 및 VRC에 대한 모든 정보는 문서화하여 투명하게 공개한다.',
    english: 'Projects under the POPLE Standard must quantify GHG removals/reductions using approved methodologies.',
  },
  {
    korean:
      '모든 프로젝트는 가장 정확한 데이터를 사용해 온실가스 배출 및 누출량을 산정하며, 과대 또는 과소 평가를 방지한다.',
    english:
      'All projects must comply with the requirements of the POPLE Standard, ensuring complete documentation and accurate quantification across all steps.',
  },
  {
    korean: '프로젝트 기간 동안 데이터는 일관성을 유지해 상호 비교가 가능해야 한다.',
    english: 'Projects must maintain data consistency throughout the crediting period to allow for comparability.',
  },

  {
    korean:
      '프로젝트는 POPLE 스탠다드의 모든 절차를 따라야 하며,\n각 절차의 모든 정보(문서, 감축량 산정등)에는 누락이 없어야 한다.',
    english:
      'Projects must use the most accurate data available when calculating GHG emissions and leakageto avoid over- or under-estimation.',
  },
  {
    korean:
      'POPLE의 온실가스 제거 및 감축 프로젝트는\n프로젝트와 적합한 방법론을 선택하여 온실가스 제거량 및 감축량을 산정한다.\n',
    english:
      'POPLE facilitates transparency and informed decision-making in the voluntary carbon market by documenting and disclosing all project and VRC information.',
  },

  {
    korean:
      '프로젝트의 온실가스 제거량 및 감축량 산정은 가장 보수적인 가정과 값을 적용하여 원칙적이고 체계적인 절차를 통해 진행된다.',
    english:
      'GHG quantification must follow conservative approaches and systematic procedures based on prudent assumptions and values.',
  },

  {
    korean: '모든 프로젝트는 실행으로 인한 추가적인 온실가스 제거 및 감축 효과가 있어야 한다.',
    english:
      'All projects must demonstrate additionality in their GHG removals/reductions beyond the business-as-usual baseline.',
  },

  {
    korean:
      '온실가스 감축 프로젝트를 통하여 정량화 가능한 범위에서 온실가스 제거 및 감축이 이루어져야 한다.\n프로젝트는 활동 수준, 배출 인자 및 기준 시나리오와 같은 다양한 매개변수에 대해 정확하고 신뢰할 수 있는\n데이터 수집과측정이 가능해야 한다.모든 프로젝트는 BAU(Business-As-Usual) 베이스라인 시나리오를 정의해야 한다.',
    english:
      'Projects must achieve measurable GHG reductions within clearly defined boundaries. This requires reliable data collection and monitoring of key parameters including activity levels, emission factors, and baseline scenarios. All projects must define a business-as-usual baseline.',
  },

  {
    korean:
      '온실가스 배출 제거 및 감축 활동은 재배출 없이 환경적·사회적으로 순기능을 해야 한다.\n프로젝트 영속성을 보장하기 위해 프로젝트 개발자 또는 감축사업자는 리스크 관리 평가를 진행하여 위험 완화 요소를 제시해야 한다.',
    english:
      'GHG removal/reduction activities must deliver permanent climate benefits without reversals while ensuring environmental and social integrity. Project developers must conduct risk assessments and implement appropriate mitigation measures to ensure permanence.',
  },
];

export const StandardPage = (props, { myObject }) => {
  const { t } = useTranslation();
  const [list, setList] = useState(null);
  const [latest, setLatest] = useState(null);
  const { updateSnackbar } = useContext(SnackbarContext);
  // const { isKorean } = useContext(LangContext);
  const { STANDARD_PAGE } = useLabel();
  const { NAVIGATION } = useLabel();
  const [activeLink, setActiveLink] = useState('');
  const { isKorean } = useContext(LangContext) || { isKorean: false };

  const requestNewList = (init_page) => {
    API.Documents.GetStandards()
      .then((res) => {
        console.log('api document', [...res.data]);
        const initialList = [...res.data];
        const result = [...res.data];

        const latest = result.filter((e) => e.type === 'HISTORY').sort((a, b) => b.createdAt - a.createdAt)[0];
        const latestIdx = result.indexOf(latest);
        setLatest(result[latestIdx]);

        result.splice(latestIdx, 1);
        result.sort((a, b) => b.createdAt - a.createdAt);
        setList(result);
      })
      .catch((err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err?.data?.message,
        });
      });
  };
  const requestDocList = (init_page) => {
    API.Documents.GetStandards()
      .then((res) => {
        console.log('api document', [...res.data]);
        const initialList = [...res.data];
        const result = [...res.data];

        setList(result);
      })
      .catch((err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err?.data?.message,
        });
      });
  };
  const handleNavClick = (sectionId) => {
    const section = document.getElementById(sectionId);

    if (section) {
      const headerOffset = 200;
      const sectionPosition = section.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = sectionPosition - headerOffset;
      setActiveLink(sectionId);
      window.scrollTo({
        behavior: 'smooth',
        top: offsetPosition,
      });
    }
  };
  useEffect(() => {
    setActiveLink('principle');
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.target.id) {
            console.log('entry.target.id', entry.target.id);
            setActiveLink(entry.target.id);
          }
        });
      },
      { threshold: 0.6, rootMargin: '0px 0px -15% 0px' },
    );

    const sections = document.querySelectorAll('section');
    sections.forEach((section) => observer.observe(section));

    return () => observer.disconnect();
  }, []);

  console.log('activeLink', activeLink);

  const circle = [
    { korean: '투명성', english: 'Transparent' },
    {
      korean: '정확성',
      english: 'Accurate',
    },
    {
      korean: '일관성',
      english: 'Comparable',
    },
    {
      korean: '완전성',
      english: 'Complete',
    },
    {
      korean: '적절성',
      english: 'Relevant',
    },
    {
      korean: '보수성',
      english: 'Conservative',
    },
    {
      korean: '추가성',
      english: 'Additional',
    },
    {
      korean: '실제성 및\n측정\n가능성',
      english: 'Measurable',
    },
    {
      korean: '영속성',
      english: 'Permanent',
    },
  ];
  const [activeCircle, setActiveCircle] = useState(4);
  const standardList = useMemo(() => {
    if (list?.length > 0) return Handler.genDocumentGroup(list);
    return [];
  }, [list]);
  console.log('standard list', standardList);
  useEffect(() => {
    requestNewList();
  }, []);
  useEffect(() => {
    requestDocList();
  }, []);
  const handleMouseEnter = (index) => {
    setActiveCircle(index === activeCircle ? index[2] : index);
  };
  const inactiveLinkStyle = {
    color: '#999EAD',
    fontWeight: 400,
  };
  const activeLinkStyle = {
    color: '#5D38E5',
    fontWeight: 600,
  };
  console.log(t['MENU']);
  return (
    <div className='h-full content'>
      <article className='basic-page'>
        <article className='basic-page bg-[#FAF9FE]' style={{ height: isKorean ? '483px' : '586px' }}>
          <PageHeader type='standard'>
            <div className='flex justify-between w-full '>
              <div className=''>
                <h1 style={{ fontWeight: 600 }} className='text-[36px]'>
                  {t('POPLE_STANDARD')}
                </h1>
              </div>
              <div style={{ ...styles.paragraph }}>
                <p className='leading-7 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                  {STANDARD_PAGE['DESCRIPTION']}
                </p>
                <p className='leading-7 whitespace-pre-wrap mt-7'>{STANDARD_PAGE['PURPOSE'][0]}</p>
                <p className='leading-7 whitespace-pre-wrap mt-7' style={{ ...styles.paragraph }}>
                  {STANDARD_PAGE['PURPOSE'][1]}
                </p>
              </div>
            </div>
          </PageHeader>
        </article>

        <article className='mt-[-50px] bg-white ml-6'>
          {latest && <Download {...latest} isKorean={isKorean} />}{' '}
        </article>
        <div className='row-page-row py-[80px]' style={{ display: 'flex', top: '200px' }}>
          <div className='fixed-sidebar'>
            <nav className='flex fixed-sidebar' style={styles.sideBar}>
              <div
                style={{
                  borderRight: '1px solid #D7DAE2',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  paddingRight: 24,
                  width: 170,
                }}
                sx={{
                  '&:hover': {
                    boxShadow: 0,
                    background: 'transparent',
                  },
                  '&:active': {
                    boxShadow: 0,
                    background: 'transparent',
                    outline: 'none',
                    border: 'none',
                  },
                }}
              >
                <Button
                  onClick={() => handleNavClick('principle')}
                  style={{
                    ...(activeLink === 'principle' ? styles.activeLink : inactiveLinkStyle),
                    paddingRight: '0',
                    textAlign: 'right',
                    width: '100%',
                    justifyContent: 'flex-end',
                  }}
                >
                  {STANDARD_PAGE['PRINCIPLE_TITLE']}
                </Button>

                <Button
                  onClick={() => handleNavClick('governance')}
                  style={{
                    ...(activeLink === 'governance' ? styles.activeLink : inactiveLinkStyle),
                    paddingRight: '0',
                    textAlign: 'right',
                    width: '100%',
                    justifyContent: 'flex-end',
                  }}
                >
                  {STANDARD_PAGE['GOVERNANCE_TITLE']}
                </Button>

                <Button
                  onClick={() => handleNavClick('related-docs')}
                  style={{
                    ...(activeLink === 'related-docs' ? styles.activeLink : inactiveLinkStyle),
                    paddingRight: '0',
                    textAlign: 'right',
                    width: '100%',
                    justifyContent: 'flex-end',
                  }}
                >
                  {STANDARD_PAGE['RELATED_DOCS']}
                </Button>
              </div>
            </nav>
          </div>

          <div
            className='content-section ml-[100px] w-full mt-6'
            style={{
              flexGrow: 1,
              backgroundColor: '#ffffff',
            }}
          >
            <section id='principle' data-anchor='principle' className='pb-13'>
              <p className='leading-6 mb-9' style={{ fontWeight: '500', fontSize: '20px' }}>
                {STANDARD_PAGE['PRINCIPLE_TITLE']}
              </p>
              <div className='text-gray-500 mb-11' style={{ ...styles.paragraph }}>
                <p className='mt-5 leading-7 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                  {STANDARD_PAGE['PRINCIPLE'][0]}
                </p>
                <p className='mt-5 leading-7 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                  {STANDARD_PAGE['PRINCIPLE'][1]}
                </p>
                <p className='mt-6 leading-7 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                  {STANDARD_PAGE['PRINCIPLE'][2]}
                </p>
              </div>
              <div className='pt-3 pb-1'>
                <div style={styles.container}>
                  {circle.map((circleName, index) => (
                    <div key={(circleName, index)} style={styles.circleContainer}>
                      <div
                        style={{
                          ...styles.circle,
                          backgroundColor: activeCircle === index ? '#8E74ED' : '#F0F0F3',
                          fontSize: isKorean && activeCircle === index ? '14px' : '14px',
                          lineHeight: activeCircle === index ? '20px' : '18px',
                          color: activeCircle === index ? '#ffff' : '#999EAD',
                          transform: activeCircle === index ? 'scale(1.25)' : 'scale(1)',
                          whiteSpace: activeCircle === index ? 'pre-wrap' : 'pre-wrap',
                          padding: circleName === '실제성 및 측정 가능성' ? '14px' : '',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          textAlign: 'center',
                        }}
                        onMouseEnter={() => handleMouseEnter(index)}
                      >
                        {isKorean ? circleName.korean : circleName.english}
                      </div>
                      <div
                        style={{
                          ...styles.uprightLine,
                          borderBottom: activeCircle === index ? '3px dotted #8E74ED' : '',
                          borderLeft: activeCircle === index ? '3px dotted #8E74ED' : '',
                          height: activeCircle === index ? '40px' : 0,
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div
                className='mt-[-170px] h-[110px] w-[870px]'
                style={{ ...styles.arrowText, borderTop: '1px solid #8E74ED' }}
              >
                <div
                  className='flex items-center justify-center'
                  style={{ paddingRight: 30, paddingLeft: 30, width: '100%' }}
                >
                  {activeCircle !== null && (
                    <p style={{ lineHeight: '26px', fontSize: '16px', textAlign: 'center', whiteSpace: 'pre-line' }}>
                      {isKorean ? circleDescriptions[activeCircle]?.korean : circleDescriptions[activeCircle]?.english}
                    </p>
                  )}
                </div>
              </div>
            </section>
            <section id='governance' data-anchor='governance'>
              <p className='mt-[140px] mb-9 leading-6' style={{ fontWeight: '500', fontSize: '20px' }}>
                {STANDARD_PAGE['GOVERNANCE_TITLE']}
              </p>
              <div className='text-gray-400' style={{ ...styles.paragraph }}>
                <p className='mt-4 leading-7 whitespace-pre-wrap'>{STANDARD_PAGE['GOVERNANCE'][0]}</p>
                <p className='mt-6 leading-7 whitespace-pre-wrap'>{STANDARD_PAGE['GOVERNANCE'][1]}</p>
                <p className='mt-0 leading-7 whitespace-pre-wrap'>{STANDARD_PAGE['GOVERNANCE'][2]}</p>
                <p className='mt-0 leading-7 whitespace-pre-wrap'>{STANDARD_PAGE['GOVERNANCE'][3]}</p>
                <p className='mt-6 leading-7 whitespace-pre-wrap'>{STANDARD_PAGE['GOVERNANCE'][4]}</p>
                <Button
                  variant='outlined'
                  style={{
                    height: 44,
                    width: isKorean ? '221px' : '376px',
                    borderRadius: 0,
                    marginTop: 20,
                    marginBottom: 10,
                    fontSize: '15px',
                    lineHeight: 20,
                    textWrap: 'nowrap',
                    padding: '10px 20px',
                  }}
                  sx={{
                    'border': '1px solid #5D38E5',
                    '&:hover': {
                      boxShadow: 'none',
                      color: '#8E74ED',
                      border: '1px solid',
                      outline: '0px solid #8E74ED',
                      background: 'transparent',
                    },
                    '&:active': {
                      border: '1px solid',
                      color: '#8E74ED',
                      outline: '0px solid #8E74ED',
                      background: 'transparent',
                    },
                  }}
                  className='mt-3'
                  component='a'
                  target='_blank'
                  rel='noopener noreferrer'
                  href={
                    isKorean
                      ? 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/[POPLE] POPLE 거버넌스 구조 및 운영회칙.docx'
                      : 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/documents/[POPLE] POPLE Governance and Operational Giudelines.pdf'
                  }
                >
                  {isKorean ? '거버넌스 구조 및 운영회칙.pdf' : 'Governance and Operations Guidelines.pdf'}
                </Button>
                {isKorean ? (
                  <img src={Govern} alt='' className='mt-5 mb-[150px] flex justify-center' />
                ) : (
                  <img src={GovernEn} alt='' className='mt-5 mb-[150px] flex justify-center' />
                )}
              </div>
            </section>
            <section id='related-docs' data-anchor='related-docs' sx={{ borderRadius: 0 }}>
              <div className='mb-8 leading-6' style={{ fontWeight: '500', fontSize: '20px' }}>
                {STANDARD_PAGE['RELATED_DOCS']}
              </div>
              <div>
                {standardList && (
                  <DetailDocument docData={standardList} type='standard' sx={{ marginBottom: '20px' }} />
                )}
              </div>
              <div style={{ marginBottom: '20px', border: '0px solid #D7DAE2' }}></div>
              <div>{standardList && <OtherDocument docData={standardList} type='standard' />}</div>
            </section>
          </div>
        </div>
      </article>
    </div>
  );
};
